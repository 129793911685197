import API from './Api';

class ExternalEntityService extends API {
  constructor(controller) {
    super(controller);
  }
}

let service = new ExternalEntityService('externalentities');

export default service;
