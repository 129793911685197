import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, Paper, Grid, InputLabel } from '@material-ui/core';

// Shared components
import { Table, ConfirmDialog, Select } from 'components';
import { MTableToolbar } from 'material-table';

import { injectIntl } from 'react-intl';

// Component styles
import styles from './styles';

class SubCategoriesTable extends Component {
    state = {
        rowsPerPage: 5,
        page: 0,
        suggestions: [],
        columns: [],
        actions: [],
        category: null
    };

    componentDidMount() {
        this.setState({ columns: this.getColumns(), actions: this.getActions() });
    }

    getColumns = () => {
        const {
            intl,
            embeded
        } = this.props;

        let columns = [
            {
                title: intl.formatMessage({
                    id: 'catalog.subcategories.table.columns.code'
                }),
                field: 'code'
            },
            {
                title: intl.formatMessage({
                    id: 'catalog.subcategories.table.columns.detail'
                }),
                field: 'detail'
            },
            {
                title: intl.formatMessage({
                    id: 'catalog.subcategories.table.columns.owner'
                }),
                field: 'ownerName'
            },
            {
                title: intl.formatMessage({
                    id: 'catalog.subcategories.table.columns.isactive'
                }),
                field: 'isActive',
                render: rowData => <span>{rowData.isActive ? 'Si' : 'No'}</span>
            }
        ];


        if (!embeded) {
            columns.splice(2, 0, {
                title: intl.formatMessage({
                    id: 'catalog.subcategories.table.columns.category'
                }),
                field: 'categoryName'
            });
        }

        return columns;
    };

    getActions = () => {
        const { intl } = this.props;

        return [
            {
                icon: 'edit',
                tooltip: intl.formatMessage({ id: 'catalog.table.action.edit' }),
                onClick: (e, rowData) => this.props.onEditClick(rowData.id)
            },
            {
                icon: 'delete',
                tooltip: intl.formatMessage({ id: 'catalog.table.action.delete' }),
                onClick: (e, rowData) => {
                    this.setState({ itemToDelete: rowData });
                }
            },
            {
                icon: 'add',
                isFreeAction: true,
                tooltip: intl.formatMessage({ id: 'catalog.table.action.create' }),
                onClick: this.props.onAddClick
            }
        ];
    };

    delete = () => {
        const { itemToDelete } = this.state;

        this.props.onDelete(itemToDelete.id);
        this.setState({ itemToDelete: null });
    };

    renderCategoriesDropDown = () => {
        const {
            embeded,
            category
        } = this.state;

        const {
            categories
        } = this.props;

        if (embeded || !categories) {
            return null;
        }

        return (
            <Grid content justify="center" alignContent="center">
                <Grid item xs={10} md={5} lg={4} style={{ paddingTop: '15px', paddingLeft: '15px' }} justify="center" alignContent="center">
                    <InputLabel>Filtrar por categoria</InputLabel>
                    <Select
                        value={category}
                        onChange={(selected) => {
                            this.setState({ category: selected.value });
                            this.props.onCategorySelected(selected.value);
                        }}
                        options={categories}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition={'absolute'}
                        menuPlacement={'bottom'}
                    />
                </Grid>
            </Grid>
        );
    }

    render() {
        const { classes, className, items, intl } = this.props;
        const { columns, itemToDelete, actions } = this.state;
        const rootClassName = classNames(classes.root, className);

        return (
            <Paper className={rootClassName}>
                {itemToDelete && (
                    <ConfirmDialog
                        text={intl.formatMessage(
                            { id: 'action.delete.warning' },
                            { itemName: `${itemToDelete.code}` }
                        )}
                        open
                        onClose={() => this.setState({ itemToDelete: null })}
                        onConfirm={this.delete}
                    />
                )}
                <Table
                    columns={columns}
                    items={items}
                    actions={actions}
                    components={{
                        Toolbar: props => (
                            <div>
                                {this.renderCategoriesDropDown()}
                                <MTableToolbar {...props} />
                            </div>
                        ),
                    }}
                />
            </Paper>
        );
    }
}

SubCategoriesTable.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    category: PropTypes.object,
    onBack: PropTypes.func,
    items: PropTypes.array.isRequired,
    embeded: PropTypes.bool
};

SubCategoriesTable.defaultProps = {
    items: [],
    users: [],
    category: {},
    onDelete: () => { },
    onBack: () => { },
    embeded: true
};

export default withStyles(styles)(injectIntl(SubCategoriesTable));
