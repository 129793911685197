import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
  LookupSettingCreators,
  LookupSettingTypes
} from 'redux/LookupSettingRedux';
import lookupSettingService from 'services/lookupSettingService';
import { MessageOperation } from '../constants';

export function* getAll(showLoading) {
  if (showLoading) yield put(LookupSettingCreators.setLoading(true));

  const response = yield call(lookupSettingService.getAll);

  if (response.ok) {
    yield put(
      LookupSettingCreators.lookupSettingListSuccess(response.data.data)
    );
  } else {
    yield put(
      LookupSettingCreators.lookupSettingListFailure(MessageOperation.error)
    );
  }

  if (showLoading) yield put(LookupSettingCreators.setLoading(false));
}

function* lookupSettingSagas() {
  yield all([
    takeLatest(LookupSettingTypes.LOOKUP_SETTING_LIST_REQUEST, getAll)
  ]);
}

export default lookupSettingSagas;
