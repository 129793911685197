import React, { Component } from 'react';
import classNames from "classnames";

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, Box } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { CategoriesTable } from './components';
import EditCategoryForm from "./EditForm/index.jsx";

import WithCategories from "./components/hoc/WithCategories";

import { withSnackbar } from 'notistack';

import { injectIntl } from "react-intl";

// Component styles
import styles from './style';

class Categories extends Component {
    state = {
        isLoading: false,
        items: [],
        users: [],
        selectedUsers: [],
        error: null,
        showDetailOf: null,
        addItem: false
    };

    componentDidMount() {
        const { getCategories } = this.props;
        getCategories();
    }

    shouldComponentUpdate(nextProps) {
        const { enqueueSnackbar, clearMessage, intl } = this.props;

        if (nextProps.messageOperation && nextProps.messageOperation !== this.props.messageOperation) {
            enqueueSnackbar(intl.formatMessage({ id: nextProps.messageOperation.message }), { variant: nextProps.messageOperation.type });
            clearMessage();
        }

        return true;
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            showDetailOf
        } = this.state;

        if (showDetailOf != prevState.showDetailOf) {
            this.props.clearSubCategory();
        }
    }

    getTitle = () => {
        const { categories, category, subCategory } = this.props;
        const { showDetailOf } = this.state;

        if (!categories || !showDetailOf || !category)
            return "Categorias";

        return (
            <React.Fragment>
                <Box component="span" fontWeight="fontWeightBold" display="inline">Categoria:{" "}</Box>
                <Box component="span" display="inline">{category.code}</Box>
                {subCategory && <Box component="span" display="inline">{" > "}</Box>}
                {subCategory && <Box component="span" fontWeight="fontWeightBold" display="inline">Subcategoria:{" "}</Box>}
                {subCategory && <Box component="span" display="inline">{subCategory.code}</Box>}
            </React.Fragment>
        );
    }

    render() {
        const { classes, categories, isLoading, category } = this.props;
        const { showDetailOf, users, addItem } = this.state;
        const titleNode = this.getTitle();

        return (
            <DashboardLayout title={titleNode}>
                <div className={classNames(classes.root, { [classes.hidden]: showDetailOf || addItem })}>
                    <div className={classes.content}>
                        <CategoriesTable
                            items={categories}
                            users={users}
                            onDelete={this.props.deleteCategory}
                            onEditClick={(id) => {
                                this.setState({ showDetailOf: id });
                            }}
                            onAddClick={() => {
                                this.setState({ addItem: true });
                            }}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
                {
                    (showDetailOf != null || addItem) &&
                    <div className={classes.root}>
                        <div className={classes.content}>
                            <EditCategoryForm
                                id={showDetailOf}
                                category={category}
                                onBack={() => this.setState({ showDetailOf: null, addItem: false })}
                            />
                        </div>
                    </div>
                }
            </DashboardLayout>
        );
    }
}

Categories.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withSnackbar(WithCategories(injectIntl(Categories))));