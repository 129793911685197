import * as yup from 'yup';

export const validationSchema = intl => {
  const required = intl.formatMessage({ id: 'field.validation.required' });

  return yup.object().shape({
    fileExt: yup.string().required(required),
    minSize: yup.number().required(required),
    maxSize: yup.number().required(required),
    namingConvention: yup.string().required(required)
  });
};

export class AttachmentConfigModel {
  constructor(obj) {
    if (!obj) obj = {};
    this.id = obj.id ? obj.id : undefined;
    this.fileExt = obj.fileExt ? obj.fileExt : undefined;
    this.minSize = obj.minSize ? obj.minSize : 0;
    this.maxSize = obj.maxSize ? obj.maxSize : 0;
    this.namingConvention = obj.namingConvention
      ? obj.namingConvention
      : undefined;
    this.isRequired =
      (obj.isRequired !== null || obj.isRequired !== undefined) &&
      obj.id !== undefined
        ? obj.isRequired
        : true;
    this.companyId = obj.companyId ? obj.companyId : undefined;
    this.isActive =
      (obj.isActive !== null || obj.isActive !== undefined) &&
      obj.id !== undefined
        ? obj.isActive
        : true;
  }
}
