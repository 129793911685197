import EntityBase from '../EntityBase';

class Category extends EntityBase {
  constructor(obj) {
    if (!obj) obj = {};
    super(obj);
    this.code = obj.code ? obj.code : '';
    this.detail = obj.detail ? obj.detail : '';
    this.descriptions = obj.descriptions ? obj.descriptions : '';
    this.companyId = obj.companyId ? obj.companyId : 0;
    this.ownerId = obj.ownerId ? obj.ownerId : null;
    this.deparmentId = obj.deparmentId ? obj.deparmentId : null;
    this.ownerName = obj.ownerName ? obj.ownerName : '';
    this.deparmentName = obj.deparmentName ? obj.deparmentName : '';
    this.logo = obj.logo ? obj.logo : '';
    this.isPublic = obj.isPublic ? obj.isPublic : false;
  }
}

export default Category;
