import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  SubCategoryCreators,
  selectIsLoading,
  selectSubCategory
} from 'redux/SubCategoryRedux';
import { selectPriorities } from 'redux/PriorityRedux';
import { selectOwners } from 'redux/UserRedux';
import { selectEntityTypes } from 'redux/EntityTypeRedux';
import { selectUserGroups } from 'redux/UserGroupRedux';

export const mapStateToProps = state => ({
  priorities: selectPriorities(state),
  subCategory: selectSubCategory(state),
  isLoading: selectIsLoading(state),
  users: selectOwners(state),
  entityTypes: selectEntityTypes(state),
  userGroups: selectUserGroups(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSubCategory: SubCategoryCreators.subCategoryGetRequest,
      createSubCategory: SubCategoryCreators.subCategoryCreateRequest,
      updateSubCategory: SubCategoryCreators.subCategoryUpdateRequest
    },
    dispatch
  );

export const hocConector = WrappedComponent => {
  const hoc = ({ ...props }) => <WrappedComponent {...props} />;

  hoc.propTypes = {
    getCategory: PropTypes.func,
    createSubCategory: PropTypes.func,
    updateSubCategory: PropTypes.func
  };

  return hoc;
};

export default Component =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hocConector(Component));
