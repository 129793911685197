import React from 'react';
import PropTypes from 'prop-types';
// Material UI components
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
// React Int Helper
import { injectIntl } from 'react-intl';
// Component assets
import styles from './styles';

class TaskTable extends React.Component {
  render() {
    const {
      classes,
      intl,
      items,
      users,
      errors,
      setFieldValue,
      onUpdateTask,
      isEditable
    } = this.props;

    const table = (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              {intl.formatMessage({
                id: 'catalog.task.table.columns.task'
              })}
            </TableCell>
            <TableCell align="center">
              {intl.formatMessage({
                id: 'catalog.task.table.columns.realizedby'
              })}
            </TableCell>
            <TableCell align="center">
              {intl.formatMessage({
                id: 'catalog.task.table.columns.notes'
              })}
            </TableCell>
            <TableCell align="center">
              {intl.formatMessage({
                id: 'catalog.task.table.columns.isrequired'
              })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={index}>
              <TableCell style={{ width: '20%' }} component="th" scope="row">
                {item.task.taskTypeCode}
              </TableCell>
              <TableCell style={{ width: '30%' }} align="center">
                <TextField
                  name={`tasks[${index}].realizedBy`}
                  placeholder="Responsable"
                  margin="normal"
                  fullWidth
                  select
                  disabled={!isEditable}
                  InputLabelProps={{
                    shrink: true
                  }}
                  className={classes.textField}
                  onChange={event => {
                    const { value } = event.target;
                    setFieldValue(`tasks[${index}].realizedBy`, value);
                    let found = items[index];
                    onUpdateTask({ ...found, realizedBy: value }, index);
                  }}
                  value={item.realizedBy}
                  error={
                    errors.tasks !== undefined &&
                    errors.tasks[index] !== undefined &&
                    errors.tasks[index].realizedBy !== undefined
                  }
                  helperText={
                    errors.tasks !== undefined &&
                    errors.tasks[index] !== undefined &&
                    errors.tasks[index].realizedBy !== undefined
                      ? errors.tasks[index].realizedBy
                      : ''
                  }
                >
                  {users.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell style={{ width: '35%' }} align="center">
                <TextField
                  name={`tasks[${index}].notes`}
                  placeholder="Notas"
                  margin="normal"
                  fullWidth
                  disabled={!isEditable}
                  inputProps={{
                    style: { textAlign: 'center' },
                    onFocus: event => {
                      event.target.select();
                    }
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  className={classes.textField}
                  onChange={event => {
                    const { value } = event.target;
                    setFieldValue(`tasks[${index}].notes`, value);
                  }}
                  onBlur={event => {
                    const { value } = event.target;
                    let found = items[index];
                    onUpdateTask({ ...found, notes: value }, index);
                  }}
                  value={item.notes}
                  error={
                    errors.tasks !== undefined &&
                    errors.tasks[index] !== undefined &&
                    errors.tasks[index].notes !== undefined
                  }
                  helperText={
                    errors.tasks !== undefined &&
                    errors.tasks[index] !== undefined &&
                    errors.tasks[index].notes !== undefined
                      ? errors.tasks[index].notes
                      : ''
                  }
                />
              </TableCell>
              <TableCell
                style={{ width: '15%' }}
                align="center"
                component="th"
                scope="row"
              >
                {item.task.isRequired ? 'Si' : 'No'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
    return <Paper className={classes.root}>{table}</Paper>;
  }
}

TaskTable.propTypes = {
  tasks: PropTypes.array,
  users: PropTypes.array
};

TaskTable.defaultProps = {
  tasks: []
};

export default withStyles(styles)(injectIntl(TaskTable));
