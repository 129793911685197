import * as React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

import {
  Button,
  InputLabel,
  FormControlLabel,
  Grid,
  Switch
} from '@material-ui/core';

import { Select, Loader, SaveContainer } from 'components';
import WithGeneralInfo from './../hoc/WithGeneralInfo';
import { TextField } from 'formik-material-ui';
import { Formik, Field, Form } from 'formik';
import { validationSchema } from './resources';
import SubCategory from 'contract/requests/SubCategory';

import EntityTypeTable from './../EntityTypeTable';

import styles from './styles';

class GeneralInfo extends React.Component {
  state = {
    subCategory: {
      code: '',
      detail: '',
      isActive: true,
      ownerId: null,
      isPublic: false
    },
    rowsSelected: [],
    entityTypes: []
  };

  componentDidMount() {
    const { getSubCategory, id } = this.props;
    if (id) getSubCategory(id);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.subCategory !== this.props.subCategory) {
      const subCategoryConfigs = nextProps.subCategory.subCategoryConfigs
        ? nextProps.subCategory.subCategoryConfigs
        : [];

      let entityTypes = [...this.props.entityTypes];

      for (let index = 0; index < entityTypes.length; index++) {
        let item = subCategoryConfigs.find(x => x === entityTypes[index].id);

        entityTypes[index] = {
          ...entityTypes[index],
          tableData: { id: index, checked: item ? true : false }
        };
      }

      let rowsSelected = entityTypes;

      this.setState(
        {
          rowsSelected: rowsSelected,
          entityTypes: entityTypes,
          subCategory: nextProps.subCategory
        },
        () => true
      );
    }

    return true;
  }

  updateSelectedEntities = rowsSelected => {
    this.setState({ rowsSelected: rowsSelected });
  };

  render() {
    const {
      categoryId,
      users,
      classes,
      createSubCategory,
      updateSubCategory,
      isLoading,
      userGroups
    } = this.props;

    const { subCategory, entityTypes } = this.state;

    const selectUsers = users.map(i => ({
      value: i.id,
      label: i.displayName,
      isActive: i.isActive
    }));

    const selectUserGroups = userGroups.map(i => ({
      value: i.id,
      label: i.code,
      isActive: i.isActive
    }));

    return (
      <React.Fragment>
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            enableReinitialize
            initialValues={subCategory}
            validationSchema={validationSchema}
            onSubmit={values => {
              let subCategoryRequest = new SubCategory(values);
              subCategoryRequest.ownerName = users.find(
                x => x.id === values.ownerId
              ).displayName;

              if (subCategoryRequest.isPublic)
                subCategoryRequest.subCategoryConfigs = this.state.rowsSelected.map(
                  x => x.id
                );

              if (!values.id) {
                subCategoryRequest.categoryId = categoryId;
                createSubCategory(subCategoryRequest);
              } else {
                updateSubCategory(subCategoryRequest);
              }
            }}
            render={({ submitForm, isSubmitting, values }) => (
              <SaveContainer saveContent={submitForm}>
                <Form>
                  <Grid container className={classes.root} spacing={1}>
                    <Grid item xs={12} md={6} lg={4}>
                      <Field
                        type="text"
                        label="Nombre"
                        name="code"
                        margin="normal"
                        component={TextField}
                        fullWidth
                        autoFocus
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.root} spacing={1}>
                    <Grid item xs={12} md={6} lg={4}>
                      <Field
                        type="text"
                        label="Detalles"
                        name="detail"
                        component={TextField}
                        margin="normal"
                        fullWidth
                        multiline
                        rows={3}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.root} spacing={1}>
                    <Grid item xs={12} md={6} lg={4}>
                      <Field
                        name="ownerId"
                        render={({ field, form }) => {
                          return (
                            <div
                              className={classNames(classes.selectRoot, {
                                error:
                                  form.touched[field.name] &&
                                  form.errors[field.name]
                              })}
                            >
                              <InputLabel className={classes.inputLabel}>
                                Responsable
                              </InputLabel>
                              <Select
                                isSearchable
                                value={field.value}
                                onChange={selected =>
                                  form.setFieldValue(field.name, selected.value)
                                }
                                options={selectUsers}
                                onMenuClose={() =>
                                  form.setFieldTouched(field.name, true, true)
                                }
                                onMenuOpen={() =>
                                  form.setFieldTouched(field.name, false)
                                }
                              />
                              {form.touched[field.name] &&
                                form.errors[field.name] && (
                                  <p className="MuiFormHelperText-root Mui-error">
                                    {form.errors[field.name]}
                                  </p>
                                )}
                            </div>
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.root} spacing={1}>
                    <Grid item xs={12} md={6} lg={4}>
                      <Field
                        name="userGroupId"
                        render={({ field, form }) => {
                          return (
                            <div
                              className={classNames(classes.selectRoot, {
                                error:
                                  form.touched[field.name] &&
                                  form.errors[field.name]
                              })}
                            >
                              <InputLabel className={classes.inputLabel}>
                                Grupos de trabajo
                              </InputLabel>
                              <Select
                                isSearchable
                                value={field.value}
                                onChange={selected =>
                                  form.setFieldValue(field.name, selected.value)
                                }
                                options={selectUserGroups}
                                onMenuClose={() =>
                                  form.setFieldTouched(field.name, true, true)
                                }
                                onMenuOpen={() =>
                                  form.setFieldTouched(field.name, false)
                                }
                              />
                              {form.touched[field.name] &&
                                form.errors[field.name] && (
                                  <p className="MuiFormHelperText-root Mui-error">
                                    {form.errors[field.name]}
                                  </p>
                                )}
                            </div>
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.root} spacing={1}>
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControlLabel
                        control={
                          <Field
                            label="Categoría Publica"
                            name="isPublic"
                            component={props => (
                              <Switch
                                checked={props.field.value}
                                value={props.field.name}
                                onChange={(e, value) => {
                                  props.form.setFieldValue(
                                    props.field.name,
                                    value
                                  );
                                }}
                              />
                            )}
                          />
                        }
                        label="Categoría Publica"
                      />
                    </Grid>
                  </Grid>

                  {values.isPublic && (
                    <Grid container className={classes.root} spacing={1}>
                      <Grid item xs={12} md={6} lg={4}>
                        <EntityTypeTable
                          items={entityTypes}
                          postSelectedRows={this.updateSelectedEntities}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid container className={classes.root} spacing={1}>
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControlLabel
                        control={
                          <Field
                            label="Activo"
                            name="isActive"
                            component={props => (
                              <Switch
                                checked={props.field.value}
                                value={props.field.name}
                                onChange={(e, value) =>
                                  props.form.setFieldValue(
                                    props.field.name,
                                    value
                                  )
                                }
                              />
                            )}
                          />
                        }
                        label="Activo"
                      />
                      <br />
                      <br />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </SaveContainer>
            )}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(WithGeneralInfo(GeneralInfo));
