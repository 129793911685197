import { phoneRegExp, zipCodeRegExp, latitudeRegExp, longitudeRegExp, rfcRegExp } from "../../../../validation";
import * as yup from 'yup';

export const validationSchema = intl => {

	const required = intl.formatMessage({id:"field.validation.required"});

	return yup.object().shape({
		code: yup.string().required(required),
		detail: yup.string().required(required),
		ownerId: yup
			.string()
			.required(required)
			.nullable(),
		address1: yup.string().required(required),
		zipCode: yup
			.string()
			.matches(zipCodeRegExp, {
				message: intl.formatMessage({id:"field.validation.zipcode"}),
				excludeEmptyString: true
			}),
		businessName: yup.string().required(required),
		taxId: yup.string().required(required).matches(rfcRegExp, {
			message: intl.formatMessage({id:"field.validation.taxid"}),
			excludeEmptyString: true
		}),
		phoneNumber: yup
			.string()
			.matches(phoneRegExp, {
				message: intl.formatMessage({id:"field.validation.mobilephone"}),
				excludeEmptyString: true
			}),
		latitude: yup
			.string()
			.matches(latitudeRegExp, {
				message: intl.formatMessage({id:"field.validation.latitude"}),
				excludeEmptyString: true
			}),
		longitude: yup
			.string()
			.matches(longitudeRegExp, {
				message: intl.formatMessage({id:"field.validation.longitude"}),
				excludeEmptyString: true
			}),
		stateId: yup
			.string()
			.required(required)
			.nullable(),
		cityId: yup
			.string()
			.required(required)
			.nullable()
	});
};

export class CompanyLocationModel {
	constructor(obj) {
		if (!obj) obj = { address: {} };
		this.id = obj.id ? obj.id : undefined;
		this.companyId = obj.companyId ? obj.companyId : undefined;
		this.code = obj.code ? obj.code : "";
		this.detail = obj.detail ? obj.detail : "";
		this.ownerId = obj.ownerId ? obj.ownerId : undefined;
		this.ownerName = obj.ownerName ? obj.ownerName : "";
		this.addressId = obj.address.id ? obj.address.id : "";
		this.address1 = obj.address.address1 ? obj.address.address1 : "";
		this.address2 = obj.address.address2 ? obj.address.address2 : "";
		this.zipCode = obj.address.zipCode ? obj.address.zipCode : "";
		this.latitude = obj.address.latitude ? obj.address.latitude : "";
		this.longitude = obj.address.longitude ? obj.address.longitude : "";
		this.businessName = obj.address.businessName ? obj.address.businessName : "";
		this.phoneNumber = obj.address.phoneNumber ? obj.address.phoneNumber : "";
		this.taxId = obj.address.taxId ? obj.address.taxId : "";
		this.stateId = obj.address.stateId ? obj.address.stateId : "";
		this.cityId = obj.address.cityId ? obj.address.cityId : "";
		this.email = obj.address.email ? obj.address.email : "";
		this.isActive = (obj.isActive !== null || obj.isActive !== undefined) && obj.id !== undefined ? obj.isActive : true;
	}
}
