import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  priorityLevels: yup.array().of(
    yup.object().shape({
      responseTimeSetting: yup
        .number()
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        .required('Este campo es requerido.'),
      responseTimeTypeId: yup
        .string()
        .required('Este campo es requerido.')
        .nullable()
    })
  )
});

export const styles = theme => ({
  root: {
    flexGrow: 1
  },
  selectRoot: {
    marginTop: theme.spacing.unit * 3
  }
});

export class SubCategoryPriorityModel {
  constructor(obj) {
    if (!obj) obj = {};
    this.subCategoryId = obj.subCategoryId ? obj.subCategoryId : undefined;
    this.responseTimeSetting = obj.responseTimeSetting
      ? obj.responseTimeSetting
      : undefined;
  }
}
