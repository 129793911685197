import * as React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

import {
	Button,
	InputLabel,
	FormControlLabel,
	Grid,
	Switch
} from '@material-ui/core';

import { Select, Loader, SaveContainer } from 'components';

import WithGeneralInfo from './../hoc/WithGeneralInfo';
import { TextField } from 'formik-material-ui';
import { Formik, Field, Form } from 'formik';

import { validationSchema } from './resources';

import Category from 'contract/requests/Category';

import EntityTypeTable from './../EntityTypeTable';

import styles from './styles';
import categoriesService from 'services/categoriesService';

class GeneralInfo extends React.Component {
	state = {
		category: {
			code: '',
			detail: '',
			descriptions: '',
			isActive: true,
			ownerId: null,
			deparmentId: null,
			isPublic: false
		},
		rowsSelected: [],
		entityTypes: []
	};

	componentDidMount() {
		const {
			id,
		} = this.props;

		if (id) {
			this.getCategory(id);
		}
	}

	componentDidUpdate(props, state) {
		if (state.category !== this.state.category) {
			this.updateCategory();
		}
	}

	getCategory = async (id) => {
		const response = await categoriesService.getById(id);
		this.setState({ category: response.data.data });
	}

	updateSelectedEntities = rowsSelected => {
		this.setState({ rowsSelected: rowsSelected });
	};

	updateCategory = () => {
		const {
			category
		} = this.state;

		const categoryConfigs = category.categoryConfigs
			? category.categoryConfigs
			: [];

		let entityTypes = [...this.props.entityTypes];

		for (let index = 0; index < entityTypes.length; index++) {
			let item = categoryConfigs.find(x => x === entityTypes[index].id);

			entityTypes[index] = {
				...entityTypes[index],
				tableData: { id: index, checked: item ? true : false }
			};
		}

		this.setState({
			rowsSelected: entityTypes,
			entityTypes: entityTypes,
		});
	}

	render() {
		const {
			users,
			deparments,
			classes,
			isLoading,
			createCategory,
			updateCategory
		} = this.props;

		const {
			category,
			entityTypes,
		} = this.state;

		const selectUsers = users.map(i => ({
			value: i.id,
			label: i.displayName,
			isActive: i.isActive
		}));

		const selectDepartments = deparments.map(i => ({
			value: i.id,
			label: i.code
		}));

		return (
			<React.Fragment>
				{isLoading ? (
					<Loader />
				) : (
					<Formik
						enableReinitialize
						initialValues={category}
						validationSchema={validationSchema}
						onSubmit={values => {
							const categoryRequest = new Category(values);
							const creator = users.find(x => x.id == values.ownerId);
							const deparment = deparments.find(
								x => x.id == values.deparmentId
							);
							categoryRequest.ownerName = creator
								? creator.displayName
								: 'Administrador';

							categoryRequest.deparmentName = deparment ? deparment.code : '';

							if (categoryRequest.isPublic)
								categoryRequest.categoryConfigs = this.state.rowsSelected.map(
									x => x.id
								);

							if (categoryRequest.id > 0) {
								updateCategory(categoryRequest);
							} else {
								createCategory(categoryRequest);
							}
						}}
						render={({ submitForm, isSubmitting, values }) => (
							<SaveContainer saveContent={submitForm}>
								<Form>
									<Grid container className={classes.root} spacing={1}>
										<Grid item xs={12} md={6} lg={4}>
											<Field
												type="text"
												label="Nombre"
												name="code"
												margin="normal"
												component={TextField}
												fullWidth
												autoFocus
												InputLabelProps={{
													shrink: true
												}}
											/>
										</Grid>
									</Grid>
									<Grid container className={classes.root} spacing={1}>
										<Grid item xs={12} md={6} lg={4}>
											<Field
												type="text"
												label="Detalles"
												name="detail"
												component={TextField}
												margin="normal"
												fullWidth
												multiline
												rows={3}
												InputLabelProps={{
													shrink: true
												}}
											/>
										</Grid>
									</Grid>
									<Grid container className={classes.root} spacing={1}>
										<Grid item xs={12} md={6} lg={4}>
											<Field
												name="ownerId"
												render={({ field, form }) => {
													return (
														<div
															className={classNames(classes.selectRoot, {
																error:
																	form.touched[field.name] &&
																	form.errors[field.name]
															})}
														>
															<InputLabel className={classes.inputLabel}>
																Responsable
															</InputLabel>
															<Select
																isSearchable
																value={field.value}
																onChange={selected =>
																	form.setFieldValue(field.name, selected.value)
																}
																options={selectUsers}
																onMenuClose={() =>
																	form.setFieldTouched(field.name, true, true)
																}
																onMenuOpen={() =>
																	form.setFieldTouched(field.name, false)
																}
															/>
															{form.touched[field.name] &&
																form.errors[field.name] && (
																	<p className="MuiFormHelperText-root Mui-error">
																		{form.errors[field.name]}
																	</p>
																)}
														</div>
													);
												}}
											/>
										</Grid>
									</Grid>
									<Grid container className={classes.root} spacing={1}>
										<Grid item xs={12} md={6} lg={4}>
											<Field
												name="deparmentId"
												render={({ field, form }) => {
													return (
														<div
															className={classNames(classes.selectRoot, {
																error:
																	form.touched[field.name] &&
																	form.errors[field.name]
															})}
														>
															<InputLabel className={classes.inputLabel}>
																Departamento
															</InputLabel>
															<Select
																isSearchable
																value={field.value}
																onChange={selected =>
																	form.setFieldValue(field.name, selected.value)
																}
																options={selectDepartments}
																onMenuClose={() =>
																	form.setFieldTouched(field.name, true, true)
																}
																onMenuOpen={() =>
																	form.setFieldTouched(field.name, false)
																}
															/>
															{form.touched[field.name] &&
																form.errors[field.name] && (
																	<p className="MuiFormHelperText-root Mui-error">
																		{form.errors[field.name]}
																	</p>
																)}
														</div>
													);
												}}
											/>
										</Grid>
									</Grid>

									<Grid container className={classes.root} spacing={1}>
										<Grid item xs={12} md={6} lg={4}>
											<FormControlLabel
												control={
													<Field
														label="Categoría Publica"
														name="isPublic"
														component={props => (
															<Switch
																checked={props.field.value}
																value={props.field.name}
																onChange={(e, value) => {
																	props.form.setFieldValue(
																		props.field.name,
																		value
																	);
																}}
															/>
														)}
													/>
												}
												label="Categoría Publica"
											/>
										</Grid>
									</Grid>

									{values.isPublic && (
										<Grid container className={classes.root} spacing={1}>
											<Grid item xs={12} md={6} lg={4}>
												<EntityTypeTable
													items={entityTypes}
													postSelectedRows={this.updateSelectedEntities}
												/>
											</Grid>
										</Grid>
									)}

									<Grid container className={classes.root} spacing={1}>
										<Grid item xs={12} md={6} lg={4}>
											<FormControlLabel
												control={
													<Field
														label="Activo"
														name="isActive"
														component={props => (
															<Switch
																checked={props.field.value}
																value={props.field.name}
																onChange={(e, value) =>
																	props.form.setFieldValue(
																		props.field.name,
																		value
																	)
																}
															/>
														)}
													/>
												}
												label="Activo"
											/>
											<br />
											<br />
										</Grid>

										<Grid item xs={12}>
											<Button
												variant="contained"
												color="primary"
												disabled={isSubmitting}
												onClick={submitForm}
											>
												Guardar
											</Button>
										</Grid>
									</Grid>
								</Form>
							</SaveContainer>
						)}
					/>
				)}
			</React.Fragment>
		);
	}
}

GeneralInfo.defaultProps = {
	deparments: []
};

export default withStyles(styles)(WithGeneralInfo(GeneralInfo));
