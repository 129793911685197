import React from 'react';

// Externals
import PropTypes from 'prop-types';
import tableLocalizations from './localization';
import MaterialTable from "material-table";

// Material helpers
import { withStyles } from '@material-ui/core';
import styles from './styles';

class Table extends React.Component {
    static propTypes = {
        items: PropTypes.array,
        columns: PropTypes.array,
        title: PropTypes.string,
        editOptions: PropTypes.object,
        actions: PropTypes.array,
        options: PropTypes.object,
        style: PropTypes.object
    };

    static defaultProps = {
        editOptions: null,
        title: " ",
        items: [],
        options: {
            pageSize: 15,
            pageSizeOptions: [5, 10, 15, 20],
            search: true
        },
        style: { width: '100%' }
    }

    render() {
        const {
            title,
            items,
            columns,
            editOptions,
            options,
            actions,
            style
        } = this.props;

        return (
            <MaterialTable
                title={title}
                columns={columns}
                data={items}
                editable={editOptions}
                localization={tableLocalizations}
                options={options}
                actions={actions}
                style={style}
                {...this.props}
            />
        );
    }
}

export default withStyles(styles)(Table);
