import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { WithNotifications } from 'hoc';

import {
    Button,
    InputLabel,
    FormControlLabel,
    Grid,
    Switch,
    Dialog,
    DialogContent,
    // DialogContentText,
    DialogActions,
    DialogTitle
} from '@material-ui/core';

import {
    Table,
    MultiSelect,
    SaveContainer
} from "components";
import { TextField } from 'formik-material-ui';
import { Formik, Field, Form } from 'formik';
import { validationSchema, styles } from "./resources";
import userGroupService from "services/userGroupService";

class GeneralInfo extends React.Component {
    static propTypes = {
        id: PropTypes.number,
        items: PropTypes.array,
        users: PropTypes.array,
        onBack: PropTypes.func,
        onCreate: PropTypes.func,
        onUpdate: PropTypes.func,
    };

    state = {
        item: {
            code: "",
            detail: "",
            isActive: true,
            members: []
        },
        selectedUsers: [],
        open: false
    }

    membersColumns = [
        {
            title: "Usuario",
            field: "userId",
            render: rowData => <span>{this.getUserName(rowData.userId)}</span>
        }
    ]

    componentDidMount() {
        this.getExistingItem();
    }

    getExistingItem = async () => {
        const {
            id
        } = this.props;

        if (!id) {
            return;
        }

        const response = await userGroupService.getById(id).catch(() => this.setState({ isLoading: false }));

        if (!response.data || response.data.status == "Fail") {
            this.props.history.push("/");
            return;
        }

        this.setState({ item: response.data.data });
    }

    getUserName = (userId) => {
        const { users } = this.props;

        const user = users.find(x => x.id == userId);

        return user ? user.displayName : "";
    }

    saveGroup = async (values) => {
        const model = { ...this.state.item, ...values };

        let response = null;
        if (this.props.id) {
            response = await userGroupService.update(model);
        }
        else {
            response = await userGroupService.create(model);
        }

        if (response == null || !response.data || response.data.status == "Fail") {
            this.props.addNotification({ message: "No se pudo guardar", options: { variant: "error" } });
            return;
        }

        this.props.addNotification({ message: "Grupo guardado correctamente", options: { variant: "success" } });

        if (this.props.id) {
            this.getExistingItem();
        }
    }

    handleClose = () => {
        this.setState({ open: false, selectedUsers: [] });
    }

    addUsers = () => {
        const {
            item,
            selectedUsers
        } = this.state;

        if (!selectedUsers.length) {
            this.handleClose();
            this.props.addNotification({ message: "No hay usuario seleccionados", options: { variant: "error" } });
            return;
        }

        let members = [...item.members];

        selectedUsers.forEach(i => {
            if (members.find(x => x.userId == i))
                return;

            members.push({
                userId: i,
                isActive: true,
                userGroupId: this.props.id || 0
            });
        });

        this.setState(ps => ({ item: { ...ps.item, members } }));
        this.handleClose();
    }

    removeMember = (member) => {
        const {
            item
        } = this.state;

        let members = [...item.members];
        members = members.filter(x => x.userId != member.userId);
        this.setState(ps => ({ item: { ...ps.item, members } }));
    }

    render() {
        const {
            classes,
            users
        } = this.props;

        const selectUsers = users.map(i => ({ value: i.id, label: i.displayName }));

        const {
            item,
            open,
            selectedUsers
        } = this.state;

        return (
            <React.Fragment>
                <Formik
                    enableReinitialize
                    initialValues={item}
                    validationSchema={validationSchema}
                    onSubmit={async (values, form) => {
                        await this.saveGroup(values);
                        form.setSubmitting(false);
                    }}
                    render={({ submitForm, isSubmitting }) => (
                        <SaveContainer saveContent={submitForm}>
                        <Form>
                            <Grid container className={classes.root}>
                                <Grid item xs={12} md={6}>
                                    <Grid item xs={11}>
                                        <Field
                                            type="text"
                                            label="Nombre"
                                            name="code"
                                            margin="normal"
                                            component={TextField}
                                            fullWidth
                                            autoFocus
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Field
                                            type="text"
                                            label="Descripción"
                                            name="detail"
                                            margin="normal"
                                            component={TextField}
                                            fullWidth
                                            autoFocus
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <br />
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    label="Activo"
                                                    name="isActive"
                                                    component={(props) => (
                                                        <Switch
                                                            checked={props.field.value}
                                                            value={props.field.name}
                                                            onChange={(e, value) => props.form.setFieldValue(props.field.name, value)}
                                                        />
                                                    )}
                                                />
                                            }
                                            label="Activo"
                                        />
                                        <br />
                                        <br />
                                    </Grid>
                                    <Grid container className={classes.root} spacing={1}>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                                onClick={submitForm}
                                            >
                                                Guardar
										</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid item xs={12}>
                                        <Field
                                            name="members"
                                            render={({ field, form }) => {
                                                return (
                                                    <div
                                                        className={
                                                            classNames(
                                                                classes.selectRoot,
                                                                { "error": form.touched[field.name] && form.errors[field.name] }
                                                            )
                                                        }
                                                    >
                                                        <InputLabel>Miembros del grupo</InputLabel>
                                                        {
                                                            form.touched[field.name] && form.errors[field.name] &&
                                                            <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                        }
                                                        <br />
                                                        <Table
                                                            options={{ pageSize: 5, }}
                                                            data={item.members}
                                                            columns={this.membersColumns}
                                                            actions={[
                                                                {
                                                                    icon: 'delete',
                                                                    tooltip: "Quitar",
                                                                    onClick: (e, rowData) => this.removeMember(rowData)
                                                                },
                                                                {
                                                                    icon: 'add',
                                                                    isFreeAction: true,
                                                                    tooltip: "Agregar usuarios",
                                                                    onClick: () => this.setState({ open: true })
                                                                }
                                                            ]}
                                                        />
                                                        
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                        </SaveContainer>
                    )}
                />
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Agregar usuario al grupo</DialogTitle>
                    <DialogContent>
                        <MultiSelect
                            closeMenuOnSelect={false}
                            onChange={(selected) => {
                                this.setState({ selectedUsers: selected });
                            }}
                            value={selectedUsers}
                            options={selectUsers}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleClose}
                            color="primary"
                        >
                            Cancelar
                        </Button>
                        <Button onClick={this.addUsers} color="primary">
                            Agregar
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default WithNotifications(withRouter(withStyles(styles)(GeneralInfo)));