import React, { Component } from 'react';
import classNames from 'classnames';

// Externals
import PropTypes from 'prop-types';

//Material components
import { withStyles } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { CompanyLocationTable } from './components';
import { CompanyLocationEditForm } from './components';
import { WithCompanyLocations } from './components/hoc';

import { withSnackbar } from 'notistack';

import { injectIntl } from 'react-intl';

// Component styles
import styles from './style';

class CompanyLocations extends Component {
	state = {
		isLoading: false,
		companyLocations: [],
		showDetailOf: null,
		addItem: false
	};

	componentDidMount() {
		const { getCompanyLocations } = this.props;
		getCompanyLocations();
	}

	shouldComponentUpdate(nextProps) {
		const { enqueueSnackbar, clearMessage, intl } = this.props;

		if (
			nextProps.messageOperation &&
			nextProps.messageOperation !== this.props.messageOperation
		) {
			enqueueSnackbar(
				intl.formatMessage({ id: nextProps.messageOperation.message }),
				{ variant: nextProps.messageOperation.type }
			);
			clearMessage();
		}

		return true;
	}

	render() {
		const {
			classes,
			companyLocations,
			isLoading,
			deleteCompanyLocation,
			getIsvalidEdit,
			isValidEdit,
			clearIsValidEdit
		} = this.props;
		const { showDetailOf, addItem } = this.state;

		return (
			<DashboardLayout title="Sucursales">
				<div
					className={
						classNames(
							classes.root,
							{
								[classes.hidden]: showDetailOf || addItem
							}
						)
					}
				>
					<div className={classes.content}>
						<CompanyLocationTable
							items={companyLocations}
							onDelete={deleteCompanyLocation}
							onAddClick={() => this.setState({ addItem: true })}
							onEditClick={id => {
								this.setState({ showDetailOf: id });
							}}
							onIsValidEdit={getIsvalidEdit}
							isValidEdit={isValidEdit}
							onClearIsValidEdit={clearIsValidEdit}
							isLoading={isLoading}
						/>
					</div>
				</div>
				{(showDetailOf != null || addItem) && (
					<div className={classes.root}>
						<div className={classes.content}>
							<CompanyLocationEditForm
								id={showDetailOf}
								onBack={() =>
									this.setState({ showDetailOf: null, addItem: false })
								}
							/>
						</div>
					</div>
				)}
			</DashboardLayout>
		);
	}
}

CompanyLocations.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
	withSnackbar(WithCompanyLocations(injectIntl(CompanyLocations)))
);
