import React from "react";
import {
    Button,
    CircularProgress,
} from '@material-ui/core';

import {
    AddCircleOutlineOutlined,
} from '@material-ui/icons';

const FileInput = ({ id, showSelected, value, onChange, loading, ...rest }) => {
    return (
        <div>
            {showSelected && Boolean(value.length) && (
                <div>Seleccionados: {value.map(f => f.name).join(", ")}</div>
            )}
            <label htmlFor={id}>
                <Button
                    disabled={loading}
                    size="small"
                    component="span"
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 5 }}
                >
                    {
                        loading ? <CircularProgress /> : <AddCircleOutlineOutlined />
                    }
                    Agregar
                </Button>
            </label>
            <input
                {...rest}
                style={{ display: "none" }}
                id={id}
                type="file"
                onChange={e => {
                    onChange && onChange([...e.target.files]);
                }}
            />
        </div>
    );
};

export default FileInput;