import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  CategoryCreators,
  selectCategories,
  selectSubCategories,
  selectIsLoading,
  selectCategory,
  selectMessageOperation
} from 'redux/CategoryRedux';
import { SubCategoryCreators, selectSubCategory } from 'redux/SubCategoryRedux';

export const mapStateToProps = state => ({
  category: selectCategory(state),
  categories: selectCategories(state),
  subCategory: selectSubCategory(state),
  subCategories: selectSubCategories(state),
  isLoading: selectIsLoading(state),
  messageOperation: selectMessageOperation(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCategory: CategoryCreators.getCategoryRequest,
      getCategories: CategoryCreators.getCategoriesRequest,
      createCategory: CategoryCreators.createCategoryRequest,
      updateCategory: CategoryCreators.updateCategoryRequest,
      deleteCategory: CategoryCreators.deleteCategoryRequest,
      clearMessage: CategoryCreators.categorySetMessage,
      clearSubCategory: SubCategoryCreators.subCategoryGetSuccess
    },
    dispatch
  );

export const hocConector = WrappedComponent => {
  const hoc = ({ ...props }) => <WrappedComponent {...props} />;

  hoc.propTypes = {
    /** Get all company categories */
    getCategory: PropTypes.func,
    getCategories: PropTypes.func,
    getSubCategories: PropTypes.func,
    createCategory: PropTypes.func,
    updateCategory: PropTypes.func,
    deleteCategory: PropTypes.func,
    categories: PropTypes.array,
    deparments: PropTypes.array,
    clearMessage: PropTypes.func
  };

  return hoc;
};

export default Component =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hocConector(Component));
