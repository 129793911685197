import EntityBase from '../EntityBase';

class AttachmentConfig extends EntityBase {
  constructor(obj) {
    if (!obj) obj = {};
    super(obj);
    this.fileExt = obj.fileExt ? obj.fileExt : '';
    this.minSize = obj.minSize ? obj.minSize : 0;
    this.maxSize = obj.maxSize ? obj.maxSize : 0;
    this.namingConvention = obj.namingConvention ? obj.namingConvention : '';
    this.isRequired = obj.isRequired ? obj.isRequired : false;
    this.companyId = obj.companyId ? obj.companyId : undefined;
    this.subCategories = obj.subCategories ? obj.subCategories : [];
  }
}

export default AttachmentConfig;
