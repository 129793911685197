import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { PortletContent, Portlet } from 'components';
import {
	ActiveTickets,
	ClosedTickets,
	InProgressTickets,
	SentTickets,
	SubscribedTickets,
	TicketsToExpire,
	ChartTable,
	ChartWorkItemMonthly
} from './components';
import ticketsService from 'services/ticketsService';
import { CircularProgress } from '@material-ui/core';
import WithDashboard from './components/Hoc/WithDashboard';
import { WithSession } from 'hoc';

const styles = theme => ({
	root: {
		padding: theme.spacing.unit * 4
	},
	item: {
		height: '100%'
	}
});

class Dashboard extends Component {
	state = {
		summary: null,
		toExpire: null
	};

	componentDidMount = () => {
		const {
			getChartWorkItemData,
			session,
		} = this.props;

		if (session && session.entityTypeId === 1)
			getChartWorkItemData();
	};

	componentWillMount = () => {
		this.getSummary();
		this.getToExpire();
	};

	getSummary = async () => {
		const response = await ticketsService.getSummary();

		if (
			response.status != 200 ||
			response.data.status == 'Fail' ||
			!response.data ||
			!response.data.data
		) {
			return;
		}

		this.setState({ summary: response.data.data });
	};

	getToExpire = async () => { };

	render() {
		const {
			classes,
			chartWorkItemCategories,
			chartWorkItemLocations,
			chartWorkItemDepartments,
			chartWorkItemMonthly,
			session,
		} = this.props;
		const { summary } = this.state;

		if (session && session.entityTypeId === 3) {
			return (
				<DashboardLayout title="Dashboard">
					<div className={classes.root}>
						{summary && (
							<React.Fragment>
								<Grid container spacing={4}>
									<Grid item lg={3} sm={6} xl={3} xs={12}>
										<ActiveTickets
											className={classes.item}
											counter={summary.initialTickets}
											onClick={() => {
												this.props.history.push('/sent-tickets?filter=active');
											}}
										/>
									</Grid>
									<Grid item lg={3} sm={6} xl={3} xs={12}>
										<ClosedTickets
											className={classes.item}
											counter={summary.closedTickets}
											onClick={() => {
												this.props.history.push('/sent-tickets?filter=closed');
											}}
										/>
									</Grid>
									<Grid item lg={3} sm={6} xl={3} xs={12}>
										<SentTickets
											className={classes.item}
											counter={summary.totalSentTickets}
											onClick={() => {
												this.props.history.push('/sent-tickets');
											}}
										/>
									</Grid>
								</Grid>
							</React.Fragment>
						)}
					</div>
				</DashboardLayout>
			);
		}

		return (
			<DashboardLayout title="Dashboard">
				<div className={classes.root}>
					{summary && (
						<React.Fragment>
							<Grid container spacing={4}>
								<Grid item lg={3} sm={6} xl={3} xs={12}>
									<ActiveTickets
										className={classes.item}
										counter={summary.initialTickets}
										onClick={() => {
											this.props.history.push('/sent-tickets?filter=active');
										}}
									/>
								</Grid>
								<Grid item lg={3} sm={6} xl={3} xs={12}>
									<ClosedTickets
										className={classes.item}
										counter={summary.closedTickets}
										onClick={() => {
											this.props.history.push('/sent-tickets?filter=closed');
										}}
									/>
								</Grid>
								<Grid item lg={3} sm={6} xl={3} xs={12}>
									<InProgressTickets
										className={classes.item}
										counter={summary.assignedTickets}
										onClick={() => {
											this.props.history.push('/received-tickets');
										}}
									/>
								</Grid>
								<Grid item lg={3} sm={6} xl={3} xs={12}>
									<SubscribedTickets
										className={classes.item}
										counter={summary.totalSubscribedTickets}
										onClick={() => {
											this.props.history.push('/subscribed-tickets');
										}}
									/>
								</Grid>
								<Grid item lg={3} sm={6} xl={3} xs={12}>
									<SentTickets
										className={classes.item}
										counter={summary.totalSentTickets}
										onClick={() => {
											this.props.history.push('/sent-tickets');
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TicketsToExpire />
								</Grid>

								<Grid item xs={12}>
									<Portlet>
										<PortletContent noPadding>
											<Grid
												container
												noPadding
												className={classes.root}
												spacing={1}
											>
												<Grid item xs={12} alignItems={'center'}>
													<Typography variant="h4" gutterBottom>
														Tickets Generados
                          </Typography>
													<Typography variant="subtitle1" gutterBottom>
														Ultimos 30 días
                          </Typography>
												</Grid>

												<Grid item xs={12} xl={4} md={4}>
													<ChartTable
														items={chartWorkItemCategories}
														labelColumn={'chart.table.field.category'}
														labelField={'category'}
														valueField={'tickets'}
													/>
												</Grid>
												<Grid item xs={12} xl={4} md={4}>
													<ChartTable
														items={chartWorkItemLocations}
														labelColumn={'chart.table.field.location'}
														labelField={'location'}
														valueField={'tickets'}
													/>
												</Grid>
												<Grid item xs={12} xl={4} md={4}>
													<ChartTable
														items={chartWorkItemDepartments}
														labelColumn={'chart.table.field.deparment'}
														labelField={'department'}
														valueField={'tickets'}
													/>
												</Grid>

												<Grid item xs={12} md={6}>
													<ChartWorkItemMonthly data={chartWorkItemMonthly} />
												</Grid>
											</Grid>
										</PortletContent>
									</Portlet>
								</Grid>

								{/* <Grid
								item
								lg={8}
								md={12}
								xl={9}
								xs={12}
							>
								<TicketsChart className={classes.item} />
							</Grid> */}
								{/* <Grid
								item
								lg={8}
								md={12}
								xl={9}
								xs={12}
							>
								<TicketsTable className={classes.item} />
							</Grid> */}
							</Grid>
						</React.Fragment>
					)}
					{!summary && (
						<Grid
							container
							justify="center"
							direction="column"
							alignItems="center"
							height="500px"
						>
							<CircularProgress />
						</Grid>
					)}
				</div>
			</DashboardLayout>
		);
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired
};

export default compose(
	withRouter,
	withStyles(styles),
	WithSession,
)(WithDashboard(Dashboard));
