import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  WorkItemTaskCreators,
  selectIsLoading,
  selectSubCategoryTasks
} from 'redux/WorkItemTaskRedux';
import { selectUserOptions } from 'redux/UserRedux';

export const mapStateToProps = state => ({
  tasks: selectSubCategoryTasks(state),
  isLoading: selectIsLoading(state),
  users: selectUserOptions(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSubCategoryTasks:
        WorkItemTaskCreators.workItemTaskGetByWorkItemIdRequest
    },
    dispatch
  );

export const hocConector = WrappedComponent => {
  const hoc = ({ ...props }) => <WrappedComponent {...props} />;

  hoc.propTypes = {
    getSubCategoryTasks: PropTypes.func
  };

  return hoc;
};

export default Component =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hocConector(Component));
