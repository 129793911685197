import { takeLatest, call, put, all } from 'redux-saga/effects';
import { CategoryTypes, CategoryCreators } from 'redux/CategoryRedux';
import categoriesService from 'services/categoriesService';
import subCategoriesService from 'services/subCategoriesService';
import categoryLocationService from 'services/categoryLocationService';
import { getAll as getCompanyLocations } from './companyLocationSagas';
import { getAll as getAllUsers } from './userSagas';
import { getAll as getResponseTimeTypes } from './responseTimeTypeSagas';
import { getAll as getDepartments } from './departmentSagas';
import { getAll as getEntityTypes } from './entityTypeSagas';
import { MessageOperation } from '../constants';

export function* getCategoriesCatalogs() {
  yield put(CategoryCreators.setLoading(true));
  yield* getResponseTimeTypes();
  yield* getAllUsers();
  yield* getCompanyLocations(false);
  yield* getDepartments(false);
  yield* getEntityTypes(false);
  yield* getAll();
  yield put(CategoryCreators.setLoading(false));
}

export function* getAll(showLoading) {
  if (showLoading) yield put(CategoryCreators.setLoading(true));
  const response = yield call(categoriesService.getAll);

  if (response.ok) {
    yield put(CategoryCreators.getCategoriesSuccessfull(response.data.data));
  } else {
    yield put(CategoryCreators.categorySetMessage(MessageOperation.error));
  }
  if (showLoading) yield put(CategoryCreators.setLoading(false));
}

export function* getById({ id }) {
  yield put(CategoryCreators.setLoading(true));

  const response = yield call(categoriesService.getById, id);
  //const subCategories = yield call(subCategoriesService.getByCategoryId, id)

  if (response.ok) {
    // {..., subCategories}
    yield put(CategoryCreators.getCategorySuccessfull(response.data.data));
  } else {
    yield put(CategoryCreators.categorySetMessage(MessageOperation.error));
  }

  yield put(CategoryCreators.setLoading(false));
}

export function* create({ item }) {
  yield put(CategoryCreators.setLoading(true));

  const response = yield call(categoriesService.create, item);

  if (response.ok) {
    yield put(
      CategoryCreators.createCategoryOK(
        {
          ...response.data.data,
          ownerName: item.ownerName,
          departmentName: item.departmentName
        },
        MessageOperation.success
      )
    );
  } else {
    yield put(CategoryCreators.categorySetMessage(MessageOperation.error));
  }

  yield put(CategoryCreators.setLoading(false));
}

export function* update({ item }) {
  yield put(CategoryCreators.setLoading(true));

  const response = yield call(categoriesService.update, item);

  if (response.ok) {
    yield put(
      CategoryCreators.updateCategoryOK(item, MessageOperation.success)
    );
  } else {
    yield put(CategoryCreators.categorySetMessage(MessageOperation.error));
  }

  yield put(CategoryCreators.setLoading(false));
}

export function* deleteItem({ id }) {
  yield put(CategoryCreators.setLoading(true));

  const response = yield call(categoriesService.delete, id);

  if (response.ok) {
    yield put(CategoryCreators.deleteCategoryOK(id, MessageOperation.success));
  } else {
    if (response.status == 409) {
      yield put(CategoryCreators.categorySetMessage(MessageOperation.conflict));
    } else {
      yield put(CategoryCreators.categorySetMessage(MessageOperation.error));
    }
  }

  yield put(CategoryCreators.setLoading(false));
}

export function* getSubCategories() {
  const response = yield call(subCategoriesService.getAll);

  if (response.ok) {
    yield put(CategoryCreators.getSubCategoriesSuccessfull(response.data.data));
  } else {
    yield put(CategoryCreators.categorySetMessage(MessageOperation.error));
  }
}

export function* createSubCategory({ item }) {
  const response = yield call(subCategoriesService.create, item);

  if (response.ok) {
    yield put(
      CategoryCreators.createSubCategoryOK(
        response.data.data,
        MessageOperation.success
      )
    );
  } else {
    yield put(CategoryCreators.categorySetMessage(MessageOperation.error));
  }
}

export function* updateSubCategory({ item }) {
  const response = yield call(subCategoriesService.update, item);

  if (response.ok) {
    yield put(
      CategoryCreators.updateSubCategoryOK(item, MessageOperation.success)
    );
  } else {
    yield put(CategoryCreators.categorySetMessage(MessageOperation.error));
  }
}

export function* deleteSubCategory({ id }) {
  const response = yield call(subCategoriesService.delete, id);

  if (response.ok) {
    yield put(
      CategoryCreators.deleteSubCategoryOK(id, MessageOperation.success)
    );
  } else {
    yield put(CategoryCreators.categorySetMessage(MessageOperation.error));
  }
}

export function* categoryLocationCatalogs() {
  // yield* getUsers();
  yield* getCompanyLocations();
}

export function* categoryLocationSaveMultiple({ items }) {
  if (!items.length) return;

  const categoryId = items[0].categoryId;

  yield put(CategoryCreators.setLoading(true));
  const response = yield call(categoryLocationService.saveMultiple, items);

  if (response.ok) {
    yield categoryLocationGetByCategoryId({ categoryId });
    yield put(
      CategoryCreators.categoryLocationCreateSuccess(MessageOperation.success)
    );
  } else {
    yield put(CategoryCreators.categorySetMessage(MessageOperation.error));
  }

  yield put(CategoryCreators.setLoading(false));
}

export function* categoryLocationGetByOwnerId({ categoryId, ownerId }) {
  yield put(CategoryCreators.setOwnerLoading(true));
  const response = yield call(
    categoryLocationService.getByCategoryId,
    categoryId,
    ownerId
  );

  if (response.ok) {
    yield put(
      CategoryCreators.categoryLocationGetByOwnerIdSuccess(
        response.data.data,
        ownerId
      )
    );
  } else {
    yield put(CategoryCreators.categorySetMessage(MessageOperation.error));
  }

  yield put(CategoryCreators.setOwnerLoading(false));
}

export function* categoryLocationGetByCategoryId({ categoryId }) {
  yield put(CategoryCreators.setOwnerLoading(true));
  const response = yield call(
    categoryLocationService.getByCategoryId,
    categoryId
  );

  if (response.ok && response.data.data && Array.isArray(response.data.data)) {
    yield put(
      CategoryCreators.categoryLocationGetByCategoryIdSuccess(
        response.data.data
      )
    );
  } else {
    yield put(CategoryCreators.categorySetMessage(MessageOperation.error));
  }

  yield put(CategoryCreators.setOwnerLoading(false));
}

/**
 * CategoriesSagas
 */
function* categoriesSagas() {
  yield all([
    takeLatest(CategoryTypes.GET_CATEGORY_REQUEST, getById),
    takeLatest(CategoryTypes.GET_CATEGORIES_REQUEST, getCategoriesCatalogs),
    takeLatest(CategoryTypes.CREATE_CATEGORY_REQUEST, create),
    takeLatest(CategoryTypes.UPDATE_CATEGORY_REQUEST, update),
    takeLatest(CategoryTypes.DELETE_CATEGORY_REQUEST, deleteItem),
    takeLatest(
      CategoryTypes.CATEGORY_LOCATION_CATALOGS_REQUEST,
      categoryLocationCatalogs
    ),
    takeLatest(
      CategoryTypes.CATEGORY_LOCATION_CREATE_REQUEST,
      categoryLocationSaveMultiple
    ),
    takeLatest(
      CategoryTypes.CATEGORY_LOCATION_GET_BY_OWNER_ID_REQUEST,
      categoryLocationGetByOwnerId
    ),
    takeLatest(
      CategoryTypes.CATEGORY_LOCATION_GET_BY_CATEGORY_ID_REQUEST,
      categoryLocationGetByCategoryId
    )
  ]);
}

export default categoriesSagas;
