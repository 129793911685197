import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
	Avatar,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	Typography
} from '@material-ui/core';

// Material icons
import {
	DashboardOutlined as DashboardIcon,
	PeopleOutlined as PeopleIcon,
	CategoryOutlined as CategoriesIcon,
	WorkOutlined as AdminIcon,
	AssignmentIndOutlined as RolesIcon,
	PlaceOutlined as PlaceIcon,
	ExpandLess,
	ExpandMore,
	CreateOutlined,
	ForumOutlined,
	CallReceivedOutlined,
	CallMadeOutlined,
	LabelImportantOutlined,
	GroupOutlined,
	SearchOutlined,
	AttachFileOutlined,
	VerifiedUserOutlined,
	AssessmentOutlined,
} from '@material-ui/icons';

import ListIcon from '@material-ui/icons/List';

// TMS components
import Token from '../../../../contract/responses/token';

// Component styles
import styles from './styles';

import WithSession from '../../../../hoc/WithSession';
import WithAuth from '../../../../hoc/WithAuth';

class Sidebar extends Component {
	state = {
		administrationOpen: false,
		ticketsOpen: false
	};

	hasPermission = businessFunctionId => {
		return (
			this.props.permissions &&
			this.props.permissions.find(x => x.id == businessFunctionId)
		);
	};

	getCompanyLogo = () => {
		var logosFolder = '/images/logos/';
		return (
			logosFolder +
			(this.props.settings && this.props.settings.logo
				? this.props.settings.logo
				: 'companyLogo.png')
		);
	};

	render() {
		const { classes, className, session, permissionsLoaded } = this.props;
		const { administrationOpen, ticketsOpen } = this.state;

		if (!permissionsLoaded) {
			return null;
		}

		const rootClassName = classNames(classes.root, className);

		return (
			<nav className={rootClassName}>
				<div className={classes.logoWrapper}>
					<Link className={classes.logoLink} to="/">
						<img
							alt="Brainalytica logo"
							className={classes.logoImage}
							src={this.getCompanyLogo()}
						/>
					</Link>
				</div>
				<Divider className={classes.logoDivider} />
				<div className={classes.profile}>
					<Link className={classes.logoLink} to="/profile">
						<Avatar
							alt={session.userName}
							className={classes.avatar}
							src="/images/avatars/avatar_generic_0.png"
						/>
						<Typography className={classes.nameText} variant="h6">
							{session.displayName} ({session.userName})
						</Typography>
						<Typography className={classes.bioText} variant="caption">
							{session.email}
						</Typography>
					</Link>
				</div>
				<Divider className={classes.profileDivider} />
				<List component="div" disablePadding>
					{this.hasPermission(2) && (
						<ListItem
							activeClassName={classes.activeListItem}
							className={classes.listItem}
							component={NavLink}
							to="/dashboard"
						>
							<ListItemIcon className={classes.listItemIcon}>
								<DashboardIcon />
							</ListItemIcon>
							<ListItemText
								classes={{ primary: classes.listItemText }}
								primary="Dashboard"
							/>
						</ListItem>
					)}
					{this.hasPermission(3) && (
						<React.Fragment>
							<ListItem
								//activeClassName={classes.activeListItem}
								//component={}
								className={classes.listItem}
								onClick={() =>
									this.setState({ administrationOpen: !administrationOpen })
								}
							>
								<ListItemIcon className={classes.listItemIcon}>
									<AdminIcon />
								</ListItemIcon>
								<ListItemText
									classes={{ primary: classes.listItemText }}
									primary="Administración"
								/>
								{administrationOpen ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
							<Collapse
								in={administrationOpen}
								timeout="auto"
								unmountOnExit
								className={classes.groupItems}
							>
								<List component="div" disablePadding>
									{this.hasPermission(6) && (
										<ListItem
											activeClassName={classes.activeListItem}
											className={classes.listItem}
											component={NavLink}
											to="/attachmentconfig"
										>
											<ListItemIcon className={classes.listItemIcon}>
												<AttachFileOutlined />
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary="Archivos"
											/>
										</ListItem>
									)}
									{this.hasPermission(7) && (
										<ListItem
											activeClassName={classes.activeListItem}
											className={classes.listItem}
											component={NavLink}
											to="/companylocations"
										>
											<ListItemIcon className={classes.listItemIcon}>
												<PlaceIcon />
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary="Sucursales"
											/>
										</ListItem>
									)}
									{this.hasPermission(24) && (
										<ListItem
											activeClassName={classes.activeListItem}
											className={classes.listItem}
											component={NavLink}
											to="/departments"
										>
											<ListItemIcon className={classes.listItemIcon}>
												<PlaceIcon />
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary="Departamentos"
											/>
										</ListItem>
									)}
									{this.hasPermission(8) && (
										<ListItem
											activeClassName={classes.activeListItem}
											className={classes.listItem}
											component={NavLink}
											to="/categories"
										>
											<ListItemIcon className={classes.listItemIcon}>
												<CategoriesIcon />
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary="Categorias"
											/>
										</ListItem>
									)}
									{this.hasPermission(9) && (
										<ListItem
											activeClassName={classes.activeListItem}
											className={classes.listItem}
											component={NavLink}
											to="/sub-categories"
										>
											<ListItemIcon className={classes.listItemIcon}>
												<CategoriesIcon />
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary="Sub Categorias"
											/>
										</ListItem>
									)}
									{this.hasPermission(10) && (
										<ListItem
											activeClassName={classes.activeListItem}
											className={classes.listItem}
											component={NavLink}
											to="/users"
										>
											<ListItemIcon className={classes.listItemIcon}>
												<PeopleIcon />
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary="Usuarios"
											/>
										</ListItem>
									)}
									{this.hasPermission(11) && (
										<ListItem
											activeClassName={classes.activeListItem}
											className={classes.listItem}
											component={NavLink}
											to="/user-groups"
										>
											<ListItemIcon className={classes.listItemIcon}>
												<GroupOutlined />
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary="Grupos"
											/>
										</ListItem>
									)}
									{this.hasPermission(12) && (
										<ListItem
											activeClassName={classes.activeListItem}
											className={classes.listItem}
											component={NavLink}
											to="/roles"
										>
											<ListItemIcon className={classes.listItemIcon}>
												<RolesIcon />
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary="Roles"
											/>
										</ListItem>
									)}
									{this.hasPermission(22) && (
										<ListItem
											activeClassName={classes.activeListItem}
											className={classes.listItem}
											component={NavLink}
											to="/tasks"
										>
											<ListItemIcon className={classes.listItemIcon}>
												<ListIcon />
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary="Tareas"
											/>
										</ListItem>
									)}
									{this.hasPermission(23) && (
										<ListItem
											activeClassName={classes.activeListItem}
											className={classes.listItem}
											component={NavLink}
											to="/task-configuration"
										>
											<ListItemIcon className={classes.listItemIcon}>
												<ListIcon />
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary="Configurar Tareas"
											/>
										</ListItem>
									)}
									{this.hasPermission(17) && (
										<ListItem
											activeClassName={classes.activeListItem}
											className={classes.listItem}
											component={NavLink}
											to="/permissions"
										>
											<ListItemIcon className={classes.listItemIcon}>
												<VerifiedUserOutlined />
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary="Permisos"
											/>
										</ListItem>
									)}
								</List>
							</Collapse>
						</React.Fragment>
					)}
					{this.hasPermission(4) && (
						<React.Fragment>
							<ListItem
								activeClassName={classes.activeListItem}
								className={classes.listItem}
								onClick={() => this.setState({ ticketsOpen: !ticketsOpen })}
							>
								<ListItemIcon className={classes.listItemIcon}>
									<ForumOutlined />
								</ListItemIcon>
								<ListItemText
									classes={{ primary: classes.listItemText }}
									primary="Tickets"
								/>
								{ticketsOpen ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
							<Collapse
								in={ticketsOpen}
								timeout="auto"
								unmountOnExit
								className={classes.groupItems}
							>
								<List component="div" disablePadding>
									{this.hasPermission(13) && (
										<ListItem
											activeClassName={classes.activeListItem}
											className={classes.listItem}
											component={NavLink}
											to="/search-tickets"
										>
											<ListItemIcon className={classes.listItemIcon}>
												<SearchOutlined />
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary="Buscador"
											/>
										</ListItem>
									)}
									{this.hasPermission(14) && (
										<ListItem
											activeClassName={classes.activeListItem}
											className={classes.listItem}
											component={NavLink}
											to="/received-tickets"
										>
											<ListItemIcon className={classes.listItemIcon}>
												<CallReceivedOutlined />
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary="Recibidos"
											/>
										</ListItem>
									)}
									{this.hasPermission(15) && (
										<ListItem
											activeClassName={classes.activeListItem}
											className={classes.listItem}
											component={NavLink}
											to="/sent-tickets"
										>
											<ListItemIcon className={classes.listItemIcon}>
												<CallMadeOutlined />
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary="Creados"
											/>
										</ListItem>
									)}
									{this.hasPermission(16) && (
										<ListItem
											activeClassName={classes.activeListItem}
											className={classes.listItem}
											component={NavLink}
											to="/subscribed-tickets"
										>
											<ListItemIcon className={classes.listItemIcon}>
												<LabelImportantOutlined />
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary="Suscrito"
											/>
										</ListItem>
									)}
									{this.hasPermission(25) && (
										<ListItem
											activeClassName={classes.activeListItem}
											className={classes.listItem}
											component={NavLink}
											to="/assigned-tickets-report"
										>
											<ListItemIcon className={classes.listItemIcon}>
												<AssessmentOutlined />
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary="Reporte asignados"
											/>
										</ListItem>
									)}
								</List>
							</Collapse>
						</React.Fragment>
					)}
					{this.hasPermission(5) && (
						<ListItem
							activeClassName={classes.activeListItem}
							className={classes.listItem}
							component={NavLink}
							to="/ticket"
						>
							<ListItemIcon className={classes.listItemIcon}>
								<CreateOutlined />
							</ListItemIcon>
							<ListItemText
								classes={{ primary: classes.listItemText }}
								primary="Crear ticket"
							/>
						</ListItem>
					)}
				</List>
				{/*<Divider className={classes.listDivider} />
		<List
		  component="div"
		  disablePadding
		  subheader={
			<ListSubheader className={classes.listSubheader}>
			  Support
			</ListSubheader>
		  }
		>
		  <ListItem
			className={classes.listItem}
			component="a"
			href="https://devias.io/contact-us"
			target="_blank"
		  >
			<ListItemIcon className={classes.listItemIcon}>
			  <InfoIcon />
			</ListItemIcon>
			<ListItemText
			  classes={{ primary: classes.listItemText }}
			  primary="Customer support"
			/>
		  </ListItem>
		</List>*/}
			</nav>
		);
	}
}

Sidebar.propTypes = {
	session: PropTypes.object,
	className: PropTypes.string,
	classes: PropTypes.object.isRequired
};

Sidebar.defaultProps = {
	session: new Token()
};

export default withStyles(styles)(WithAuth(WithSession(Sidebar)));
