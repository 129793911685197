import React from 'react';
import { withStyles } from '@material-ui/core';
import { Loader, SaveContainer } from 'components';
import { Button, Grid } from '@material-ui/core';

import { Formik, Form, FieldArray } from 'formik';

import { validationSchema, styles } from './resources';

import WithPriorityLevels from '../hoc/WithPriorityLevels';

import { withSnackbar } from 'notistack';

import { injectIntl } from 'react-intl';

import PriorityTable from './PriorityTable';

class SubCategoryPriority extends React.Component {
  state = {
    priorityLevels: [],
    subCategoryId: 0
  };

  componentDidMount() {
    const {
      getPriorityLevels,
      subCategoryId,
      getResponseTimeTypes,
      responseTimeTypes
    } = this.props;

    getPriorityLevels(subCategoryId);

    if (!responseTimeTypes.length) {
      getResponseTimeTypes();
    }
  }

  shouldComponentUpdate(nextProps) {
    const { intl, enqueueSnackbar, clearMessage } = this.props;

    if (nextProps.priorityLevels !== this.props.priorityLevels) {
      this.setState({ priorityLevels: nextProps.priorityLevels }, () => true);
    }

    if (
      nextProps.messageOperation &&
      nextProps.messageOperation !== this.props.messageOperation
    ) {
      enqueueSnackbar(
        intl.formatMessage({ id: nextProps.messageOperation.message }),
        { variant: nextProps.messageOperation.type }
      );
      clearMessage();
    }

    return true;
  }

  render() {
    const {
      subCategoryId,
      classes,
      savePriorityLevels,
      isLoading,
      responseTimeTypes
    } = this.props;

    const { priorityLevels } = this.state;

    return (
      <React.Fragment>
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            enableReinitialize
            initialValues={{ priorityLevels: priorityLevels }}
            onSubmit={values => {
              let request = {
                subCategoryId: subCategoryId,
                priorityLevels: values.priorityLevels.map(item => ({
                  id: item.id,
                  priorityId: item.priorityId,
                  responseTimeSetting: item.responseTimeSetting,
                  responseTimeTypeId: item.responseTimeTypeId,
                  subCategoryId: subCategoryId,
                  isActive: item.isActive
                }))
              };
              savePriorityLevels(request);
            }}
            validationSchema={validationSchema}
            render={({
              errors,
              values,
              submitForm,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              touched
            }) => (
              <SaveContainer saveContent={submitForm}>
                <Grid
                  container
                  justify="center"
                  className={classes.root}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Form autoComplete="off">
                      <FieldArray
                        name="priorityLevels"
                        render={() => (
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <PriorityTable
                                items={values.priorityLevels}
                                responseTimeTypes={responseTimeTypes}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                errors={errors}
                                touched={touched}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <Button
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}
                                onClick={submitForm}
                              >
                                Guardar
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      />
                    </Form>
                  </Grid>
                </Grid>
              </SaveContainer>
            )}
          />
        )}
      </React.Fragment>
    );
  }
}

SubCategoryPriority.defaultProps = {
  priorityLevels: []
};

export default withStyles(styles)(
  withSnackbar(WithPriorityLevels(injectIntl(SubCategoryPriority)))
);
