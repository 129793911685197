export default theme => ({
	root: {
		flexGrow: 1
	},
	selectRoot: {
		marginTop: theme.spacing.unit * 3
	},
	inputLabel: {
        marginBottom: theme.spacing.unit * 1,
        fontSize: 11
    },
    columnGrid: {
        height: '500px'
    },
    giantIcon: {
        width: '70px',
        height: '70px'
    },
});