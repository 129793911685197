import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, Checkbox } from '@material-ui/core';

// Shared components
import { Portlet, PortletContent, Table } from 'components';
import { withSnackbar } from 'notistack';

import { injectIntl } from 'react-intl';

// Component styles
import styles from './styles';

class RolesTable extends Component {
  state = {
    rowsPerPage: 15,
    page: 0,
    columns: []
  };

  componentDidMount() {
    this.setState({ columns: this.getColumns() });
  }

  getColumns = () => {
    const { intl } = this.props;

    return [
      {
        title: intl.formatMessage({ id: 'catalog.roles.table.columns.code' }),
        field: 'code'
      },
      {
        title: intl.formatMessage({ id: 'catalog.roles.table.columns.detail' }),
        field: 'detail'
      },
      {
        title: intl.formatMessage({
          id: 'catalog.roles.table.columns.isactive'
        }),
        field: 'isActive',
        editComponent: rowData => {
          return (
            <Checkbox
              checked={rowData.isActive != null ? rowData.isActive : true}
              onChange={(e, value) => {
                rowData.onChange(value);
              }}
            />
          );
        },
        render: rowData => (
          <span>
            {rowData.isActive
              ? intl.formatMessage({ id: 'item.status.active' })
              : intl.formatMessage({ id: 'item.status.inactive' })}
          </span>
        )
      }
    ];
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes, className, items } = this.props;
    const { columns } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <Table
            columns={columns}
            items={items}
            editOptions={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  if (items.find(x => x.code == newData.code)) {
                    this.props.enqueueSnackbar('El rol ya existe', {
                      variant: 'error'
                    });
                    reject(false);
                    return;
                  }
                  if (
                    (newData && newData.code.length === 0) ||
                    newData === null
                  ) {
                    this.props.enqueueSnackbar('Ingrese los datos del Rol', {
                      variant: 'error'
                    });
                    reject(false);
                    return;
                  }
                  newData.isActive = newData.isActive ? newData.isActive : true;
                  this.props.onCreate(newData);
                  resolve();
                }),
              onRowUpdate: newData =>
                new Promise((resolve, reject) => {
                  if (!newData.isEditable) {
                    this.props.enqueueSnackbar('El rol no es editable.', {
                      variant: 'error'
                    });
                    reject(false);
                    return;
                  }
                  this.props.onUpdate(newData);
                  resolve();
                }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  if (!oldData.isEditable) {
                    this.props.enqueueSnackbar('El rol no es editable.', {
                      variant: 'error'
                    });
                    reject(false);
                    return;
                  }
                  this.props.onDelete(oldData.id);
                  resolve();
                })
            }}
          />
        </PortletContent>
      </Portlet>
    );
  }
}

RolesTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  items: PropTypes.array.isRequired
};

RolesTable.defaultProps = {
  items: [],
  onCreate: () => {},
  onUpdate: () => {},
  onDelete: () => {}
};

export default withStyles(styles)(withSnackbar(injectIntl(RolesTable)));
