import React, { Component } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import compose from 'recompose/compose';
import ticketsService from 'services/ticketsService';
import { withRouter } from 'react-router-dom';

// Material components
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel
} from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
  Status
} from 'components';

// Component styles
import styles from './styles';

const statusColors = {
  delivered: 'cerrado',
  pending: 'en progreso',
  refund: 'activo'
};

class TicketsToExpire extends Component {
  signal = false;

  state = {
    isLoading: false,
    items: []
  };

  getTicketsToExpire = async () => {
    try {
      this.setState({ isLoading: true });
      const response = await ticketsService.getToExpire();

      if (
        response.status != 200 ||
        response.data.status == 'Fail' ||
        !response.data ||
        !response.data.data
      ) {
        this.setState({ isLoading: false });
        return;
      }
      this.setState({
        items: response.data.data,
        direction: 'asc',
        isLoading: false
      });
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  };

  compareValues = (key, order = 'desc') => {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  };

  componentDidMount() {
    this.signal = true;
    this.getTicketsToExpire();
  }

  componentWillUnmount() {
    this.signal = false;
  }

  getStatus = statusId => {
    switch (statusId) {
      case 1:
        return 'Inicial';
      case 2:
        return 'En progreso';
      case 3:
        return 'Finalizado';
    }
  };

  render() {
    const { classes, className } = this.props;
    const { isLoading, items: orders, direction } = this.state;

    let ordersTotal = orders.length;

    const rootClassName = classNames(classes.root, className);
    const showOrders = !isLoading && orders.length > 0;

    return (
      <Portlet className={rootClassName}>
        <PortletHeader noDivider>
          <PortletLabel
            subtitle={isLoading ? '' : `${ordersTotal}`}
            title="Tickets por expirar"
          />
          <PortletToolbar>
            <Button
              className={classes.newEntryButton}
              color="primary"
              size="small"
              variant="outlined"
              onClick={() => {
                this.props.history.push('/ticket');
              }}
            >
              Nuevo Ticket
            </Button>
          </PortletToolbar>
        </PortletHeader>
        <PerfectScrollbar>
          <PortletContent className={classes.portletContent} noPadding>
            {isLoading && (
              <div className={classes.progressWrapper}>
                <CircularProgress />
              </div>
            )}
            {showOrders && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell># Ticket</TableCell>
                    <TableCell align="left">Tema</TableCell>
                    <TableCell
                      align="left"
                      sortDirection={direction}
                      onClick={() =>
                        this.setState({
                          items: orders.sort(
                            this.compareValues(
                              'expireDate',
                              direction == 'desc' ? 'asc' : 'desc'
                            )
                          ),
                          direction: direction == 'desc' ? 'asc' : 'desc'
                        })
                      }
                    >
                      <Tooltip enterDelay={300} title="Ordenar">
                        <TableSortLabel active direction={direction}>
                          Expira
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="left">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map(item => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={item.id}
                      onClick={() => {
                        this.props.history.push('/ticket/' + item.id);
                      }}
                    >
                      <TableCell>{item.id}</TableCell>
                      <TableCell className={classes.customerCell}>
                        {item.subject}
                      </TableCell>
                      <TableCell>
                        <div
                          className={classNames({
                            [classes.red]:
                              moment().diff(moment(item.expireDate), 'days') >=
                              0,
                            [classes.yellow]:
                              moment().diff(moment(item.expireDate), 'days') < 0
                          })}
                        >
                          {moment(item.expireDate).fromNow()}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={classes.statusWrapper}>
                          <Status
                            className={classes.status}
                            color={statusColors[item.status]}
                            size="sm"
                          />
                          {this.getStatus(item.statusId)}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </PortletContent>
        </PerfectScrollbar>
      </Portlet>
    );
  }
}

TicketsToExpire.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(TicketsToExpire);
