import * as yup from 'yup';

import { phoneRegExp } from 'validation';

export const validationSchema = intl => {
  const required = intl.formatMessage({ id: 'field.validation.required' });

  return yup.object().shape({
    userName: yup
      .string()
      .trim()
      .matches(
        /^(?=.{4,25}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/,
        'Formato incorrecto, 4-25 caracteres alfanumericos, guion bajo y punto no permitidos al inicio o fin'
      )
      .required(required),
    roleId: yup
      .string()
      .required(required)
      .nullable(),
    companyLocationId: yup
      .string()
      .required(required)
      .nullable(),
    email: yup
      .string()
      .required(required)
      .email(intl.formatMessage({ id: 'field.validation.email' })),
    displayName: yup.string().required(required),
    mobilePhone: yup.string().matches(phoneRegExp, {
      message: intl.formatMessage({ id: 'field.validation.mobilephone' }),
      excludeEmptyString: true
    }),
    entityTypeId: yup
      .string()
      .required(required)
      .nullable(),
    externalEntityId: yup
      .string()
      .nullable()
  });
};
