import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

import { GeneralInfo } from './../components';

// Material helpers
import {
	withStyles,
	Hidden,
	AppBar,
	Tabs,
	Tab,
	Typography,
	Box,
	IconButton
} from '@material-ui/core';

// Shared components
import {
	Portlet,
	PortletContent
} from 'components';
import { withSnackbar } from 'notistack';


import {
	InfoRounded,
	ArrowBack,
} from '@material-ui/icons';

import styles from './styles';

const tabProps = (index) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			<Box p={3}>{children}</Box>
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

class EditForm extends Component {
	static propTypes = {
		classes: PropTypes.object.isRequired,
        id: PropTypes.number,
        users: PropTypes.array,
		onUpdate: PropTypes.func,
		onCreate: PropTypes.func,
		onBack: PropTypes.func
	};

	static defaultProps = {
		id: null,
		onUpdate: () => { },
		onCreate: () => { },
		onBack: () => { },
	};

	state = {
		tab: 0,
		users: [],
		item: {}
	};

	render() {
		const {
			classes,
			id,
			onBack,
			onCreate,
			onUpdate,
			users,
		} = this.props;

		const {
			tab,
		} = this.state;

		return (
			<Portlet className={classes.root}>
				<PortletContent noPadding>
					{
						onBack &&
						<IconButton
							className={classes.backButton}
							onClick={onBack}
						>
							<ArrowBack />
						</IconButton>
					}
					<AppBar position="static" color="default">
						<Tabs
							value={tab}
							onChange={(e, tab) => {
								this.setState({ tab });
							}}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="auto"
						>
							<Tab
								icon={<InfoRounded />}
								label={<Hidden xsDown>Datos generales</Hidden>}
								{...tabProps(0)}
							/>
						</Tabs>
					</AppBar>
					<TabPanel value={tab} index={0}>
						<GeneralInfo
							id={id}
                            users={users}
                            onCreate={onCreate}
                            onUpdate={onUpdate}
						/>
					</TabPanel>
				</PortletContent>
			</Portlet>
		);
	}
}

export default withStyles(styles)(withSnackbar(EditForm));