import React from 'react';

// Material helpers
import { withStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

// Shared components
import { Table } from 'components';

// Component styles
import styles from './styles';

// import { withSnackbar } from 'notistack';

// import { Loader } from 'components';

import { injectIntl } from 'react-intl';

class SubCategoryTaskTable extends React.Component {
  state = {
    rowsPerPage: 10,
    page: 0,
    itemToDelete: null,
    columns: [],
    actions: []
  };

  componentDidMount() {
    this.setState({ columns: this.getColumns() });
  }

  getColumns = () => {
    const { intl, updateRowData } = this.props;

    return [
      {
        title: intl.formatMessage({
          id: 'catalog.task.table.columns.subcategory'
        }),
        field: 'code'
      },
      {
        title: intl.formatMessage({
          id: 'catalog.task.table.columns.isrequired'
        }),
        field: 'isRequired',
        render: rowData => {
          return (
            <Switch
              checked={rowData.isRequired != null ? rowData.isRequired : true}
              onChange={(e, value) => {
                updateRowData({ ...rowData, isRequired: value });
              }}
            />
          );
        }
      }
    ];
  };

  render() {
    const { items, postSelectedRows } = this.props;
    const { columns } = this.state;

    const options = {
      search: false,
      sorting: false,
      showTitle: false,
      toolbar: false,
      header: true,
      selection: true,
      headerStyle: {
        zIndex: 0
      }
    };

    return (
      <Table
        styles={{ zIndex: 0 }}
        columns={columns}
        items={items}
        options={options}
        onSelectionChange={rows => postSelectedRows(rows)}
      />
    );
  }
}

export default withStyles(styles)(injectIntl(SubCategoryTaskTable));
