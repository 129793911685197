import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
  {
    lookupSettingListRequest: [],
    lookupSettingListSuccess: ['lookupSettings'],
    lookupSettingListError: ['error'],
    setLoading: ['isLoading']
  },
  {}
);

export const LookupSettingTypes = Types;
export const LookupSettingCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
  lookupSettings: [],
  isLoading: false
});

export const onLookupSettingListSuccess = (state, { lookupSettings }) =>
  state.merge({ lookupSettings });
export const onSetLoading = (state, { isLoading }) =>
  state.merge({ isLoading });

//map our actions types to our reducer functions
export const HANDLERS = {
  [LookupSettingTypes.LOOKUP_SETTING_LIST_SUCCESS]: onLookupSettingListSuccess,
  [LookupSettingTypes.SET_LOADING]: onSetLoading
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectSettingList = state =>
  Immutable.asMutable(state.lookupSettingReducer.lookupSettings, {
    deep: true
  });

export const getLoading = state => state.lookupSettingReducer.isLoading;
