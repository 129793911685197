import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
  {
    setLoading: ['isLoading'],
    departmentListRequest: [],
    departmentListSuccess: ['departments'],
    departmentListError: ['error'],
    departmentGetRequest: ['id'],
    departmentGetSuccess: ['department'],
    departmentGetFailure: ['error'],
    departmentCreateRequest: ['department'],
    departmentCreateSuccess: ['department', 'messageOperation'],
    departmentCreateFailure: ['error'],
    departmentUpdateRequest: ['department'],
    departmentUpdateSuccess: ['department', 'messageOperation'],
    departmentUpdateFailure: ['error'],
    departmentDeleteRequest: ['id'],
    departmentDeleteSuccess: ['id', 'messageOperation'],
    departmentDeleteFailure: ['error'],
    departmentSetMessage: ['messageOperation']
  },
  {}
);

export const DepartmentTypes = Types;
export const DepartmentCreators = Creators;

/**
 * Redux
 */
// the initial department of this reducer
export const INITIAL_STATE = Immutable({
  departments: [],
  isLoading: false,
  messageOperation: null
});

export const onDepartmentListSuccess = (state, { departments }) =>
  state.merge({ departments });
export const onDepartmentGetSuccess = (state, { department }) =>
  state.merge({ department: department });
export const onDepartmentCreateSuccess = (
  state,
  { department, messageOperation }
) =>
  state.merge({
    departments: state.departments.concat(department),
    messageOperation,
    department: department
  });
export const onDepartmentUpdateSuccess = (
  state,
  { department, messageOperation }
) =>
  state.merge({
    departments: state.departments.map(i =>
      i.id === department.id ? department : i
    ),
    department: department,
    messageOperation
  });
export const onDepartmentDeleteSuccess = (state, { id, messageOperation }) => {
  let item = state.departments.find(i => i.id == id);
  return state.merge({
    departments: state.departments.map(i =>
      i.id == item.id ? { ...item, isActive: false } : i
    ),
    messageOperation
  });
};
export const onSetLoading = (state, { isLoading }) =>
  state.merge({ isLoading });
export const onDepartmentSetMessage = (state, { messageOperation }) =>
  state.merge({ messageOperation });

//map our actions types to our reducer functions
export const HANDLERS = {
  [DepartmentTypes.DEPARTMENT_LIST_SUCCESS]: onDepartmentListSuccess,
  [DepartmentTypes.DEPARTMENT_GET_SUCCESS]: onDepartmentGetSuccess,
  [DepartmentTypes.DEPARTMENT_CREATE_SUCCESS]: onDepartmentCreateSuccess,
  [DepartmentTypes.DEPARTMENT_UPDATE_SUCCESS]: onDepartmentUpdateSuccess,
  [DepartmentTypes.DEPARTMENT_DELETE_SUCCESS]: onDepartmentDeleteSuccess,
  [DepartmentTypes.SET_LOADING]: onSetLoading,
  [DepartmentTypes.DEPARTMENT_SET_MESSAGE]: onDepartmentSetMessage
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectDepartments = state =>
  Immutable.asMutable(state.departmentReducer.departments, { deep: true });
export const selectDepartment = state => state.departmentReducer.department;
export const selectDepartmentsOptions = state =>
  Immutable.asMutable(
    state.departmentReducer.departments.filter(x => x.isActive),
    {
      deep: true
    }
  );
export const selectIsLoading = state => state.departmentReducer.isLoading;
export const selectMessageOperation = state =>
  state.departmentReducer.messageOperation;
