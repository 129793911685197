import React from 'react';
import {
	VictoryChart,
	VictoryAxis,
	VictoryStack,
	VictoryBar,
	VictoryTooltip,
	VictoryLegend
} from 'victory';
import { getMonthName } from '../../../../utils';

const ChartWorkItemMonthly = ({ data }) => {
	const months = data.map(x => {
		return `${getMonthName(x.month)}/${x.year.toString().substring(2)}`;
	});

	const mappedDataSet = data
		? data.map(item => (
			<VictoryStack
				colorScale={['tomato', 'orange', 'gold']}
				style={{ data: { width: 20 } }}
			>
				<VictoryBar
					data={[{ x: item.rowIndex, y: item.totalInSLA }]}
					labels={({ datum }) => `Tickets dentro de SLA: ${datum.y}`}
					labelComponent={<VictoryTooltip />}
				/>
				<VictoryBar
					data={[{ x: item.rowIndex, y: item.totalTicketClosed }]}
					labels={({ datum }) => `Tickets cerrados: ${datum.y}`}
					labelComponent={<VictoryTooltip />}
				/>
				<VictoryBar
					data={[{ x: item.rowIndex, y: item.totalTicketMonth }]}
					labels={({ datum }) => `Tickets Creados: ${datum.y}`}
					labelComponent={<VictoryTooltip />}
				/>
			</VictoryStack>
		))
		: null;

	return (
		<div styles={{ width: 400, height: 400 }}>
			<VictoryChart domainPadding={{ x: 30, y: 20 }}>
				{mappedDataSet}
				<VictoryAxis
					label="Mes"
					tickFormat={months}
					fixLabelOverlap
					style={{
						axisLabel: { padding: 30 }
					}}
				/>
				<VictoryAxis
					dependentAxis
					crossAxis
					label={`Tickets`}
					style={{
						axisLabel: { padding: 38 }
					}}
				/>
			</VictoryChart>
			<VictoryLegend
				x={125}
				y={0}
				title="Tickets"
				centerTitle
				orientation="horizontal"
				gutter={20}
				style={{ border: { stroke: 'black' }, title: { fontSize: 12 } }}
				data={[
					{ name: 'En SLA', symbol: { fill: 'tomato' } },
					{ name: 'Cerrados', symbol: { fill: 'orange' } },
					{ name: 'Total', symbol: { fill: 'gold' } }
				]}
				height={60}
			/>
		</div>
	);
};

export default ChartWorkItemMonthly;
