import API from './Api';

class TicketsService extends API {
	constructor(controller) {
		super(controller);
	}

	getReceived = () => {
		return this.API.get(`${this.controller}/received`);
	};

	getSubscribedTickets = () => {
		return this.API.get(`${this.controller}/subscribed`);
	};

	getsimpleTickets = () => {
		return this.API.get(`${this.controller}/relations`);
	};

	getSent = () => {
		return this.API.get(`${this.controller}/sent`);
	};

	getSummary = () => {
		return this.API.get(`${this.controller}/summary`);
	};

	getToExpire = () => {
		return this.API.get(`${this.controller}/to-expire`);
	}

	getTicketHistory = (id) => {
		return this.API.get(`${this.controller}/${id}/history`);
	}

	follow = id => {
		return this.API.post(`${this.controller}/${id}/follow`);
	};

	search = searchModel => {
		return this.API.post(`${this.controller}/search`, searchModel);
	};

	getAssignedReport = model => {
		return this.API.get(`${this.controller}/assigned-report?start=${model.startDate.toJSON()}&end=${model.endDate.toJSON()}`);
	};

	getQRCode = (id, data) => {
		return this.API.post(`${this.controller}/${id}/qr`, data);
	};
}

export default new TicketsService('workitems');
