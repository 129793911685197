export default theme => {
	return {
		root: {
			padding: theme.spacing.unit * 3,
			cursor: 'pointer'
		},
		content: {
			alignItems: 'center',
			display: 'flex'
		},
		title: {
			color: theme.palette.text.secondary,
			fontWeight: 700
		},
		value: {
			marginTop: theme.spacing.unit
		},
		iconWrapper: {
			alignItems: 'center',
			backgroundColor: theme.palette.error.main,
			borderRadius: '50%',
			display: 'inline-flex',
			height: '4rem',
			justifyContent: 'center',
			marginLeft: 'auto',
			width: '4rem'
		},
		icon: {
			color: theme.palette.common.white,
			fontSize: '2rem',
			height: '2rem',
			width: '2rem'
		},
		footer: {
			marginTop: theme.spacing.unit * 2,
			display: 'flex',
			alignItems: 'center'
		},
		difference: {
			alignItems: 'center',
			color: theme.palette.error.dark,
			display: 'inline-flex',
			fontWeight: 700
		},
		caption: {
			marginLeft: theme.spacing.unit
		}
	};
};
