import { takeLatest, call, put } from 'redux-saga/effects';
import { DepartmentTypes, DepartmentCreators } from '../redux/DepartmentRedux';
import departmentService from 'services/departmentService';
import Department from 'contract/responses/Department';
import { MessageOperation } from '../constants';
import { getAll as getUsers } from '../sagas/userSagas';

export function* getCatalogs() {
  yield put(DepartmentCreators.setLoading(true));
  yield* getUsers(false);
  yield* getAll(false);
  yield put(DepartmentCreators.setLoading(false));
}

export function* getAll(displayMessage = true) {
  if (displayMessage) yield put(DepartmentCreators.setLoading(true));

  const response = yield call(departmentService.getAll);

  if (response.ok) {
    yield put(
      DepartmentCreators.departmentListSuccess(
        response.data.data.map(item => new Department(item))
      )
    );
  } else {
    yield put(DepartmentCreators.departmentListFailure(MessageOperation.error));
  }

  if (displayMessage) yield put(DepartmentCreators.setLoading(false));
}

export function* getById({ id }) {
  yield put(DepartmentCreators.setLoading(true));

  const response = yield call(departmentService.getById, id);
  if (response.ok) {
    let item = new Department(response.data.data);
    yield put(DepartmentCreators.departmentGetSuccess(item));
  } else {
    yield put(DepartmentCreators.departmentSetMessage(MessageOperation.error));
  }

  yield put(DepartmentCreators.setLoading(false));
}

export function* create({ department }) {
  yield put(DepartmentCreators.setLoading(true));
  const response = yield call(departmentService.create, department);
  if (response.ok) {
    let newTaskType = new Department(response.data.data);
    yield put(
      DepartmentCreators.departmentCreateSuccess(
        newTaskType,
        MessageOperation.success
      )
    );
    yield* getById({ id: newTaskType.id });
  } else {
    yield put(DepartmentCreators.departmentSetMessage(MessageOperation.error));
  }

  yield put(DepartmentCreators.setLoading(false));
}

export function* update({ department }) {
  yield put(DepartmentCreators.setLoading(true));

  const response = yield call(departmentService.update, department);

  if (response.ok) {
    yield put(
      DepartmentCreators.departmentUpdateSuccess(
        department,
        MessageOperation.success
      )
    );
    yield* getById({ id: department.id });
  } else {
    yield put(DepartmentCreators.departmentSetMessage(MessageOperation.error));
  }

  yield put(DepartmentCreators.setLoading(false));
}

export function* deleteItem({ id }) {
  yield put(DepartmentCreators.setLoading(true));

  const response = yield call(departmentService.delete, id);

  if (response.ok) {
    yield put(
      DepartmentCreators.departmentDeleteSuccess(id, MessageOperation.success)
    );
  } else {
    yield put(DepartmentCreators.departmentSetMessage(MessageOperation.error));
  }

  yield put(DepartmentCreators.setLoading(false));
}

/**
 * sagas
 */
function* departmentSagas() {
  yield takeLatest(DepartmentTypes.DEPARTMENT_LIST_REQUEST, getCatalogs);
  yield takeLatest(DepartmentTypes.DEPARTMENT_GET_REQUEST, getById);
  yield takeLatest(DepartmentTypes.DEPARTMENT_CREATE_REQUEST, create);
  yield takeLatest(DepartmentTypes.DEPARTMENT_UPDATE_REQUEST, update);
  yield takeLatest(DepartmentTypes.DEPARTMENT_DELETE_REQUEST, deleteItem);
}

export default departmentSagas;
