import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import {
	withStyles,
	Hidden,
	AppBar,
	Tabs,
	Tab,
	Typography,
	Box
} from '@material-ui/core';

// Shared components
import { Portlet, PortletContent, ButtonToolTip } from 'components';

import { GeneralInfo } from './../../components';

import { InfoRounded, ArrowBack } from '@material-ui/icons';

import { injectIntl } from 'react-intl';

import styles from './styles';

const tabProps = index => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			<Box p={3}>{children}</Box>
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

class EditForm extends Component {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		id: PropTypes.number,
		onUpdate: PropTypes.func,
		onBack: PropTypes.func
	};

	static defaultProps = {
		id: null,
		onUpdate: () => { },
		onBack: () => { }
	};

	state = {
		id: this.props.id,
		tab: 0
	};

	shouldComponentUpdate(nextProps) {
		if (nextProps.category !== this.props.category) {
			this.setState({
				category: nextProps.category,
				id: nextProps.category.id
			});
		}
		return true;
	}

	render() {
		const { classes, onBack, intl, id } = this.props;

		const {
			//id
			tab
		} = this.state;

		return (
			<Portlet className={classes.root}>
				<PortletContent noPadding>
					{onBack && (
						<ButtonToolTip
							className={classes.backButton}
							onClick={onBack}
							title={intl.formatMessage({ id: 'button.label.back' })}
						>
							<ArrowBack />
						</ButtonToolTip>
					)}
					<AppBar position="static" color="default">
						<Tabs
							value={tab}
							onChange={(e, tab) => {
								this.setState({ tab });
							}}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="auto"
						>
							<Tab
								icon={<InfoRounded />}
								label={
									<Hidden xsDown>
										{intl.formatMessage({ id: 'catalog.tab.generalinfo' })}
									</Hidden>
								}
								{...tabProps(0)}
							/>
						</Tabs>
					</AppBar>
					<TabPanel value={tab} index={0}>
						<GeneralInfo id={id} />
					</TabPanel>
				</PortletContent>
			</Portlet>
		);
	}
}

export default withStyles(styles)(injectIntl(EditForm));
