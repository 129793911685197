import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  TaskCreators,
  selectTask,
  selectIsLoading,
  selecttaskSubCategories
} from 'redux/TaskRedux';
import { selectTaskTypes } from 'redux/TaskTypeRedux';
import { selectCategories } from 'redux/CategoryRedux';

export const mapStateToProps = state => ({
  isLoading: selectIsLoading(state),
  task: selectTask(state),
  taskTypes: selectTaskTypes(state),
  categories: selectCategories(state),
  subCategories: selecttaskSubCategories(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTask: TaskCreators.taskGetByTaskTypeIdRequest,
      saveMultiple: TaskCreators.taskSaveMultipleRequest,
      getByCategoryId: TaskCreators.taskGetByCategoryIdRequest
    },
    dispatch
  );

export const hoc = WrappedComponent => {
  const hocComponent = class HocComponent extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  hocComponent.propTypes = {
    isLoading: PropTypes.bool,
    taskType: PropTypes.object,
    getTask: PropTypes.func,
    createTask: PropTypes.func,
    updateTask: PropTypes.func
  };

  return hocComponent;
};

export default WrapperComponent =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hoc(WrapperComponent));
