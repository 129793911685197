import { useState, useEffect } from "react";
import pushNotificationsService from "../services/pushNotificationsService";

import {
    isPushNotificationSupported,
    askUserPermission,
    registerServiceWorker,
    createNotificationSubscription,
    deleteNotificationSubscription,
    getUserSubscription
} from "../push-notifications";

const pushNotificationSupported = isPushNotificationSupported();

export default function usePushNotifications() {
    const [userConsent, setSuserConsent] = useState(Notification.permission);
    const [userSubscription, setUserSubscription] = useState(null);
    const [pushServerSubscriptionId, setPushServerSubscriptionId] = useState();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (pushNotificationSupported) {
            setLoading(true);
            setError(false);
            registerServiceWorker().then(() => {
                setLoading(false);
            });
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        setError(false);
        const getExixtingSubscription = async () => {
            const existingSubscription = await getUserSubscription();
            setUserSubscription(existingSubscription);
            setLoading(false);
        };
        getExixtingSubscription();
    }, []);

	/**
	 * define a click handler that asks the user permission,
	 * it uses the setSuserConsent state, to set the consent of the user
	 * If the user denies the consent, an error is created with the setError hook
	 */
    const promptUserPermission = () => {
        setLoading(true);
        setError(false);
        askUserPermission().then(consent => {
            setSuserConsent(consent);
            if (consent !== "granted") {
                setError({
                    name: "Consent denied",
                    message: "You denied the consent to receive notifications",
                    code: 0
                });
            }
            setLoading(false);
        });
    };

	/**
	 * define a click handler that creates a push notification subscription.
	 * Once the subscription is created, it uses the setUserSubscription hook
	 */
    const subscribeToPushNotification = () => {
        setLoading(true);
        setError(false);

        if (userSubscription != null) {
            return;
        }

        createNotificationSubscription()
            .then(function (subscription) {
                setUserSubscription(subscription);
                sendSubscriptionToPushServer(subscription);
                setLoading(false);
            })
            .catch(err => {
                setError(err);
                setLoading(false);
            });
    };

    /**
	 * define a method that delete a push notification subscription.
	 * Once the subscription is deleted, it send unsucrcriptionToServer
	 */
    const unsubscribePushNotification = () => {
        setLoading(true);
        setError(false);

        if (userSubscription == null) {
            return;
        }

        deleteNotificationSubscription()
            .then(function (success) {
                if (!success) {
                    return;
                }

                setUserSubscription(null);
                setPushServerSubscriptionId(null);
                sendUnsuscriptionToPushServer();
                setLoading(false);
            })
            .catch(err => {
                setError(err);
                setLoading(false);
            });
    };

	/**
	 * define a click handler that sends the push susbcribtion to the push server.
	 * Once the subscription ics created on the server, it saves the id using the hook setPushServerSubscriptionId
	 */
    const sendSubscriptionToPushServer = (subscription) => {
        setLoading(true);
        setError(false);
        pushNotificationsService.postSubscription(subscription)
            .then(function (response) {
                if (response.status == 200 && response.data.status == "Success" && response.data.data) {
                    setPushServerSubscriptionId(response.data.data.id);
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setError(err);
            });
    };

    /**
	 * define a click handler that sends the push susbcribtion to the push server.
	 * Once the subscription ics created on the server, it saves the id using the hook setPushServerSubscriptionId
	 */
    const sendUnsuscriptionToPushServer = () => {
        setLoading(true);
        setError(false);

        if (!pushServerSubscriptionId) {
            setLoading(true);
            setError(false);
            return;
        }
        
        pushNotificationsService.deleteSubscription(pushServerSubscriptionId)
            .then(function () {
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setError(err);
            });
    };

	/**
	 * define a click handler that requests the push server to send a notification, passing the id of the saved subscription
	 */
    const onClickSendNotification = async () => {
        setLoading(true);
        setError(false);
        // await http.get(`/subscription/${pushServerSubscriptionId}`).catch(err => {
        // 	setLoading(false);
        // 	setError(err);
        // });
        setLoading(false);
    };

	/**
	 * returns all the stuff needed by a Component
	 */
    return {
        promptUserPermission,
        subscribeToPushNotification,
        unsubscribePushNotification,
        sendSubscriptionToPushServer,
        pushServerSubscriptionId,
        onClickSendNotification,
        userConsent,
        pushNotificationSupported,
        userSubscription,
        error,
        loading
    };
}