import API from "./Api";

class SeveritiesService extends API {
    
    constructor(controller){
        super(controller);
    }

}

export default new SeveritiesService("severities");