import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import {
  withStyles,
  Hidden,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Paper
} from '@material-ui/core';

// Shared components
import { ButtonToolTip } from 'components';
import { withSnackbar } from 'notistack';
import {
  GeneralInfo,
  SubCategoryLocationComponent,
  SubCategoryPriority
} from '../components';

import {
  InfoRounded,
  ArrowBack,
  ListRounded,
  NotificationImportantRounded
} from '@material-ui/icons';

import { injectIntl } from 'react-intl';

import styles from './styles';

const tabProps = index => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

class EditForm extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    id: PropTypes.number,
    onUpdate: PropTypes.func,
    onCreate: PropTypes.func,
    onBack: PropTypes.func
  };

  static defaultProps = {
    id: null,
    onUpdate: () => {},
    onCreate: () => {},
    onBack: () => {}
  };

  state = {
    tab: 0,
    users: [],
    item: {}
  };

  render() {
    const { classes, onBack, categoryId, intl, id } = this.props;

    const { tab } = this.state;

    return (
      <Paper className={classes.root}>
        {onBack && (
          <ButtonToolTip
            className={classes.backButton}
            onClick={onBack}
            title={intl.formatMessage({ id: 'button.label.back' })}
          >
            <ArrowBack />
          </ButtonToolTip>
        )}
        <AppBar position="static" color="default">
          <Tabs
            value={tab}
            onChange={(e, tab) => {
              this.setState({ tab });
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              icon={<InfoRounded />}
              label={<Hidden xsDown>Datos generales</Hidden>}
              {...tabProps(0)}
            />
            <Tab
              icon={<ListRounded />}
              label={<Hidden xsDown>Sucursales</Hidden>}
              disabled={!id}
              {...tabProps(1)}
            />
            <Tab
              icon={<NotificationImportantRounded />}
              label={<Hidden xsDown>SLA</Hidden>}
              disabled={!id}
              {...tabProps(2)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={tab} index={0}>
          {tab === 0 && <GeneralInfo id={id} categoryId={categoryId} />}
        </TabPanel>
        <TabPanel value={tab} index={1}>
          {tab === 1 && <SubCategoryLocationComponent subCategoryId={id} />}
        </TabPanel>
        <TabPanel value={tab} index={2}>
          {tab === 2 && <SubCategoryPriority subCategoryId={id} />}
        </TabPanel>
      </Paper>
    );
  }
}

export default withStyles(styles)(withSnackbar(injectIntl(EditForm)));
