import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import PropTypes from 'prop-types';
import UploadAdapter from 'common/uploadAdapter';
import '@ckeditor/ckeditor5-build-inline/build/translations/es';
import React, { PureComponent } from 'react';

export default class RichEditor extends PureComponent {
    static propTypes = {
        type: PropTypes.string,
        lang: PropTypes.string,
        value: PropTypes.string,
        placeholder: PropTypes.string,
        onInit: PropTypes.func,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        minHeight: PropTypes.number
    };

    static defaultProps = {
        lang: "es",
        type: "inline",
        placeholder: "Ingresar texto"
    };

    render() {


        ClassicEditor.defaultConfig.language = this.props.lang;
        ClassicEditor.defaultConfig.placeholder = this.props.placeholder;

        InlineEditor.defaultConfig.language = this.props.lang;
        InlineEditor.defaultConfig.placeholder = this.props.placeholder;

        InlineEditor.defaultConfig.minHeight = this.props.minHeight;

        return (
            <CKEditor
                className={this.props.className ? this.props.className : "default-editor"}
                editor={this.props.type == "inline" ? InlineEditor : ClassicEditor}
                data={this.props.value}
                onInit={editor => {
                    this.props.onInit && this.props.onInit(editor);
                    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => new UploadAdapter(loader);
                }}
                onChange={(event, editor) => this.props.onChange && this.props.onChange(editor.getData())}
                onBlur={() => this.props.onBlur && this.props.onBlur()}
            />
        );
    }
}