import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Loader } from 'components';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { RolesTable } from './components';

import WithRoles from "./components/hoc/WithRoles";

import { injectIntl } from "react-intl";

import { withSnackbar } from 'notistack';

// Component styles
import styles from './style';

class Roles extends Component {

	componentDidMount() {
		const { getRoles } = this.props;
		getRoles();
	}

	shouldComponentUpdate(nextProps) {
		const { intl, enqueueSnackbar, onCleanMessage } = this.props;
		if (nextProps.messageOperation && nextProps.messageOperation !== this.props.messageOperation) {
			enqueueSnackbar(intl.formatMessage({ id:nextProps.messageOperation.message }), { variant: nextProps.messageOperation.type });
			onCleanMessage();
		}

		return true;
	}
	
	render() {
		const { classes, roles, isLoading, createRole, updateRole, deleteRole } = this.props;

		return (
			<DashboardLayout title="Roles">
				<div className={classes.root}>
					<div className={classes.content}>
						{
							isLoading
								? <Loader />
								:
								(
									<RolesTable
										items={roles}
										onCreate={createRole}
										onUpdate={updateRole}
										onDelete={deleteRole}
									/>
								)
						}
					</div>
				</div>
			</DashboardLayout>
		);
	}
}

Roles.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withSnackbar(injectIntl(WithRoles(Roles))));
