import * as React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const MultiSelect = ({ options, value, onChange, placeholder, noOptionsMessage, ...props }) => {
	const getIdsFromObjects = (items) => {
		return items.map(i => i.value);
	};

	const getObjectsFromIds = (items) => {
		return options.filter(x => items.includes(x.value));
	};

	options = options || [];

	props = {
        ...props,
        noOptionsMessage: noOptionsMessage ? noOptionsMessage : () => "No hay opciones",
		placeholder: (placeholder || "Seleccionar"),
		onChange: selected => onChange(getIdsFromObjects(selected || [])),
		value: getObjectsFromIds(value),
		isMulti: true,
		options
	};

	return <Select {...props} />;
};

MultiSelect.propTypes = {
	options: PropTypes.array,
	value: PropTypes.array,
	onChange: PropTypes.func,
	placeholder: PropTypes.string
};

export default MultiSelect;