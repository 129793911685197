import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// I18n Helper
import { injectIntl } from 'react-intl';

// Shared components
import {
  Portlet,
  PortletContent,
  Table,
  ConfirmDialog,
  Loader
} from 'components';

// Component styles
import styles from './styles';

class CategoriesTable extends Component {
  state = {
    rowsPerPage: 15,
    page: 0,
    itemToDelete: null,
    users: [],
    suggestions: [],
    columns: [],
    actions: []
  };

  componentDidMount() {
    this.setState({ columns: this.getColumns(), actions: this.getActions() });
  }

  getColumns = () => {
    const { intl } = this.props;

    return [
      {
        title: intl.formatMessage({
          id: 'catalog.categories.table.columns.name'
        }),
        field: 'code'
      },
      {
        title: intl.formatMessage({
          id: 'catalog.categories.table.columns.detail'
        }),
        field: 'detail'
      },
      {
        title: intl.formatMessage({
          id: 'catalog.categories.table.columns.owner'
        }),
        field: 'ownerName'
      },
      {
        title: intl.formatMessage({
          id: 'catalog.categories.table.columns.deparment'
        }),
        field: 'deparmentName'
      },
      {
        title: intl.formatMessage({
          id: 'catalog.categories.table.columns.isactive'
        }),
        field: 'isActive',
        render: rowData => (
          <span>
            {rowData.isActive
              ? intl.formatMessage({ id: 'item.status.active' })
              : intl.formatMessage({ id: 'item.status.inactive' })}
          </span>
        )
      }
    ];
  };

  getActions = () => {
    const { intl } = this.props;

    return [
      {
        icon: 'edit',
        tooltip: intl.formatMessage({ id: 'catalog.table.action.edit' }),
        onClick: (e, rowData) => this.props.onEditClick(rowData.id)
      },
      {
        icon: 'delete',
        tooltip: intl.formatMessage({ id: 'catalog.table.action.delete' }),
        onClick: (e, rowData) => {
          this.setState({ itemToDelete: rowData });
        }
      },
      {
        icon: 'add',
        isFreeAction: true,
        tooltip: intl.formatMessage({ id: 'catalog.table.action.create' }),
        onClick: this.props.onAddClick
      }
    ];
  };

  delete = () => {
    const { itemToDelete } = this.state;

    this.props.onDelete(itemToDelete.id);
    this.setState({ itemToDelete: null });
  };

  render() {
    const { classes, className, items, isLoading, intl } = this.props;
    const { columns, actions, itemToDelete } = this.state;
    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          {itemToDelete && (
            <ConfirmDialog
              text={intl.formatMessage(
                { id: 'action.delete.warning' },
                { itemName: `${itemToDelete.code}` }
              )}
              open
              onClose={() => this.setState({ itemToDelete: null })}
              onConfirm={this.delete}
            />
          )}
          {isLoading ? (
            <Loader />
          ) : (
            <Table columns={columns} items={items} actions={actions} />
          )}
        </PortletContent>
      </Portlet>
    );
  }
}

CategoriesTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onEditClick: PropTypes.func,
  onDelete: PropTypes.func,
  items: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired
};

CategoriesTable.defaultProps = {
  items: [],
  users: [],
  onEditClick: () => {},
  onAddClick: () => {}
};

export default withStyles(styles)(injectIntl(CategoriesTable));
