import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
//import Yup from 'yup';
import Moment from 'moment';

// UI Components
import {
	withStyles,
	Grid,
	CircularProgress,
	Radio,
	RadioGroup,
	FormControl,
	FormControlLabel,
	FormLabel,
	Paper,
	//Typography
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table, ButtonToolTip, Select, SaveContainer } from 'components';

//Services
import { WithTicket, WithSession, WithNotifications } from "hoc";
import rolesService from "services/rolesService";
import businessFunctionsService from "services/businessFunctionsService";
import businessFunctionRolesService from "services/businessFunctionRolesService";

//Assets
import {
	ArrowBackIosOutlined,
	ArrowForwardIosOutlined,
	ChevronLeftOutlined,
	ChevronRightOutlined,
	SaveOutlined
} from '@material-ui/icons';
import styles from './styles';

class Permissions extends Component {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		id: PropTypes.number,
		onBack: PropTypes.func
	};

	static defaultProps = {
		id: null,
		onBack: () => { },
	};

	state = {
		filtersPanelExpanded: true,
		tablePanelExpanded: true,
		date: new Date(),
		roles: [],
		businessFunctions: [],
		assigned: [],
		radioSelected: "role"
	};

	selectStatus = [
		{ value: 1, label: "Inicial" },
		{ value: 2, label: "En progreso" },
		{ value: 3, label: "Finalizado" }
	]

	emptyFilters = {
		startDate: Moment().startOf('month'),
		endDate: Moment().endOf('day'),
		workItemId: "",
		categoryId: "",
		subCategoryId: "",
		locationId: "",
		subject: "",
		assignedTo: "",
		createdBy: "",
		statusId: "",
		severityId: ""
	}

	statuses = [
		{ value: 1, label: "Inicial" },
		{ value: 2, label: "En progreso" },
		{ value: 3, label: "Finalizado" }
	]

	componentDidMount = () => {
		rolesService.getAllSimple().then((response) => {
			this.setState({ roles: response.data.data });
		});

		businessFunctionsService.getAllSimple().then((response) => {
			this.setState({ businessFunctions: response.data.data });
		});
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (prevState.selected != this.state.selected) {
			this.setState({ assignedSelected: [], unassignedSelected: [], assigned: [] });
			this.getAssigned();
		}

		if (prevState.radioSelected != this.state.radioSelected) {
			this.setState({ assignedSelected: [], unassignedSelected: [], assigned: [], selected: null });
		}
	}

	save = () => {
		const {
			selected,
			assigned,
			radioSelected
		} = this.state;

		if(!selected)
		return;

		const model = {
			id: selected.value,
			assignedIds: assigned.map(x => x.id),
			type: radioSelected
		};

		businessFunctionRolesService.postBusinessFunctionRoles(model).then(response => {
			if (response.data.data) {
				this.props.addNotification({ message: "Permisos guardados", options: { variant: "success" } });
			}
		});
	}

	getAssigned = () => {
		const {
			radioSelected,
			selected
		} = this.state;

		if (!selected) {
			this.setState({ assigned: [] });
			return;
		}

		if (radioSelected == 'role') {
			businessFunctionRolesService.getAssignedBusinessFunctions(selected.value).then((response) => {
				this.setState({ assigned: response.data.data });
			});
		}
		else {
			businessFunctionRolesService.getAssignedRoles(selected.value).then((response) => {
				this.setState({ assigned: response.data.data });
			});
		}
	}

	renderView = () => {
		const {
			radioSelected,
			roles,
			businessFunctions,
			selected
		} = this.state;

		const {
			classes
		} = this.props;

		return (
			<SaveContainer saveContent={this.save}>
			<Grid content spacing={2} className={classes.root}>
				<Grid container justify="center" spacing={2}>
					<Grid item xs={12}>
						<Paper style={{ padding: '12px 10px' }}>
							<Grid container>
								<Grid item xs={12} md={5}>
									<FormControl component="fieldset" className={classes.formControl}>
										<FormLabel component="legend">Administrar</FormLabel>
										<RadioGroup row aria-label="type" name="type" value={radioSelected} onChange={e => this.setState({ radioSelected: e.target.value })}>
											<FormControlLabel value="role" control={<Radio />} label="Rol" />
											<FormControlLabel value="businessFunction" control={<Radio />} label="Permiso" />
										</RadioGroup>
									</FormControl>
								</Grid>
								<Grid item xs={18} md={5}>
									<FormControl component="fieldset" className={classes.formControl}>
										<FormLabel component="legend">Seleccionar {radioSelected == 'role' ? "rol" : "permiso"}</FormLabel>
										<Select
											key={radioSelected}
											value={selected}
											onChange={(selected) => this.setState({ selected })}
											options={radioSelected == "role" ? roles : businessFunctions}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={4} md={2}>
									<ButtonToolTip
										title={"Guardar"}
										onClick={this.save}
									>
										<SaveOutlined />
									</ButtonToolTip>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container justify="center" spacing={2} style={{ marginTop: '10px', marginBottom: '10px' }}>
						<Grid item xs={12} md={6}>
							<Paper>
								{this.renderTable('unassigned')}
							</Paper>
						</Grid>
						<Grid item xs={12} md={2} style={{ display: 'none' }}>
							<Grid
								container
								justify="center"
								direction="column"
								alignItems="center"
								alignContent="center"
								style={{ height: '100%' }}
							>
								<ButtonToolTip
									title={"Agregar seleccionados"}
								>
									<ChevronRightOutlined />
								</ButtonToolTip>
								<ButtonToolTip
									title={"Agregar todos"}
								>
									<ArrowForwardIosOutlined />
								</ButtonToolTip>
								<ButtonToolTip
									title={"Remover seleccionados"}
								>
									<ChevronLeftOutlined />
								</ButtonToolTip>
								<ButtonToolTip
									title={"Remover todos"}
								>
									<ArrowBackIosOutlined />
								</ButtonToolTip>
							</Grid>
						</Grid>
						<Grid item xs={12} md={6}>
							<Paper>
								{this.renderTable('assigned')}
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			</SaveContainer>
		);
	}

	addItems = () => {
		const {
			assigned,
			unassignedSelected
		} = this.state;

		let newAssigned = [...assigned];

		unassignedSelected.forEach(i => {
			newAssigned.push({ id: i.value, code: i.label });
		});

		this.setState({ assigned: newAssigned, unassignedSelected: [] });
	}

	removeItems = () => {
		const {
			assigned,
			assignedSelected
		} = this.state;

		const selectededIds = assignedSelected.map(i => i.value);
		let newAssigned = assigned.filter(x => !selectededIds.includes(x.id));
		this.setState({ assigned: newAssigned, assignedSelected: [] });
	}

	renderTable = (type) => {
		const {
			roles,
			businessFunctions,
			loading,
			radioSelected,
			assigned,
			unassignedSelected,
			assignedSelected,
			selected
		} = this.state;

		let items = [];
		let title;

		if (type == 'unassigned') {
			if (radioSelected == 'role') {
				items = businessFunctions.filter(x => !assigned.map(a => a.id).includes(x.value));
				title = 'Permisos sin asignar';
			}
			else {
				items = roles.filter(x => !assigned.map(a => a.id).includes(x.value));
				title = 'Roles sin asignar';
			}
		}
		else {
			if (radioSelected == 'role') {
				items = businessFunctions.filter(x => assigned.map(a => a.id).includes(x.value));
				title = 'Permisos asignados';
			}
			else {
				items = roles.filter(x => assigned.map(a => a.id).includes(x.value));
				title = 'Roles asignados';
			}
		}

		return (
			<React.Fragment>
				<Grid
					container
					spacing={3}
					direction="row"
					justify="center"
					alignItems="center"
					style={{ paddingLeft: '15px', paddingRight: "15px" }}
				>
					{
						loading
							? <CircularProgress />
							: (
								<Table
									columns={[{ title, field: 'label' }]}
									items={selected ? items.filter(x => x.isActive) : []}
									actions={[
										{
											icon: type == 'unassigned' ? 'add_circle_outline' : 'delete_outline',
											tooltip: type == 'unassigned' ? 'Agregar seleccionados' : 'Quitar seleccionados',
											onClick: () => {
												if (type == 'unassigned') {
													this.addItems();
												}
												else {
													this.removeItems();
												}
											}
										}
									]}
									options={{
										pageSize: 10,
										search: false,
										selection: true,
										toolbar: type == 'unassigned' ? unassignedSelected && unassignedSelected.length > 0 : assignedSelected && assignedSelected.length > 0
									}}
									onSelectionChange={(selectedItems) => {
										if (type == 'unassigned') {
											this.setState({ unassignedSelected: selectedItems });
										}
										else {
											this.setState({ assignedSelected: selectedItems });
										}
									}}
								/>
							)
					}
				</Grid>
			</React.Fragment>
		);
	}

	render() {
		const {
			classes,
		} = this.props;

		// const {
		// tablePanelExpanded
		// } = this.state;

		return (
			<DashboardLayout title="Permisos">
				<div className={classes.root}>
					<div className={classes.content}>
						<Portlet className={classes.root}>
							<PortletContent noPadding>
								{this.renderView()}
							</PortletContent>
						</Portlet>
					</div>
				</div>
			</DashboardLayout>
		);
	}
}

export default withRouter(WithSession(WithNotifications(WithTicket(withStyles(styles)(Permissions)))));