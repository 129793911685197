import { takeLatest, call, put } from 'redux-saga/effects';
import {
  ExternalEntityTypes,
  ExternalEntityCreators
} from 'redux/ExternalEntityRedux';
import externalEntityService from 'services/externalEntityService';
import { MessageOperation } from '../constants';

export function* getAll(showLoading) {
  if (showLoading) yield put(ExternalEntityCreators.setLoading(true));

  const response = yield call(externalEntityService.getAll);

  if (response.ok) {
    yield put(
      ExternalEntityCreators.externalEntityGetListSuccess(response.data.data)
    );
  } else {
    yield put(
      ExternalEntityCreators.externalEntityGetListFailure(
        MessageOperation.error
      )
    );
  }

  if (showLoading) yield put(ExternalEntityCreators.setLoading(false));
}

/**
 * sagas
 */
function* externalEntitySagas() {
  yield takeLatest(
    ExternalEntityTypes.EXTERNAL_ENTITY_GET_LIST_REQUEST,
    getAll
  );
}

export default externalEntitySagas;
