import React, { Component } from 'react';
import classNames from "classnames";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { withSnackbar } from 'notistack';
import { Table } from './components';
import EditForm from "./EditForm/index.jsx";
import styles from './style';
import userGroupService from "services/userGroupService";
import userService from "services/userService";

class UserGroups extends Component {
    static propTypes = {
        className: PropTypes.string,
        classes: PropTypes.object.isRequired
    };

    state = {
        isLoading: false,
        items: [],
        users: [],
        error: null,
        showDetailOf: null,
        addItem: false
    };

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        this.setState({ isLoading: true });
        const response = await userGroupService.getAll().catch(() => this.setState({ isLoading: false }));

        if (!response.data || response.data.status == "Fail" || !Array.isArray(response.data.data)) {
            this.props.history.push("/");
            return;
        }

        this.setState({ items: response.data.data });

        const usersResponse = await userService.getAll().catch(() => this.setState({ isLoading: false }));
        if (!usersResponse.data || usersResponse.data.status == "Fail" || !Array.isArray(usersResponse.data.data))
            return;
        this.setState({ users: usersResponse.data.data, isLoading: false });
    }

    deleteGroup = () => {
        alert("Group delete");
    }

    render() {
        const { classes } = this.props;
        const {
            showDetailOf,
            addItem,
            items,
            item,
            users,
            isLoading
        } = this.state;

        return (
            <DashboardLayout title={items && showDetailOf && item ? `Grupo: ${item.code}` : "Grupos"}>
                <div className={classNames(classes.root, { [classes.hidden]: showDetailOf || addItem })}>
                    <div className={classes.content}>
                        <Table
                            items={items}
                            onDelete={this.deleteGroup}
                            onEditClick={(id) => {
                                this.setState({ showDetailOf: id });
                            }}
                            onAddClick={() => {
                                this.setState({ addItem: true });
                            }}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
                {
                    (showDetailOf != null || addItem) &&
                    <div className={classNames(classes.root, { [classes.hidden]: !showDetailOf && !addItem })}>
                        <div className={classes.content}>
                            <EditForm
                                id={showDetailOf}
                                users={users}
                                onBack={() => {
                                    this.getData();
                                    this.setState({ showDetailOf: null, addItem: false });
                                }}
                            />
                        </div>
                    </div>
                }
            </DashboardLayout>
        );
    }
}

export default withStyles(styles)(withSnackbar(UserGroups));