import * as React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

const MultiSelectAsync = ({ defaultOptions, value, onChange, placeholder, noOptionsMessage, ...props }) => {
    defaultOptions = defaultOptions || [];

    props = {
        ...props,
        noOptionsMessage: noOptionsMessage ? noOptionsMessage : ({inputValue}) => !inputValue ? "Escribe para buscar opciones" : "No hay opciones",
        placeholder: (placeholder || "Seleccionar"),
        onChange: selected => onChange(selected || []),
        value: value,
        isMulti: true,
        defaultOptions
    };

    return <AsyncSelect {...props} />;
};

MultiSelectAsync.propTypes = {
    noOptionsMessage: PropTypes.string,
    defaultOptions: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    placeholder: PropTypes.string
};

export default MultiSelectAsync;