import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
  {
    externalEntityGetListRequest: [],
    externalEntityGetListSuccess: ['items'],
    externalEntityGetListFailure: ['error'],
    setLoading: ['isLoading']
  },
  {}
);

export const ExternalEntityTypes = Types;
export const ExternalEntityCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
  externalEntities: [],
  isLoading: false
});

export const onGetList = (state, { items }) =>
  state.merge({ externalEntities: items });
export const onSetLoading = (state, { isLoading }) =>
  state.merge({ isLoading });

//map our actions types to our reducer functions
export const HANDLERS = {
  [ExternalEntityTypes.EXTERNAL_ENTITY_GET_LIST_SUCCESS]: onGetList,
  [ExternalEntityTypes.SET_LOADING]: onSetLoading
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectExternalEntities = state =>
  Immutable.asMutable(
    state.externalEntityReducer.externalEntities.filter(x => x.isActive),
    { deep: true }
  );
export const setLoading = state => state.externalEntityReducer.isLoading;
