import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { SubCategoryCreators, selectSubCategories, selectSubCategory, selectIsLoading, selectMessageOperation } from "redux/SubCategoryRedux";

export const mapStateToProps = state => ({
	subCategory: selectSubCategory(state),
	subCategories: selectSubCategories(state),
	isLoading: selectIsLoading(state),
	messageOperation: selectMessageOperation(state)
});

export const mapDispatchToProps = dispatch => bindActionCreators({
    getSubCategoriesByResponsible: SubCategoryCreators.responsibleSubCategoriesRequest,
	getSubCategory: SubCategoryCreators.subCategoryGetRequest,
	getSubCategories: SubCategoryCreators.subCategoryGetByCategoryIdRequest,
	getAllSubCategories: SubCategoryCreators.allSubCategoriesRequest,
	createSubCategory: SubCategoryCreators.subCategoryCreateRequest,
	updateSubCategory: SubCategoryCreators.subCategoryUpdateRequest,
	deleteSubCategory: SubCategoryCreators.subCategoryDeleteRequest,
	clearMessage: SubCategoryCreators.subCategorySetMessage,
	clearSubCategory: SubCategoryCreators.subCategoryGetSuccess,
	clearSubCategories: SubCategoryCreators.subCategoryListSuccess
}, dispatch);

export const hocConector = WrappedComponent => {
	const hoc = ({ ...props }) => <WrappedComponent {...props} />;

	hoc.propTypes = {
        /** Get all company categories */
        getSubCategoriesByResponsible: PropTypes.func,
        getAllSubCategories: PropTypes.func,
		getSubCategory: PropTypes.func,
		getSubCategories: PropTypes.func,
		createSubCategory: PropTypes.func,
		updateSubCategory: PropTypes.func,
		deleteSubCategory: PropTypes.func,
        clearMessage: PropTypes.func,
        clearSubCategories: PropTypes.func
	};

	return hoc;
};

export default Component => connect(mapStateToProps, mapDispatchToProps)(hocConector(Component));