import * as React from 'react';
import { withStyles } from '@material-ui/core';
import { WithNotifications } from 'hoc';

import {
	Button,
	InputLabel,
	FormControlLabel,
	Grid,
	Switch,
	Dialog,
	DialogContent,
	// DialogContentText,
	DialogActions,
	DialogTitle
} from '@material-ui/core';

import {
	Table,
	MultiSelect,
} from 'components';

import classNames from 'classnames';
import { Select, Loader, SaveContainer } from 'components';
import WithGeneralInfo from '../hoc/WithGeneralInfo';
import { TextField } from 'formik-material-ui';
import { Formik, Field, Form } from 'formik';
import { validationSchema } from './resources';
import Department from 'contract/requests/Department';

import styles from './styles';

class GeneralInfo extends React.Component {
	state = {
		department: {
			code: '',
			detail: '',
			isActive: true,
			ownerId: null,
			departmentMembers: [],
		},
		selectedUsers: [],
		open: false,
	};

	membersColumns = [
		{
			title: "Usuario",
			field: "userId",
			render: rowData => <span>{this.getUserName(rowData.userId)}</span>
		}
	]

	componentDidMount() {
		const { getDepartment, id } = this.props;
		if (id) getDepartment(id);
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.department !== this.props.department) {
			this.setState({
				department: { ...nextProps.department, departmentMembers: [...nextProps.department.departmentMembers.asMutable()].map(x => ({ ...x })) }
			});
		}

		return true;
	}

	getUserName = (userId) => {
		const { users } = this.props;

		const user = users.find(x => x.id == userId);

		return user ? user.displayName : "";
	}

	handleClose = () => {
		this.setState({ open: false, selectedUsers: [] });
	}

	addUsers = () => {
		const {
			department,
			selectedUsers
		} = this.state;

		const {
			id,
		} = this.props;

		if (!selectedUsers.length) {
			this.handleClose();
			this.props.addNotification({ message: "No hay usuario seleccionados", options: { variant: "error" } });
			return;
		}

		const departmentMembers = [...department.departmentMembers || []];

		selectedUsers.forEach(i => {
			if (departmentMembers.find(x => x.userId == i))
				return;

			departmentMembers.push({
				userId: i,
				departmentId: id,
			});
		});

		this.setState(ps => ({ department: { ...ps.department, departmentMembers } }));
		this.handleClose();
	}

	removeMember = (member) => {
		const {
			department
		} = this.state;

		let departmentMembers = [...department.departmentMembers || []];
		departmentMembers = departmentMembers.filter(x => x.userId != member.userId);
		this.setState(ps => ({ department: { ...ps.department, departmentMembers } }));
	}

	render() {
		const {
			classes,
			createDepartment,
			updateDepartment,
			isLoading,
			users,
			id,
		} = this.props;

		const {
			department,
			selectedUsers,
			open,
		} = this.state;

		const selectUsers = users.map(i => ({
			value: i.id,
			label: i.displayName,
			isActive: i.isActive
		}));

		return (
			<React.Fragment>
				{isLoading ? (
					<Loader />
				) : (
					<Formik
						key={(department.departmentMembers || []).length}
						enableReinitialize
						initialValues={department}
						validationSchema={validationSchema}
						onSubmit={values => {
							let request = new Department({ ...values, departmentMembers: department.departmentMembers });

							const creator = users.find(x => x.id == values.ownerId);
							request.ownerName = creator
								? creator.displayName
								: 'Administrador';

							if (!values.id) {
								createDepartment(request);
							} else {
								updateDepartment(request);
							}
						}}
						render={({ submitForm, isSubmitting }) => (
							<SaveContainer saveContent={submitForm}>
								<Form>
									<Grid container className={classes.root} spacing={2}>
										<Grid item xs={12} md={6}>
											<Grid item xs={12}>
												<Field
													type="text"
													label="Nombre"
													name="code"
													margin="normal"
													component={TextField}
													fullWidth
													autoFocus
													InputLabelProps={{
														shrink: true
													}}
												/>
											</Grid>
											<Grid item xs={12}>
												<Field
													type="text"
													label="Detalles"
													name="detail"
													component={TextField}
													margin="normal"
													fullWidth
													multiline
													rows={3}
													InputLabelProps={{
														shrink: true
													}}
												/>
											</Grid>
											<Grid item xs={12}>
												<Field
													name="ownerId"
													render={({ field, form }) => {
														return (
															<div
																className={classNames(classes.selectRoot, {
																	error:
																		form.touched[field.name] &&
																		form.errors[field.name]
																})}
															>
																<InputLabel className={classes.inputLabel}>
																	Responsable
																</InputLabel>
																<Select
																	isSearchable
																	value={field.value}
																	onChange={selected =>
																		form.setFieldValue(field.name, selected.value)
																	}
																	options={selectUsers}
																	onMenuClose={() =>
																		form.setFieldTouched(field.name, true, true)
																	}
																	onMenuOpen={() =>
																		form.setFieldTouched(field.name, false)
																	}
																/>
																{form.touched[field.name] &&
																	form.errors[field.name] && (
																		<p className="MuiFormHelperText-root Mui-error">
																			{form.errors[field.name]}
																		</p>
																	)}
															</div>
														);
													}}
												/>
											</Grid>
											<Grid item xs={12}>
												<FormControlLabel
													control={
														<Field
															label="Activo"
															name="isActive"
															component={props => (
																<Switch
																	checked={props.field.value}
																	value={props.field.name}
																	onChange={(e, value) =>
																		props.form.setFieldValue(
																			props.field.name,
																			value
																		)
																	}
																/>
															)}
														/>
													}
													label="Activo"
												/>
												<br />
											</Grid>
											<Grid item xs={12}>
												<Button
													variant="contained"
													color="primary"
													disabled={isSubmitting}
													onClick={submitForm}
												>
													Guardar
												</Button>
											</Grid>
										</Grid>
										{
											id > 0 &&
											<Grid item xs={12} md={6}>
												<Grid item xs={12}>
													<Field
														name="departmentMembers"
														render={({ field, form }) => {
															return (
																<div
																	className={
																		classNames(
																			classes.selectRoot,
																			{ "error": form.touched[field.name] && form.errors[field.name] }
																		)
																	}
																>
																	<InputLabel>Usuarios a cargo</InputLabel>
																	{
																		form.touched[field.name] && form.errors[field.name] &&
																		<p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
																	}
																	<br />
																	<Table
																		options={{ pageSize: 5, }}
																		data={department.departmentMembers}
																		columns={this.membersColumns}
																		actions={[
																			{
																				icon: 'delete',
																				tooltip: "Quitar",
																				onClick: (e, rowData) => this.removeMember(rowData)
																			},
																			{
																				icon: 'add',
																				isFreeAction: true,
																				tooltip: "Agregar usuarios",
																				onClick: () => this.setState({ open: true })
																			}
																		]}
																	/>
																</div>
															);
														}}
													/>
												</Grid>
											</Grid>
										}
									</Grid>
								</Form>
							</SaveContainer>
						)}
					/>
				)}
				<Dialog
					open={open}
					onClose={this.handleClose}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">Agregar usuario al grupo</DialogTitle>
					<DialogContent>
						<MultiSelect
							closeMenuOnSelect={false}
							onChange={(selected) => {
								this.setState({ selectedUsers: selected });
							}}
							value={selectedUsers}
							options={selectUsers}
							styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
							menuPortalTarget={document.body}
							menuPosition={'absolute'}
							menuPlacement={'bottom'}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={this.handleClose}
							color="primary"
						>
							Cancelar
						</Button>
						<Button onClick={this.addUsers} color="primary">
							Agregar
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}
}

export default WithNotifications(withStyles(styles)(WithGeneralInfo(GeneralInfo)));
