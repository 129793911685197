import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectUsers } from "redux/UserRedux";
import { SubCategoryLocationCreators, selectOwnerSubCategoryLocations, selectLoading, selectOwnerLoading, selectOwnerId, selectMessageOperation, selectSubCategoryLocations } from "redux/SubCategoryLocationRedux";

export const mapStateToProps = state => ({
    messageOperation: selectMessageOperation(state),
    ownerId: selectOwnerId(state),
    isLoading: selectLoading(state),
    isOwnerLoading: selectOwnerLoading(state),
    users: selectUsers(state),
    companyLocations: selectOwnerSubCategoryLocations(state),
    subCategoryLocations: selectSubCategoryLocations(state)
});

export const mapDispatchToProps = {
    getCatalogs: SubCategoryLocationCreators.subCategoryLocationCatalogsRequest,
    getByOwnerId: SubCategoryLocationCreators.subCategoryLocationGetByOwnerIdRequest,
    getSubCategoryLocations: SubCategoryLocationCreators.subCategoryLocationGetBySubCategoryIdRequest,
    createSubCategoryLocation: SubCategoryLocationCreators.subCategoryLocationCreateRequest,
    updateSubCategortLocation: SubCategoryLocationCreators.subCategoryLocationUpdateRequest
};

export const hocSubCategoryLocation = (WrappedComponent) => {
    const hocComponent = ({ ...props }) => <WrappedComponent {...props} />;

    hocComponent.propTypes = {
        users: PropTypes.array,
        companyLocations: PropTypes.array,
        subCategoryLocations: PropTypes.array,
        getUsers: PropTypes.func,
        getCompanyLocations: PropTypes.func,
        getSubCategoryLocations: PropTypes.func
    };

    return hocComponent;
};

export default WithSubCategoryLocation => connect(mapStateToProps, mapDispatchToProps)(hocSubCategoryLocation(WithSubCategoryLocation));