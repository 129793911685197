import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  CompanyLocationCreators,
  selectMessageOperation,
  selectCompanyLocations,
  selectIsLoading,
  selectIsValidEdit
} from '../../../../redux/CompanyLocationRedux';

export const mapStateToProps = state => ({
  messageOperation: selectMessageOperation(state),
  companyLocations: selectCompanyLocations(state),
  isLoading: selectIsLoading(state),
  isValidEdit: selectIsValidEdit(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCompanyLocations: CompanyLocationCreators.companyLocationListRequest,
      deleteCompanyLocation:
        CompanyLocationCreators.companyLocationDeleteRequest,
      clearMessage: CompanyLocationCreators.companyLocationSetMessage,
      getIsvalidEdit: CompanyLocationCreators.companyLocationIsValidEditRequest,
      clearIsValidEdit:
        CompanyLocationCreators.companyLocationIsValidEditSuccess
    },
    dispatch
  );

export const hocCompanyLocations = WrappedComponent => {
  const hoc = ({ ...props }) => <WrappedComponent {...props} />;

  hoc.propTypes = {
    /** Company locations collection */
    companyLocations: PropTypes.array,
    isLoading: PropTypes.bool,
    getCompanyLocations: PropTypes.func,
    deleteCompanyLocation: PropTypes.func
  };

  return hoc;
};

export default WithCompanyLocations =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hocCompanyLocations(WithCompanyLocations));
