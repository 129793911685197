import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { UserCreators, selectUsers, selectIsLoading, selectMessageOperation } from "redux/UserRedux";
import { selectRoles } from "redux/RoleRedux";

export const mapStateToProps = state => ({
	users: selectUsers(state),
	roles: selectRoles(state),
	isLoading: selectIsLoading(state),
	messageOperation: selectMessageOperation(state)
});

export const mapDispatchToProps = dispatch => bindActionCreators({
	getUser: UserCreators.userGetRequest,
	getUsers: UserCreators.userListRequest,
	deleteUser: UserCreators.userDeleteRequest,
	setEditId: UserCreators.setEditIdRequest,
	clearMessage: UserCreators.userSetMessage
}, dispatch);

export const hocComponent = WrappedComponent => {
	const hoc = class HocUsers extends React.Component {
		render() {
			return (<WrappedComponent {...this.props} />);
		}
	};

	hoc.propTypes = {
		/** Get all company categories */
		getUsers: PropTypes.func,
		deleteUser: PropTypes.func,
		setEditId: PropTypes.func,
		users: PropTypes.array,
		isLoading: PropTypes.bool
	};

	return hoc;
};

export default WithUsers => connect(mapStateToProps, mapDispatchToProps)(hocComponent(WithUsers));