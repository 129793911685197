import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  DepartmentCreators,
  selectMessageOperation,
  selectDepartments,
  selectIsLoading
} from 'redux/DepartmentRedux';

export const mapStateToProps = state => ({
  messageOperation: selectMessageOperation(state),
  departments: selectDepartments(state),
  isLoading: selectIsLoading(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDepartments: DepartmentCreators.departmentListRequest,
      deleteDepartment: DepartmentCreators.departmentDeleteRequest,
      clearMessage: DepartmentCreators.departmentSetMessage
    },
    dispatch
  );

export const hoc = WrappedComponent => {
  const hocComponent = ({ ...props }) => <WrappedComponent {...props} />;

  hocComponent.propTypes = {
    /** Company locations collection */
    deparments: PropTypes.array,
    isLoading: PropTypes.bool,
    getDepartments: PropTypes.func,
    deleteDepartment: PropTypes.func
  };

  return hocComponent;
};

export default WithDepartments =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hoc(WithDepartments));
