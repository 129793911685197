import Api from "./Api";

class UploadsService extends Api {

    constructor(controller){
        super(controller);
    }

	uploadTempAttachment = (file) => {
		const formData = new FormData();
		formData.append('file', file);
		const headers = {
			'Content-Type': 'multipart/form-data'
		};

		return this.API.post(`${this.controller}`, formData, { headers });
	}
}

export default new UploadsService("uploads");