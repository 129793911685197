import React from 'react';
//import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  AttachmentConfigCreators,
  selectIsLoading,
  selectAttachmentConfig
} from 'redux/AttachmentConfigRedux';
import { selectSubCategoryAttachmentConfigs } from 'redux/SubCategoryAttachmentConfigRedux';

export const mapStateToProps = state => ({
  isLoading: selectIsLoading(state),
  attachmentConfig: selectAttachmentConfig(state),
  subCategories: selectSubCategoryAttachmentConfigs(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAttachmentConfig: AttachmentConfigCreators.attachmentConfigGetRequest,
      saveMultiple: AttachmentConfigCreators.attachmentConfigSaveMultipleRequest
      // createAttachmentConfig:
      //   AttachmentConfigCreators.attachmentConfigCreateRequest,
      // updateAttachmentConfig:
      //   AttachmentConfigCreators.attachmentConfigUpdateRequest
    },
    dispatch
  );

export const hocConector = WrappedComponent => {
  const hoc = ({ ...props }) => <WrappedComponent {...props} />;

  hoc.propTypes = {
    /** Get all company categories */
  };

  return hoc;
};

export default Component =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hocConector(Component));
