import React, { Component } from 'react';
import classNames from 'classnames';

// Externals
import PropTypes from 'prop-types';

//Material components
import { withStyles } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { GeneralInfo, WithTasks } from './components';
// import { TaskTable, EditForm, WithTasks } from './components';
import { Portlet, PortletContent } from 'components';

import { withSnackbar } from 'notistack';

import { injectIntl } from 'react-intl';

// Component styles
import styles from './styles';

class Task extends Component {
  state = {
    isLoading: false,
    companyLocations: [],
    showDetailOf: null,
    addItem: false
  };

  componentDidMount() {
    const { getTasks } = this.props;
    getTasks();
  }

  shouldComponentUpdate(nextProps) {
    const { enqueueSnackbar, clearMessage, intl } = this.props;
    if (
      nextProps.messageOperation &&
      nextProps.messageOperation !== this.props.messageOperation
    ) {
      enqueueSnackbar(
        intl.formatMessage({ id: nextProps.messageOperation.message }),
        { variant: nextProps.messageOperation.type }
      );
      clearMessage();
    }

    return true;
  }

  render() {
    const { classes } = this.props;
    // const { classes, tasks, isLoading, deleteTask } = this.props;
    // const { showDetailOf, addItem } = this.state;

    return (
      <DashboardLayout title="Configurar Tarea">
        {/* <div
          className={classNames(classes.root, {
            [classes.hidden]: showDetailOf || addItem
          })}
        > */}
        <div className={classNames(classes.root)}>
          <div className={classes.content}>
            <Portlet className={classes.root}>
              <PortletContent noPadding>
                <GeneralInfo />
              </PortletContent>
            </Portlet>
            {/* <TaskTable
              items={tasks}
              onDelete={deleteTask}
              onAddClick={() => this.setState({ addItem: true })}
              onEditClick={id => {
                console.log(id);
                this.setState({ showDetailOf: id });
              }}
              isLoading={isLoading}
            /> */}
          </div>
        </div>
        {/* {(showDetailOf != null || addItem) && (
          <div className={classes.root}>
            <div className={classes.content}>
              <EditForm
                id={showDetailOf}
                onBack={() =>
                  this.setState({ showDetailOf: null, addItem: false })
                }
              />
            </div>
          </div>
        )} */}
      </DashboardLayout>
    );
  }
}

Task.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withSnackbar(WithTasks(injectIntl(Task))));
