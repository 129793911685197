import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectUsers } from "redux/UserRedux";
//import { selectCompanyLocations } from "redux/CompanyLocationRedux";
import { CategoryCreators, selectCategoryLocations, selectOwnerCategoryLocations, selectIsLoading, selectOwnerLoading, selectOwnerId, selectMessageOperation } from "redux/CategoryRedux";

export const mapStateToProps = state => ({
    messageOperation: selectMessageOperation(state),
    ownerId: selectOwnerId(state),
    isLoading: selectIsLoading(state),
    isOwnerLoading: selectOwnerLoading(state),
    users: selectUsers(state),
    companyLocations: selectOwnerCategoryLocations(state),
    categoryLocations: selectCategoryLocations(state)
});

export const mapDispatchToProps = {
    getCatalogs: CategoryCreators.categoryLocationCatalogsRequest,
    getCategoryLocations: CategoryCreators.categoryLocationGetByCategoryIdRequest,
    getByOwnerId: CategoryCreators.categoryLocationGetByOwnerIdRequest,
    createCategoryLocation: CategoryCreators.categoryLocationCreateRequest,
    updateCategortLocation: CategoryCreators.categoryLocationUpdateRequest
};

export const hocCategoryLocation = (WrappedComponent) => {
    const hocComponent = ({ ...props }) => <WrappedComponent {...props} />;

    hocComponent.propTypes = {
        users: PropTypes.array,
        companyLocations: PropTypes.array,
        getUsers: PropTypes.func,
        getCompanyLocations: PropTypes.func
    };

    return hocComponent;
};

export default WithCategoryLocation => connect(mapStateToProps, mapDispatchToProps)(hocCategoryLocation(WithCategoryLocation));