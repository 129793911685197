import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
  {
    chartWorkItemDataRequest: [],
    chartWorkItemCategoriesRequest: [],
    chartWorkItemCategoriesSuccess: ['chartWorkItemCategories'],
    chartWorkItemCategoriesError: ['error'],
    chartWorkItemDepartmentsRequest: [],
    chartWorkItemDepartmentsSuccess: ['chartWorkItemDepartments'],
    chartWorkItemDepartmentsError: ['error'],
    chartWorkItemLocationsRequest: [],
    chartWorkItemLocationsSuccess: ['chartWorkItemLocations'],
    chartWorkItemLocationsError: ['error'],
    chartWorkItemMonthlyRequest: [],
    chartWorkItemMonthlySuccess: ['chartWorkItemMonthly'],
    chartWorkItemMonthlyError: ['error'],
    setLoading: ['isLoading']
  },
  {}
);

export const ChartWorkItemTypes = Types;
export const ChartWorkItemCreators = Creators;

/**
 * Redux
 */
// the initial chart work item of this reducer
export const INITIAL_STATE = Immutable({
  chartWorkItemCategories: [],
  chartWorkItemLocations: [],
  chartWorkItemDepartments: [],
  chartWorkItemMonthly: [],
  isLoading: false
});

export const onChartWorkItemCategoriesSuccess = (
  state,
  { chartWorkItemCategories }
) => state.merge({ chartWorkItemCategories });
export const onChartWorkItemLocationsSuccess = (
  state,
  { chartWorkItemLocations }
) => state.merge({ chartWorkItemLocations });
export const onChartWorkItemDepartmentsSuccess = (
  state,
  { chartWorkItemDepartments }
) => state.merge({ chartWorkItemDepartments });
export const onChartWorkItemMonthlySuccess = (
  state,
  { chartWorkItemMonthly }
) => state.merge({ chartWorkItemMonthly });
export const onSetLoading = (state, { isLoading }) =>
  state.merge({ isLoading });

//map our actions types to our reducer functions
export const HANDLERS = {
  [ChartWorkItemTypes.CHART_WORK_ITEM_CATEGORIES_SUCCESS]: onChartWorkItemCategoriesSuccess,
  [ChartWorkItemTypes.CHART_WORK_ITEM_LOCATIONS_SUCCESS]: onChartWorkItemLocationsSuccess,
  [ChartWorkItemTypes.CHART_WORK_ITEM_DEPARTMENTS_SUCCESS]: onChartWorkItemDepartmentsSuccess,
  [ChartWorkItemTypes.CHART_WORK_ITEM_MONTHLY_SUCCESS]: onChartWorkItemMonthlySuccess,
  [ChartWorkItemTypes.SET_LOADING]: onSetLoading
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectChartWorkItemCategories = state => {
  let items = Immutable.asMutable(
    state.chartWorkItemReducer.chartWorkItemCategories,
    {
      deep: true
    }
  );

  let total = 0;
  items.forEach(function(item) {
    total += item.tickets;
  });

  let temp = items.map(x => ({
    ...x,
    color: Math.round((x.tickets / total) * 100)
  }));

  return temp;
};
export const selectChartWorkItemDepartments = state => {
  let items = Immutable.asMutable(
    state.chartWorkItemReducer.chartWorkItemDepartments,
    {
      deep: true
    }
  );

  let total = 0;
  items.forEach(function(item) {
    total += item.tickets;
  });

  let temp = items.map(x => ({
    ...x,
    color: Math.round((x.tickets / total) * 100)
  }));

  return temp;
};
export const selectChartWorkItemLocations = state => {
  let items = Immutable.asMutable(
    state.chartWorkItemReducer.chartWorkItemLocations,
    {
      deep: true
    }
  );

  let total = 0;
  items.forEach(function(item) {
    total += item.tickets;
  });

  let temp = items.map(x => ({
    ...x,
    color: Math.round((x.tickets / total) * 100)
  }));

  return temp;
};
export const selectChartWorkItemMonthly = state =>
  Immutable.asMutable(state.chartWorkItemReducer.chartWorkItemMonthly, {
    deep: true
  });
