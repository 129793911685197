import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
//import Yup from 'yup';
import Moment from 'moment';
import classNames from "classnames";

// UI Components
import {
    withStyles,
    Grid,
    Typography,
    CircularProgress,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Button,
    InputLabel,
    Icon
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table, Select, SelectAsync } from 'components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DatePicker } from "@material-ui/pickers";
import { Formik, Field, Form } from 'formik';

//Services
import { WithTicket, WithSession, WithNotifications } from "hoc";
import severitiesService from "services/severitiesService";
import ticketsService from "services/ticketsService";
import userService from "services/userService";
import categoriesService from "services/categoriesService";
import subCategoriesService from "services/subCategoriesService";
import companyLocationsService from "services/companyLocationService";

//Assets
import styles from './styles';

class SearchTickets extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        id: PropTypes.number,
        onBack: PropTypes.func
    };

    static defaultProps = {
        id: null,
        onBack: () => { },
    };

    state = {
        tickets: [],
        severities: [],
        categories: [],
        subCategories: [],
        companyLocations: [],
        filtersPanelExpanded: true,
        tablePanelExpanded: true,
        date: new Date()
    };

    columns = [
        {
            title: "Id",
            field: 'id'
        },
        {
            title: "Asunto",
            field: 'subject',
            render: row => {
                return (
                    <Link to={`/ticket/${row.id}`}>
                        {row.subject}
                    </Link>
                );
            }
        },
        {
            title: "SubCategoria",
            field: 'subCategory'
        },
        {
            title: "Estado",
            field: 'status'
        },
        {
            title: "Creado por",
            field: 'createdByName'
        },
        {
            title: "Última modificación",
            field: 'createdDate',
            render: row => {
                return (
                    <span>
                        {
                            row.modifiedDate
                                ? Moment(row.modifiedDate).local().fromNow()
                                : Moment(row.createdDate).local().fromNow()
                        }
                    </span>
                );
            }
        }
    ];

    selectStatus = [
        { value: 1, label: "Inicial" },
        { value: 2, label: "En progreso" },
        { value: 3, label: "Finalizado" }
    ]

    emptyFilters = {
        startDate: Moment().startOf('month'),
        endDate: Moment().endOf('day'),
        workItemId: "",
        categoryId: "",
        subCategoryId: "",
        locationId: "",
        subject: "",
        assignedTo: "",
        createdBy: "",
        statusId: "",
        severityId: ""
    }

    statuses = [
        { value: 1, label: "Inicial" },
        { value: 2, label: "En progreso" },
        { value: 3, label: "Finalizado" }
    ]

    componentDidMount = () => {
        this.searchTickets(this.emptyFilters);

        severitiesService.getAllSimple().then((response) => {
            this.setState({ severities: response.data.data });
        });

        categoriesService.getAllSimple().then((response) => {
            this.setState({ categories: response.data.data });
        });

        companyLocationsService.getAllSimple().then((response) => {
            this.setState({ companyLocations: response.data.data });
        });

        subCategoriesService.getAll().then((response) => {
            this.setState({ allSubCategories: response.data.data });
        });
    }

    updateSubCategories = (categoryId) => {
        if (!categoryId) {
            this.setState({ subCategories: [] });
            return;
        }

        subCategoriesService.getSimpleByCategoryId(categoryId).then((response) => {
            this.setState({ subCategories: response.data.data });
        });
    }

    searchTickets = (values) => {
        ticketsService.search(values).then((response) => {
            if (!response.data || response.data.status == "Fail" || !response.data.data) {
                return;
            }

            let tickets = response.data.data;

            tickets.forEach(i => {
                i.status = this.statuses[(i.statusId || 0) - 1].label;

                const subCategory = this.state.allSubCategories ? this.state.allSubCategories.find(x => x.id == i.subCategoryId) : null;

                i.subCategory = subCategory ? subCategory.code : i.subCategoryId;
            });

            this.setState({ tickets });
        });
    }

    copyRows = () => {
        this.setState({ copiyng: true }, () => {
            let table = document.getElementsByTagName('table')[0];

            if (window.getSelection) {
                const selection = window.getSelection();
                const range = document.createRange();
                range.selectNodeContents(table);
                selection.removeAllRanges();
                selection.addRange(range);
            }

            document.execCommand('copy', true);
            window.getSelection().removeAllRanges();
            this.props.addNotification({ message: "Tickets copiados al porta papeles", options: { variant: "success" } });

            window.setTimeout(() => {
                this.setState({ copiyng: false });
            }, 500);
        });
    }

    renderFilters = () => {
        const {
            classes
        } = this.props;

        const {
            severities,
            companyLocations,
            date,
            categories,
            subCategories
        } = this.state;

        return (
            <Grid container alignItems="center">
                <Grid item xs={12} justify="center" alignContent="center">
                    <Formik
                        key={date.toISOString()}
                        style={{ width: '100%' }}
                        enableReinitialize
                        initialValues={this.emptyFilters}
                        onSubmit={async (values, form) => {
                            this.setState({ filtersPanelExpanded: false });
                            this.searchTickets(values);
                            form.setSubmitting(false);
                        }}
                        render={({ submitForm, isSubmitting, values }) => (
                            <Form>
                                <Grid container className={classes.root} justify="space-beetwen">
                                    <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                                        <Field
                                            label="Fecha inicial"
                                            name="startDate"
                                            margin="normal"
                                            render={({ field, form }) => (
                                                <Grid
                                                    direction="column"
                                                    justify="center"
                                                    className={
                                                        classNames(
                                                            classes.inputRoot,
                                                            { "error": form.touched[field.name] && form.errors[field.name] }
                                                        )
                                                    }
                                                >
                                                    <InputLabel>Fecha inicial</InputLabel>
                                                    <DatePicker
                                                        format="DD MMMM [del] YYYY"
                                                        value={field.value}
                                                        onChange={(date) => form.setFieldValue(field.name, date)}
                                                    />
                                                    {
                                                        form.touched[field.name] && form.errors[field.name] &&
                                                        <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                    }
                                                </Grid>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                                        <Field
                                            label="Fecha inicial"
                                            name="endDate"
                                            margin="normal"
                                            render={({ field, form }) => (
                                                <Grid
                                                    direction="column"
                                                    justify="center"
                                                    className={
                                                        classNames(
                                                            classes.inputRoot,
                                                            { "error": form.touched[field.name] && form.errors[field.name] }
                                                        )
                                                    }
                                                >
                                                    <InputLabel>Fecha final</InputLabel>
                                                    <DatePicker
                                                        format="DD MMMM [del] YYYY"
                                                        value={field.value}
                                                        onChange={(date) => form.setFieldValue(field.name, date)}
                                                    />
                                                    {
                                                        form.touched[field.name] && form.errors[field.name] &&
                                                        <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                    }
                                                </Grid>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} className={classes.item} alignContent="center">
                                        <Field
                                            name="locationId"
                                            render={({ field, form }) => {
                                                return (
                                                    <div
                                                        className={
                                                            classNames(
                                                                classes.selectRoot,
                                                                { "error": form.touched[field.name] && form.errors[field.name] }
                                                            )
                                                        }
                                                    >
                                                        <InputLabel>Sucursal</InputLabel>
                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            value={field.value}
                                                            onChange={(selected) => form.setFieldValue(field.name, selected.value)}
                                                            options={companyLocations}
                                                            onMenuClose={() => form.setFieldTouched(field.name, true, true)}
                                                            onMenuOpen={() => form.setFieldTouched(field.name, false)}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'absolute'}
                                                            menuPlacement={'bottom'}
                                                        />
                                                        {
                                                            form.touched[field.name] && form.errors[field.name] &&
                                                            <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                        }
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                                        <Field
                                            name="categoryId"
                                            render={({ field, form }) => {
                                                return (
                                                    <div
                                                        className={
                                                            classNames(
                                                                classes.selectRoot,
                                                                { "error": form.touched[field.name] && form.errors[field.name] }
                                                            )
                                                        }
                                                    >
                                                        <InputLabel>Categoria</InputLabel>
                                                        <Select
                                                            isClearable
                                                            key={field.value}
                                                            isSearchable
                                                            value={field.value}
                                                            onChange={(selected) => {
                                                                form.setFieldValue(field.name, selected.value);
                                                                form.setFieldValue("subCategoryId", "");
                                                                this.updateSubCategories(selected.value);
                                                            }}
                                                            options={categories}
                                                            onMenuClose={() => form.setFieldTouched(field.name, true, true)}
                                                            onMenuOpen={() => form.setFieldTouched(field.name, false)}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'absolute'}
                                                            menuPlacement={'bottom'}
                                                        />
                                                        {
                                                            form.touched[field.name] && form.errors[field.name] &&
                                                            <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                        }
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                                        <Field
                                            name="subCategoryId"
                                            render={({ field, form }) => (
                                                <div
                                                    className={
                                                        classNames(
                                                            classes.selectRoot,
                                                            { "error": form.touched[field.name] && form.errors[field.name] }
                                                        )
                                                    }
                                                >
                                                    <InputLabel>SubCategoria</InputLabel>
                                                    <Select
                                                        isClearable
                                                        key={field.value}
                                                        isSearchable
                                                        isDisabled={!values.categoryId || !subCategories.length}
                                                        value={field.value}
                                                        onChange={(selected) => form.setFieldValue(field.name, selected.value)}
                                                        options={subCategories}
                                                        onMenuClose={() => form.setFieldTouched(field.name, true, true)}
                                                        onMenuOpen={() => form.setFieldTouched(field.name, false)}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        menuPortalTarget={document.body}
                                                        menuPosition={'absolute'}
                                                        menuPlacement={'bottom'}
                                                    />
                                                    {
                                                        form.touched[field.name] && form.errors[field.name] &&
                                                        <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                    }
                                                </div>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                                        <Field
                                            name="createdBy"
                                            render={({ field, form }) => {
                                                return (
                                                    <div
                                                        className={
                                                            classNames(
                                                                classes.selectRoot,
                                                                { "error": form.touched[field.name] && form.errors[field.name] }
                                                            )
                                                        }
                                                    >
                                                        <InputLabel>Creador</InputLabel>
                                                        <SelectAsync
                                                            value={field.value}
                                                            onChange={(selected) => form.setFieldValue(field.name, selected.value)}
                                                            cacheOptions
                                                            loadOptions={async value => {
                                                                if (!value) return [];
                                                                const res = await userService.search(value);
                                                                return res.data && res.data.data && Array.isArray(res.data.data)
                                                                    ? res.data.data
                                                                    : [];
                                                            }}
                                                            onInputChange={newValue => {
                                                                this.setState({ creatorSearch: newValue });
                                                                return newValue;
                                                            }}
                                                            onMenuClose={() => form.setFieldTouched(field.name, true, true)}
                                                            onMenuOpen={() => form.setFieldTouched(field.name, false)}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'absolute'}
                                                            menuPlacement={'bottom'}
                                                        />
                                                        {
                                                            form.touched[field.name] && form.errors[field.name] &&
                                                            <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                        }
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                                        <Field
                                            name="assignedTo"
                                            render={({ field, form }) => {
                                                return (
                                                    <div
                                                        className={
                                                            classNames(
                                                                classes.selectRoot,
                                                                { "error": form.touched[field.name] && form.errors[field.name] }
                                                            )
                                                        }
                                                    >
                                                        <InputLabel>Responsable</InputLabel>
                                                        <SelectAsync
                                                            value={field.value}
                                                            onChange={(selected) => form.setFieldValue(field.name, selected.value)}
                                                            cacheOptions
                                                            loadOptions={async value => {
                                                                if (!value) return [];
                                                                const res = await userService.search(value);
                                                                return res.data && res.data.data && Array.isArray(res.data.data)
                                                                    ? res.data.data
                                                                    : [];
                                                            }}
                                                            onInputChange={newValue => {
                                                                this.setState({ creatorSearch: newValue });
                                                                return newValue;
                                                            }}
                                                            onMenuClose={() => form.setFieldTouched(field.name, true, true)}
                                                            onMenuOpen={() => form.setFieldTouched(field.name, false)}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'absolute'}
                                                            menuPlacement={'bottom'}
                                                        />
                                                        {
                                                            form.touched[field.name] && form.errors[field.name] &&
                                                            <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                        }
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                                        <Field
                                            name="severityId"
                                            render={({ field, form }) => {
                                                return (
                                                    <div
                                                        className={
                                                            classNames(
                                                                classes.selectRoot,
                                                                { "error": form.touched[field.name] && form.errors[field.name] }
                                                            )
                                                        }
                                                    >
                                                        <InputLabel>Severidad</InputLabel>
                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            value={field.value}
                                                            onChange={(selected) => form.setFieldValue(field.name, selected.value)}
                                                            options={severities}
                                                            onMenuClose={() => form.setFieldTouched(field.name, true, true)}
                                                            onMenuOpen={() => form.setFieldTouched(field.name, false)}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'absolute'}
                                                            menuPlacement={'bottom'}
                                                        />
                                                        {
                                                            form.touched[field.name] && form.errors[field.name] &&
                                                            <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                        }
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                                        <Field
                                            name="statusId"
                                            render={({ field, form }) => {
                                                return (
                                                    <div
                                                        className={
                                                            classNames(
                                                                classes.selectRoot,
                                                                { "error": form.touched[field.name] && form.errors[field.name] }
                                                            )
                                                        }
                                                    >
                                                        <InputLabel>Estado</InputLabel>
                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            value={field.value}
                                                            onChange={(selected) => form.setFieldValue(field.name, selected.value)}
                                                            options={this.selectStatus}
                                                            onMenuClose={() => form.setFieldTouched(field.name, true, true)}
                                                            onMenuOpen={() => form.setFieldTouched(field.name, false)}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'absolute'}
                                                            menuPlacement={'bottom'}
                                                        />
                                                        {
                                                            form.touched[field.name] && form.errors[field.name] &&
                                                            <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                        }
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                                        <Field
                                            type="text"
                                            label="Asunto"
                                            name="subject"
                                            margin="normal"
                                            component={TextField}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                                        <Field
                                            type="text"
                                            label="Ticket Id"
                                            name="workItemId"
                                            margin="normal"
                                            component={TextField}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid container className={classes.item} justify="flex-end">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            endIcon={<Icon>send</Icon>}
                                            disabled={isSubmitting}
                                            onClick={submitForm}
                                        >
                                            Buscar
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            className={classes.button}
                                            endIcon={<Icon>send</Icon>}
                                            disabled={isSubmitting}
                                            onClick={() => this.setState({ date: new Date() })}
                                            style={{ marginRight: '10px' }}
                                        >
                                            Limpiar filtros
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    />
                </Grid>
            </Grid>
        );
    }

    renderTable = () => {
        const {
            tickets,
            loading,
            selectedItems,
            copiyng
        } = this.state;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    {
                        loading
                            ? <CircularProgress />
                            : (
                                <Table
                                    columns={this.columns}
                                    items={copiyng ? selectedItems : tickets}
                                    options={{
                                        pageSize: copiyng ? 100 : 10,
                                        pageSizeOptions: [5, 10, 20, 100],
                                        selection: copiyng ? false : true,
                                        search: true,
                                        //toolbar: selectedItems && selectedItems.length ? true : false,
                                        toolbar: true,
                                        exportButton: true,
                                        exportFileName: "Tickets buscador",
                                        exportAllData: true
                                    }}
                                    actions={copiyng ? [] : [
                                        {
                                            icon: 'content_copy',
                                            tooltip: 'Copiar al porta papeles',
                                            onClick: this.copyRows
                                        }
                                    ]}
                                    onSelectionChange={(selectedItems) => {
                                        this.setState({ selectedItems });
                                    }}
                                />
                            )
                    }
                </Grid>
            </React.Fragment>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            filtersPanelExpanded,
            tablePanelExpanded
        } = this.state;

        return (
            <DashboardLayout title="Buscador">
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                <div className={classes.root}>
                                    <ExpansionPanel expanded={filtersPanelExpanded} onChange={(e, v) => this.setState({ filtersPanelExpanded: v })}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="filters-content"
                                            id="filters-header"
                                        >
                                            <Typography className={classes.heading}>Opciones de busqueda</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            {this.renderFilters()}
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel expanded={tablePanelExpanded}>
                                        <ExpansionPanelSummary
                                            aria-controls="result-content"
                                            id="result-header"
                                        >
                                            <Typography className={classes.heading}>Resultados</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            {this.renderTable()}
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(WithSession(WithNotifications(WithTicket(withStyles(styles)(SearchTickets)))));