import React from 'react';
import { withStyles } from '@material-ui/core';
import { Loader } from 'components';
import {
  //Button,
  Grid
} from '@material-ui/core';

//import { Formik, Form, FieldArray, useFormikContext } from 'formik';
import { Formik, Form, FieldArray } from 'formik';

import { validationSchema, styles } from './resources';

import WithTasks from './WithTasks';

import { withSnackbar } from 'notistack';

import { injectIntl } from 'react-intl';

import TaskTable from './TaskTable';

class Task extends React.Component {
  state = {
    tasks: [],
    users: []
  };

  componentDidMount() {
    // const { getSubCategoryTasks, subCategory, workItemId } = this.props;
    // if (subCategory) getSubCategoryTasks(workItemId, subCategory);
    this.refresh();
  }

  refresh = () => {
    const { getSubCategoryTasks, subCategory, workItemId } = this.props;
    if (subCategory) getSubCategoryTasks(workItemId, subCategory);
  };

  shouldComponentUpdate(nextProps) {
    const {
      getSubCategoryTasks,
      enqueueSnackbar,
      intl,
      clearMessage,
      workItemId
    } = this.props;

    if (nextProps.subCategory !== this.props.subCategory)
      getSubCategoryTasks(workItemId, nextProps.subCategory);

    if (nextProps.tasks !== this.props.tasks) {
      this.setState({ tasks: nextProps.tasks }, () => true);
    }

    if (
      nextProps.messageOperation &&
      nextProps.messageOperation !== this.props.messageOperation
    ) {
      enqueueSnackbar(
        intl.formatMessage({ id: nextProps.messageOperation.message }),
        { variant: nextProps.messageOperation.type }
      );
      clearMessage();
    }

    return true;
  }

  handleUpdateWorkItemTasks = (task, index) => {
    const { updateWorkItemTasks, workItemId } = this.props;
    const { tasks } = this.state;
    tasks[index] = { ...tasks[index], ...task, isUpdated: true };
    var filledTasks = tasks.filter(x => x.realizedBy && x.isUpdated);
    updateWorkItemTasks(
      filledTasks.map(x => ({ ...x, workItemId: workItemId }))
    );
  };

  render() {
    const { classes, isLoading, users, isEditable } = this.props;
    const { tasks } = this.state;

    return (
      <React.Fragment>
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            enableReinitialize
            initialValues={{ tasks: tasks }}
            //onSubmit={() => {console.log('submited')}
            validationSchema={validationSchema}
            render={({
              errors,
              values,
              //   submitForm,
              //   isSubmitting,
              setFieldValue,
              setFieldTouched,
              touched
            }) => (
              <Grid
                container
                justify="center"
                className={classes.root}
                spacing={2}
              >
                <Grid item xs={12}>
                  <Form autoComplete="off">
                    <FieldArray
                      name="tasks"
                      render={() => (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <TaskTable
                              items={values.tasks}
                              users={users}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              errors={errors}
                              touched={touched}
                              onUpdateTask={this.handleUpdateWorkItemTasks}
                              isEditable={isEditable}
                            />
                          </Grid>
                        </Grid>
                      )}
                    />
                  </Form>
                </Grid>
              </Grid>
            )}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withSnackbar(WithTasks(injectIntl(Task))));
