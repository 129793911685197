import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import {
	ReportProblem as ReportProblemIcon
} from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

class ActiveTickets extends Component {
	static defaultProps = {
		counter: 0
	};

	static propTypes = {
		className: PropTypes.string,
		onClick: PropTypes.func.isRequired,
		classes: PropTypes.object.isRequired
	};

	render() {
		const { classes, className, counter, onClick, ...rest } = this.props;

		const rootClassName = classNames(classes.root, className);

		return (
			<Paper
				{...rest}
				className={rootClassName}
				onClick={onClick}
			>
				<div className={classes.content}>
					<div className={classes.details}>
						<Typography
							className={classes.title}
							variant="body2"
						>
							Activos
						</Typography>
						<Typography
							className={classes.value}
							variant="h3"
						>
							{counter}
						</Typography>
					</div>
					<div className={classes.iconWrapper}>
						<ReportProblemIcon className={classes.icon} />
					</div>
				</div>
				{/* <div className={classes.footer}>
					<Typography
						className={classes.caption}
						variant="caption"
					>
						Ultimo mes
		  			</Typography>
				</div> */}
			</Paper>
		);
	}
}

export default withStyles(styles)(ActiveTickets);
