import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  TaskCreators,
  selectMessageOperation,
  selectTasks,
  selectIsLoading
} from 'redux/TaskRedux';

export const mapStateToProps = state => ({
  messageOperation: selectMessageOperation(state),
  tasks: selectTasks(state),
  isLoading: selectIsLoading(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTasks: TaskCreators.taskGetListRequest,
      deleteTask: TaskCreators.taskDeleteRequest,
      clearMessage: TaskCreators.taskSetMessage
    },
    dispatch
  );

export const hoc = WrappedComponent => {
  const hocComponent = ({ ...props }) => <WrappedComponent {...props} />;

  hocComponent.propTypes = {
    /** Company locations collection */
    tasks: PropTypes.array,
    isLoading: PropTypes.bool,
    getTasks: PropTypes.func,
    deleteTask: PropTypes.func
  };

  return hocComponent;
};

export default WithTasks =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hoc(WithTasks));
