
import * as Yup from "yup";

export const styles = theme => ({
    // root: {
    // 	flexGrow: 1
    // },
    selectRoot: {
        marginTop: theme.spacing.unit * 3
    },
    dialogPaper: {
        overflow: 'visible'
    }
});

export const validationSchema = Yup.object().shape({
    code: Yup.string()
        .max(100, 'Maximo 100 caracteres')
        .required('Requerido'),
    detail: Yup.string()
        .max(200, 'Maximo 200 caracteres')
        .required('Requerido'),
    members: Yup.array()
        .min(1, 'El grupo debe tener por lo menos 1 miembro')
        .required('Requerido'),
    isActive: Yup.bool()
        .required('Requerido')
});