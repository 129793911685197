import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserCreators, selectIsLoading, selectUser } from 'redux/UserRedux';
import { selectRoles } from 'redux/RoleRedux';
import { selectCompanyLocations } from 'redux/CompanyLocationRedux';
import { selectFullEntityTypes } from 'redux/EntityTypeRedux';
import { selectExternalEntities } from 'redux/ExternalEntityRedux';

export const mapStateToProps = state => ({
  roles: selectRoles(state),
  user: selectUser(state),
  companyLocations: selectCompanyLocations(state),
  isLoading: selectIsLoading(state),
  entityTypes: selectFullEntityTypes(state),
  externalEntities: selectExternalEntities(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUser: UserCreators.userGetRequest,
      createUser: UserCreators.userCreateRequest,
      updateUser: UserCreators.userUpdateRequest
    },
    dispatch
  );

export const hocComponentName = WrappedComponent => {
  const hocComponent = class HocComponent extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  hocComponent.propTypes = {
    roles: PropTypes.array,
    user: PropTypes.object,
    getUser: PropTypes.func,
    createUser: PropTypes.func,
    updateUser: PropTypes.func
  };

  return hocComponent;
};

export default WrapperComponent =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hocComponentName(WrapperComponent));
