import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Modal from 'components/Modal';
import ButtonToolTip from 'components/ButtonToolTip';
import {
	PrintOutlined as PrintIcon,
	CloseOutlined,
	SaveOutlined,
	RemoveRedEyeOutlined,
	RefreshOutlined
} from '@material-ui/icons';
import ChildTicketForm from 'views/Tickets/Form';

import FileInput from "components/FileInput";

// UI Components
import {
	withStyles,
	AppBar,
	Tab,
	Tabs,
	Grid,
	InputLabel,
	Button,
	Typography,
	CircularProgress,
	TextField as CoreTextField,
	List,
	ListItem,
	Divider,
	ListItemText,
	Avatar,
	ListItemAvatar,
	IconButton,
	ListItemSecondaryAction,
	Card,
	CardHeader,
	CardContent,
	Hidden
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import {
	Portlet,
	PortletContent,
	RichEditor,
	Select,
	Table,
	TabPanel,
	MultiSelectAsync,
	SaveContainer
} from 'components';
import { TextField } from 'formik-material-ui';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {
	InfoRounded,
	AttachmentRounded,
	HistoryRounded,
	AttachmentOutlined,
	AddCircleOutlineOutlined,
	LinkOutlined,
	ListOutlined
} from '@material-ui/icons';
import { tabProps } from 'components/TabPanel';
import Task from '../Task';

//Services
import { WithTicket, WithNotifications } from 'hoc';
import severitiesService from 'services/severitiesService';
import uploadsService from 'services/uploadsService';
import userService from 'services/userService';
import ticketsService from 'services/ticketsService';
import companyLocationsService from 'services/companyLocationService';
import documentService from 'services/documentService';
import subCategoriesService from 'services/subCategoriesService';
import categoriesService from 'services/categoriesService';
import usersService from 'services/userService';
import lookupSettingService from 'services/lookupSettingService';

//Assets
import styles from './styles';

function TicketForm(props) {
	const emptyItem = {
		subject: props.title || '',
		detail: '',
		discussion: '',
		categoryId: '',
		subCategoryId: '',
		assignedTo: '',
		subscribers: [],
		attachments: [],
		history: [],
		severityId: 1,
		statusId: 1,
		parent: null,
		childs: []
	};

	const {
		classes,
		match: {
			params: { id: ticketId }
		},
		title,
		relationship,
	} = props;

	const [editing, setEditing] = useState(false);
	const [subscribers, setSubscribers] = useState([]);
	const [tab, setTab] = useState(0);
	const [severities, setSeverities] = useState([]);
	const [companyLocations, setCompanyLocations] = useState([]);
	const [ticket, setTicket] = useState(emptyItem);
	const [attachments, setAttachments] = useState([]);
	const [history, setHistory] = useState([]);
	const [historySelected, setHistorySelected] = useState(0);
	const [loading] = useState(false);
	const [showRelationModalOpen, setShowRelationModalOpen] = useState(false);
	const [showTicketFormModal, setShowTicketFormModal] = useState(false);
	const [relationType, setRelationType] = useState(1);
	const [ticketOption, setTicketOption] = useState(2);
	const [tickets, setTickets] = useState([]);
	const [settingList, setSettingList] = useState([]);
	const [modalTitle, setModalTitle] = useState('');
	const [ticketUpdate, setTicketUpdate] = useState(new Date().toISOString());
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [users, setUsers] = useState([]);
	const [settings, setSettings] = useState([]);
	const [user] = useState(JSON.parse(localStorage.appUser));
	const [id, setId] = useState(props.id);
	const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
	const [workItemTasks, setWorkItemTasks] = useState([]);
	const [addFromNew, setAddFromNew] = useState(false);
	const [ticketSelected, setTicketSelected] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [uploading, setUploading] = useState(false);

	const attachmentsColumns = [
		{
			title: 'Nombre',
			field: 'name',
			render: rowData => (
				<a
					download={rowData.name}
					title={rowData.name}
					href={getAttachmentUrl(rowData)}
				>
					{rowData.name}
				</a>
			)
		},
		{
			title: 'Tamaño',
			field: 'size',
			render: rowData => <span>{formatSize(rowData.size)}</span>
		},
		{
			title: 'Responsable',
			field: 'createdBy',
			render: row => {
				const responsible = users.find(x => x.id == row.createdBy);
				return <span>{responsible ? responsible.displayName : ''}</span>;
			}
		},
		{
			title: 'Fecha',
			field: 'createdDate',
			render: rowData => (
				<span>
					{Moment(rowData.createdDate)
						.local()
						.format('DD/MM/YYYY HH:mm A')}
				</span>
			)
		}
	];

	const statuses = [
		{ value: 1, label: 'Inicial' },
		{ value: 2, label: 'En progreso' },
		{ value: 3, label: 'Finalizado' }
	];

	const relationTypes = [
		{ value: 1, label: 'Ticket hijo' },
		{ value: 2, label: 'Ticket padre' }
	];

	const ticketOptions = [
		{ value: 1, label: 'Ticket existente' },
		{ value: 2, label: 'Ticket nuevo' }
	];

	const getSubCategories = () => {
		let selectSubCategories = [...subCategories];

		if (user.entityTypeId != 1) {
			selectSubCategories = selectSubCategories.filter(x => x.isPublic);
		}

		if (selectedCategory) {
			selectSubCategories = selectSubCategories.filter(x => x.categoryId == selectedCategory);
		}

		return selectSubCategories.map(i => ({ value: i.id, label: i.code, isActive: i.isActive }));
	};

	const init = async () => {
		categoriesService.getAll().then(response => {
			setCategories(response && response.data ? response.data.data : []);
		});

		subCategoriesService.getAll().then(response => {
			setSubCategories(response && response.data ? response.data.data : []);
		});

		usersService.getAll().then(response => {
			setUsers(response && response.data ? response.data.data : []);
		});

		usersService.getSettings().then(response => {
			setSettings(response && response.data ? response.data.data : []);
		});

		severitiesService.getAll().then(response => {
			setSeverities(response && response.data ? response.data.data : []);
		});

		companyLocationsService.getAll().then(response => {
			setCompanyLocations(response && response.data ? response.data.data : []);
		});

		lookupSettingService.getAll().then(response => {
			setSettingList(response && response.data ? response.data.data : []);
		});

		await loadTicketFromUrl();

		if (user.companyLocationId) {
			emptyItem.companyLocationId = user.companyLocationId;
		}

		if (relationship) {
			// eslint-disable-next-line
			debugger;

			if (relationship.relationType == 1) {
				// this new ticket will be the parent of the other one.
				setTicket({ ...ticket, parent: relationship.ticket });
			} else {
				// This new ticket will be the child of the other one
				setTicket({
					...ticket,
					childs: ticket.childs.concat(relationship.ticket)
				});
			}
		}
	};

	const cleanScreen = () => {
		setTicket({ ...emptyItem });
		setAttachments([]);
		setHistory([]);
		setHistorySelected(0);
		setTab(0);
		setEditing(false);
		setId(null);
		setSelectedCategory(null);
		setUploading(false);
	};

	const loadTicketFromUrl = async () => {
		if (!ticketId || title != null) {
			setTicket({ ...emptyItem });
			return;
		}

		setId(ticketId);
		setEditing(true);

		try {
			const response = await ticketsService.getById(ticketId);
			if (
				!response.data ||
				response.data.status == 'Fail' ||
				!response.data.data
			) {
				props.history.push('/');
				return;
			}

			let loadedTicket = response.data.data;
			loadedTicket.discussion = '';

			setTicket(loadedTicket);
			setSelectedSubCategoryId(loadedTicket.subCategoryId);
			setTicketUpdate(new Date().toISOString());
			setAttachments(loadedTicket.attachments);
			setSubscribers(loadedTicket.subscribersElements.map(x => ({
				value: x.userId,
				label: x.user
			})));

			triggerWorkItemsTasksRefesh(loadedTicket.id, loadedTicket.subCategoryId);
			getTicketHistory();
		}
		catch (e) {
			// eslint-disable-next-line
			console.log(e);
		}
	};

	const getTicketHistory = () => {
		if (!ticketId) {
			return;
		}

		ticketsService.getTicketHistory(ticketId).then(response => {
			if (
				!response.data ||
				response.data.status == 'Fail' ||
				!Array.isArray(response.data.data)
			) {
				props.history.push('/');
				return;
			}

			setHistory(response.data.data);
		});
	};

	const formatSize = bytes => {
		var i = Math.floor(Math.log(bytes) / Math.log(1024));
		return (
			(!bytes && '0 Bytes') ||
			(bytes / Math.pow(1024, i)).toFixed(2) +
			' ' +
			['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][i]
		);
	};

	const getAttachmentUrl = attachment => {
		if (attachment.isTemp) {
			return (
				window.env.API_URL +
				`files/C${settings.companyId}/${attachment.filePath}`
			);
		}

		return (
			window.env.API_URL +
			`attachments/C${settings.companyId}/T${id}/${attachment.filePath}`
		);
	};

	const onFileSelected = async files => {
		if (!files || !files.length) {
			return;
		}

		setUploading(true);

		const response = await uploadsService.uploadTempAttachment(files[0]).catch(() => {
			setUploading(false);
			props.addNotification({
				message: 'Error agregando archivo',
				options: { variant: 'error' }
			});
		});

		if (response.status != 200) {
			setUploading(false);
			return;
		}

		if (response.data.status === 'Fail') {
			setUploading(false);
			props.addNotification({
				message: response.data.data,
				options: { variant: 'warning' }
			});
			return;
		}

		setAttachments([...attachments, response.data.data]);
		props.addNotification({
			message: "Archivo agregado",
			options: { variant: 'success' }
		});
		setUploading(false);
	};

	const saveTicket = async values => {
		let completeTicket = {
			...values,
			attachments: attachments || [],
			tasks: workItemTasks,
			subscribers: subscribers.map(x => x.value),
			parent: ticket.parent,
			childs: ticket.childs
		};

		if (editing) {
			await updateTicket(completeTicket);
		} else {
			await createTicket(completeTicket);
		}
	};

	const createTicket = async ticket => {
		try {
			const response = await ticketsService.create(ticket);

			if (response.status != 200) {
				props.addNotification({
					message: 'Error guardando',
					options: { variant: 'error' }
				});
				return;
			}

			if (response.data.status == 'Fail') {
				props.addNotification({
					message: response.data.data.error,
					options: { variant: 'error' }
				});
				return;
			}

			props.addNotification({
				message: 'Ticket creado',
				options: { variant: 'success' }
			});

			if (title != null) {
				props.onCreated(response.data.data);
			} else {
				props.history.push(`/ticket/${response.data.data.id}`);
			}
		} catch (e) {
			props.addNotification({
				message: 'Error guardando',
				options: { variant: 'error' }
			});
		}
	};

	const updateTicket = async ticket => {
		try {
			ticket.id = id;
			const response = await ticketsService.update(ticket);

			if (response.status != 200) {
				props.addNotification({
					message: 'Error guardando',
					options: { variant: 'error' }
				});
				return;
			}

			if (response.data.status == 'Fail') {
				props.addNotification({
					message: response.data.data.error,
					options: { variant: 'error' }
				});
				return;
			}

			props.addNotification({
				message: 'Ticket actualizado',
				options: { variant: 'success' }
			});

			loadTicketFromUrl();
		} catch (e) {
			props.addNotification({
				message: 'Error guardando',
				options: { variant: 'error' }
			});
		}
	};

	const follow = async () => {
		try {
			const response = await ticketsService.follow(id);

			if (response.status != 200) {
				props.addNotification({
					message: 'Error al seguir ticket',
					options: { variant: 'error' }
				});
				return;
			}

			if (response.data.status == 'Fail') {
				props.addNotification({
					message: response.data.data.error,
					options: { variant: 'error' }
				});
				return;
			}

			props.addNotification({
				message: 'Siguiendo ticket',
				options: { variant: 'success' }
			});

			loadTicketFromUrl();
		} catch (e) {
			props.addNotification({
				message: 'Error al seguir ticket',
				options: { variant: 'error' }
			});
		}
	};

	const addRelationship = async newTicket => {
		if (ticketOption == 2) {
			if (relationType == 2) {
				if (ticket.parentId) {
					props.addNotification({
						message: 'El ticket solo puede tener un padre',
						options: { variant: 'warning' }
					});
					return;
				}
			}

			setShowTicketFormModal(true);
			setAddFromNew(true);
			setTicketOption(1);
			return;
		}

		if ((!addFromNew && !ticketSelected) || (addFromNew && !newTicket)) {
			props.addNotification({
				message: 'Necesitas selecionar ticket',
				options: { variant: 'warning' }
			});
			return;
		}

		let newRelation = tickets.find(x => x.id == ticketSelected);

		if (addFromNew) {
			newRelation = newTicket;
		}

		if (relationType == 1) {
			setShowRelationModalOpen(false);
			setTicket({ ...ticket, childs: ticket.childs.concat(newRelation) });
		} else {
			if (ticket.parentId) {
				props.addNotification({
					message: 'El ticket solo puede tener un padre',
					options: { variant: 'warning' }
				});
				return;
			}

			setShowRelationModalOpen(false);
			setTicket({ ticket: { ...ticket, parent: newRelation } });
		}

		setTicketSelected(null);
		setAddFromNew(false);
		setShowRelationModalOpen(false);
		setShowTicketFormModal(false);
	};

	const deleteParent = () => {
		setTicket({ ticket: { ticket, parent: null } });
	};

	const deleteChild = id => {
		setTicket({ ...ticket, childs: ticket.childs.filter(x => x.id != id) });
	};

	const getFieldName = field => {
		switch (field) {
			case 'Subject':
				return 'Asunto';
			case 'Detail':
				return 'Detalles';
			case 'Responsible':
				return 'Responsable';
			case 'Severity':
				return 'Prioridad';
			case 'Status':
				return 'Estatus';
			case 'SubCategory':
				return 'Sub Categoria';
			case 'SubCategoryLocation':
				return 'Sucursal de sub categoria';
			case 'Parent':
				return 'Ticket padre';
			case 'Relationships':
				return 'Hijos';
			case 'Attachment':
				return 'Archivos adjuntos';
		}
	};

	const onParentClick = () => {
		if (!ticket.parent) {
			return;
		}

		window.open(window.location.origin + '/ticket/' + ticket.parent.id);
	};

	const onChildClick = id => {
		window.open(window.location.origin + '/ticket/' + id);
	};

	const onTicketRelationsOpen = () => {
		if (tickets && tickets.length) {
			return;
		}

		ticketsService.getsimpleTickets().then(response => {
			if (
				!response.data ||
				response.data.status == 'Fail' ||
				!response.data.data
			) {
				return;
			}

			setTickets(response.data.data);
		});
	};

	const onGetReport = () => {
		documentService.getWorkItemReport(ticket.id);
	};

	const getRelationIds = () => {
		let ids = [];

		if (!ticket) {
			return ids;
		}

		if (ticket.parent) {
			ids.push(ticket.parent.id);
		}

		ticket.childs.forEach(i => {
			ids.push(i.id);
		});

		return ids;
	};

	const renderTabs = () => {
		return (
			<div>
				<AppBar position="static" color="default">
					<Tabs
						value={tab}
						onChange={(_, tab) => setTab(tab)}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="auto"
					>
						<Tab icon={<InfoRounded />} {...tabProps(0)} />
						<Tab icon={<AttachmentRounded />} {...tabProps(1)} />
						{
							user.entityTypeId === 1 &&
							<Tab icon={<LinkOutlined />} {...tabProps(2)} />
						}
						{editing && <Tab icon={<HistoryRounded />} {...tabProps(3)} />}
						{ticket && ticket.id > 0 && !ticket.isCreatedFromTask && (
							<Tab icon={<ListOutlined />} {...tabProps(4)} />
						)}
					</Tabs>
				</AppBar>
				<TabPanel value={tab} index={0} boxStyle={{ paddingTop: '0px' }}>
					{renderForm()}
				</TabPanel>
				<TabPanel value={tab} index={1}>
					{renderAttachments()}
				</TabPanel>
				{
					user.entityTypeId === 1 &&
					<TabPanel value={tab} index={2}>
						{renderLinks()}
					</TabPanel>
				}
				<TabPanel value={tab} index={3}>
					{renderHistory()}
				</TabPanel>
				<TabPanel value={tab} index={4}>
					{renderTasks()}
				</TabPanel>
			</div>
		);
	};

	const renderForm = () => {
		const selectCategories =
			user.entityTypeId === 1
				? categories
					.filter(i => i.isActive)
					.map(i => ({ value: i.id, label: i.code, isActive: i.isActive }))
				: categories
					.filter(i => i.isActive && i.isPublic)
					.map(i => ({ value: i.id, label: i.code, isActive: i.isActive }));
		const selectUsers = users
			.filter(i => i.isActive)
			.map(i => ({ value: i.id, label: i.displayName, isActive: i.isActive }));
		const selectSeverities = severities
			.filter(i => i.isActive)
			.map(i => ({ value: i.id, label: i.code, isActive: i.isActive }));
		const selectCompanyLocations = companyLocations
			.filter(i => i.isActive)
			.map(i => ({ value: i.id, label: i.code, isActive: i.isActive }));

		const selectSubCategories = getSubCategories();

		let status = null;
		let responsible = null;
		let createdBy = null;

		if (ticket) {
			status = statuses.find(x => x.value == ticket.statusId);
			responsible = users.find(x => x.id == ticket.assignedTo);
			createdBy = users.find(x => x.id == ticket.createdBy);
		}

		return (
			<Formik
				key={ticketUpdate}
				enableReinitialize
				initialValues={editing ? ticket : emptyItem}
				validationSchema={validationSchema}
				onSubmit={async (values, { setSubmitting }) => {
					await saveTicket(values);
					setSubmitting(false);
				}}
				render={({ submitForm, isSubmitting, values }) => (
					<SaveContainer saveContent={submitForm}>
						<Form>
							<Grid container className={classes.root} spacing={1}>
								<Hidden mdDown>
									<Grid item xsOnly className={classes.item} md={7} lg={8}>
										<Field
											type="text"
											label="Asunto"
											name="subject"
											margin="normal"
											component={TextField}
											fullWidth
											autoFocus
										/>
									</Grid>
								</Hidden>
								<Grid
									container
									direction="row"
									justify="center"
									alignItems="center"
									xs={12}
									md={5}
									lg={4}
								>
									<Grid item xs={12} md={7} lg={7}>
										<Button
											size="small"
											component="button"
											variant="outlined"
											color="default"
											className={classes.button}
											onClick={submitForm}
											disabled={isSubmitting}
											style={{ marginLeft: '0px' }}
										>
											<SaveOutlined className={classes.leftIcon} />
											<Typography
												style={{ fontSize: '10px', marginLeft: '3px' }}
											>
												{editing ? 'Guardar' : 'Crear'}
											</Typography>
										</Button>
										{ticket && ticket.id > 0 && (
											<Button
												size="small"
												component="button"
												variant="text"
												color="default"
												className={classes.button}
												onClick={follow}
											>
												<RemoveRedEyeOutlined className={classes.leftIcon} />
												<Typography
													style={{ fontSize: '10px', marginLeft: '3px' }}
												>
													Seguir
												</Typography>
											</Button>
										)}

										{ticket && ticket.id > 0 && (
											<ButtonToolTip
												title="Refrescar"
												onClick={loadTicketFromUrl}
											>
												<RefreshOutlined />
											</ButtonToolTip>
										)}
										{ticket && ticket.id > 0 && (
											<ButtonToolTip
												title="Imprimir"
												onClick={onGetReport}
											>
												<PrintIcon />
											</ButtonToolTip>
										)}
									</Grid>
									<Grid item xs={12} md={5} lg={5}>
										<Typography style={{ fontSize: '10px', marginLeft: '3px' }}>
											Fecha de Creación:{' '}
											{ticket && ticket.createdDate &&
												Moment(ticket.createdDate)
													.local()
													.fromNow()}
										</Typography>
										<Typography style={{ fontSize: '10px', marginLeft: '3px' }}>
											Fecha de modificación:{' '}
											{ticket && ticket.modifiedDate &&
												Moment(ticket.modifiedDate)
													.local()
													.fromNow()}
										</Typography>
									</Grid>
								</Grid>
								<Hidden smUp>
									<Grid item xs={12} className={classes.item}>
										<Field
											type="text"
											label="Asunto"
											name="subject"
											margin="normal"
											component={TextField}
											fullWidth
											autoFocus
										/>
									</Grid>
								</Hidden>
								{!editing && (
									<Grid item xs={12} md={4} className={classes.itemSpacing}>
										<Field
											name="companyLocationId"
											render={({ field, form }) => {
												return (
													<div
														className={classNames(classes.selectRoot, {
															error:
																form.touched[field.name] &&
																form.errors[field.name]
														})}
													>
														<InputLabel>Sucursal</InputLabel>
														<Select
															isDisabled={user.entityTypeId !== 1}
															key={id}
															isSearchable
															value={field.value}
															onChange={selected =>
																form.setFieldValue(field.name, selected.value)
															}
															options={selectCompanyLocations}
															onMenuClose={() =>
																form.setFieldTouched(field.name, true, true)
															}
															onMenuOpen={() =>
																form.setFieldTouched(field.name, false)
															}
														/>
														{form.touched[field.name] &&
															form.errors[field.name] && (
																<p className="MuiFormHelperText-root Mui-error">
																	{form.errors[field.name]}
																</p>
															)}
													</div>
												);
											}}
										/>
									</Grid>
								)}
								<Grid item xs={12} md={4} className={classes.itemSpacing}>
									<Field
										name="categoryId"
										render={({ field, form }) => {
											return (
												<div
													className={classNames(classes.selectRoot, {
														error:
															form.touched[field.name] &&
															form.errors[field.name]
													})}
												>
													<InputLabel>Categoria</InputLabel>
													<Select
														key={field.value}
														isSearchable
														value={field.value}
														onChange={selected => {
															form.setFieldValue(field.name, selected.value);
															form.setFieldValue('subCategoryId', '');
															setSelectedCategory(selected.value);
														}}
														options={selectCategories}
														onMenuClose={() =>
															form.setFieldTouched(field.name, true, true)
														}
														onMenuOpen={() =>
															form.setFieldTouched(field.name, false)
														}
													/>
													{form.touched[field.name] &&
														form.errors[field.name] && (
															<p className="MuiFormHelperText-root Mui-error">
																{form.errors[field.name]}
															</p>
														)}
												</div>
											);
										}}
									/>
								</Grid>
								<Grid item xs={12} md={4} className={classes.itemSpacing}>
									<Field
										name="subCategoryId"
										render={({ field, form }) => {
											return (
												<div
													className={classNames(classes.selectRoot, {
														error:
															form.touched[field.name] &&
															form.errors[field.name]
													})}
												>
													<InputLabel>SubCategoria</InputLabel>
													<Select
														key={field.value}
														isSearchable
														isDisabled={
															!values.categoryId || !selectSubCategories.length
														}
														value={field.value}
														onChange={selected => {
															form.setFieldValue(field.name, selected.value);
															setSelectedSubCategoryId(selected.value);
														}}
														options={selectSubCategories}
														onMenuClose={() =>
															form.setFieldTouched(field.name, true, true)
														}
														onMenuOpen={() =>
															form.setFieldTouched(field.name, false)
														}
													/>
													{form.touched[field.name] &&
														form.errors[field.name] && (
															<p className="MuiFormHelperText-root Mui-error">
																{form.errors[field.name]}
															</p>
														)}
												</div>
											);
										}}
									/>
								</Grid>
								{editing && (
									<Grid
										item
										xs={12}
										md={4}
										className={classes.itemSpacing}
										alignItems="flex-end"
									>
										<Field
											name="statusId"
											render={({ field, form }) => {
												return (
													<div className={classes.selectRoot}>
														<InputLabel>Estado</InputLabel>
														{form.values.assignedTo != user.id ? (
															<CoreTextField
																disabled
																value={status.label}
																style={{ width: '100%' }}
															/>
														) : (
															<Select
																key={id}
																value={field.value}
																onChange={selected =>
																	form.setFieldValue(field.name, selected.value)
																}
																options={statuses}
																onMenuClose={() =>
																	form.setFieldTouched(field.name, true, true)
																}
																onMenuOpen={() =>
																	form.setFieldTouched(field.name, false)
																}
															/>
														)}
													</div>
												);
											}}
										/>
									</Grid>
								)}
								{editing && (
									<Grid item xs={12} md={4} className={classes.itemSpacing}>
										<Field
											name="assignedTo"
											render={({ field, form }) => {
												return (
													<div
														className={classNames(classes.selectRoot, {
															error:
																form.touched[field.name] &&
																form.errors[field.name]
														})}
													>
														<InputLabel>Responsable</InputLabel>
														{editing &&
															(ticket.assignedTo == user.id || [1, 3, 1011].includes(user.roleId)) ? (
															<Select
																key={id}
																isSearchable
																value={field.value}
																onChange={selected =>
																	form.setFieldValue(field.name, selected.value)
																}
																options={selectUsers}
																onMenuClose={() =>
																	form.setFieldTouched(field.name, true, true)
																}
																onMenuOpen={() =>
																	form.setFieldTouched(field.name, false)
																}
															/>
														) : (
															<CoreTextField
																disabled
																value={
																	responsible ? responsible.displayName : ''
																}
																style={{ width: '100%' }}
															/>
														)}
														{form.touched[field.name] &&
															form.errors[field.name] && (
																<p className="MuiFormHelperText-root Mui-error">
																	{form.errors[field.name]}
																</p>
															)}
													</div>
												);
											}}
										/>
									</Grid>
								)}
								{
									user.entityTypeId === 1 &&
									<Grid item xs={12} md={4} className={classes.itemSpacing}>
										<Field
											name="subscribers"
											render={({ field, form }) => {
												return (
													<div
														className={classNames(classes.selectRoot, {
															error:
																form.touched[field.name] &&
																form.errors[field.name]
														})}
													>
														<InputLabel>Interesados</InputLabel>
														<MultiSelectAsync
															cacheOptions
															loadOptions={async value => {
																if (!value) return [];
																const res = await userService.search(value);
																return res.data &&
																	res.data.data &&
																	Array.isArray(res.data.data)
																	? res.data.data
																	: [];
															}}
															closeMenuOnSelect
															onChange={selected => {
																form.setFieldValue(field.name, selected);
																setSubscribers(selected);
															}}
															value={subscribers}
															defaultOptions={subscribers}
															onMenuClose={() =>
																form.setFieldTouched(field.name, true, true)
															}
															onMenuOpen={() =>
																form.setFieldTouched(field.name, false)
															}
														// className="basic-multi-select"
														// classNamePrefix="select"
														/>
														{form.touched[field.name] &&
															form.errors[field.name] && (
																<p className="MuiFormHelperText-root Mui-error">
																	{form.errors[field.name]}
																</p>
															)}
													</div>
												);
											}}
										/>
									</Grid>
								}
								<Grid item xs={12} lg={4} className={classes.itemSpacing}>
									<Field
										name="severityId"
										render={({ field, form }) => {
											return (
												<div
													className={classNames(classes.selectRoot, {
														error:
															form.touched[field.name] &&
															form.errors[field.name]
													})}
												>
													<InputLabel>Severidad</InputLabel>
													<Select
														key={id}
														isSearchable
														value={field.value}
														onChange={selected =>
															form.setFieldValue(field.name, selected.value)
														}
														options={selectSeverities}
														onMenuClose={() =>
															form.setFieldTouched(field.name, true, true)
														}
														onMenuOpen={() =>
															form.setFieldTouched(field.name, false)
														}
													/>
													{form.touched[field.name] &&
														form.errors[field.name] && (
															<p className="MuiFormHelperText-root Mui-error">
																{form.errors[field.name]}
															</p>
														)}
												</div>
											);
										}}
									/>
								</Grid>

								<Grid item xs={12} lg={4} className={classes.itemSpacing}>
									<Field
										name="createdBy"
										render={() => {
											return (
												<div className={classNames(classes.selectRoot)}>
													<InputLabel>Creado por</InputLabel>
													<CoreTextField
														disabled
														value={createdBy ? createdBy.displayName : ''}
														style={{ width: '100%' }}
													/>
												</div>
											);
										}}
									/>
								</Grid>

								<Grid item xs={12} lg={4} className={classes.itemSpacing}>
									<Field
										name="deparmentName"
										render={() => {
											return (
												<div className={classNames(classes.selectRoot)}>
													<InputLabel>Departamento</InputLabel>
													<CoreTextField
														disabled
														value={
															ticket && ticket.deparmentName
																? ticket.deparmentName
																: ''
														}
														style={{ width: '100%' }}
													/>
												</div>
											);
										}}
									/>
								</Grid>

								<Grid item xs={12} lg={4} className={classes.itemSpacing}>
									<Field
										name="groupName"
										render={() => {
											return (
												<div className={classNames(classes.selectRoot)}>
													<InputLabel>Grupo de trabajo</InputLabel>
													<CoreTextField
														disabled
														value={
															ticket && ticket.deparmentName && ticket.groupName
																? ticket.groupName
																: ''
														}
														style={{ width: '100%' }}
													/>
												</div>
											);
										}}
									/>
								</Grid>

								<Grid item xs={12} className={classes.item}>
									<Field
										name="detail"
										render={({ field, form }) => (
											<div
												className={classNames(classes.selectRoot, {
													error:
														form.touched[field.name] && form.errors[field.name]
												})}
											>
												{field.value && <InputLabel>Detalles</InputLabel>}
												<RichEditor
													placeholder="Descripción detallada"
													value={field.value}
													onChange={value =>
														form.setFieldValue(field.name, value)
													}
													onBlur={() =>
														form.setFieldTouched(field.name, true, true)
													}
												/>
												{form.touched[field.name] &&
													form.errors[field.name] && (
														<p className="MuiFormHelperText-root Mui-error">
															{form.errors[field.name]}
														</p>
													)}
											</div>
										)}
									/>
								</Grid>
								{editing && (
									<Grid item xs={12} className={classes.item}>
										<Typography style={{ fontSize: '17px' }}>
											Discusión
										</Typography>
										<Divider />
										<Grid item xs={12} className={classes.item}>
											<Field
												name="discussion"
												render={({ field, form }) => (
													<RichEditor
														className="discussion-editor"
														placeholder="Agregar mensaje a discusión"
														value={field.value}
														onChange={value =>
															form.setFieldValue(field.name, value)
														}
														onBlur={() =>
															form.setFieldTouched(field.name, true, true)
														}
														minHeight={'50px'}
													/>
												)}
											/>
											<br />
											{renderDiscussionMessages()}
										</Grid>
									</Grid>
								)}
								{editing && (
									<Grid item xs={12} className={classes.item}>
										<Typography style={{ fontSize: '17px' }}>
											Codigo QR
										</Typography>
										<Divider />
										<Grid item xs={6} className={classes.item}>
											<img src={`${ticket.qrCode}`} height="200" width="200" />
										</Grid>
									</Grid>
								)}
							</Grid>
						</Form>
					</SaveContainer>
				)}
			/>
		);
	};

	const triggerWorkItemsTasksRefesh = (ticketId, subCategoryId) => {
		setWorkItemTasks([]);
		props.getSubCategoryTasks(ticketId, subCategoryId);
	};

	const renderTasks = () => {
		return (
			<React.Fragment>
				{selectedSubCategoryId ? (
					<Task
						subCategory={selectedSubCategoryId}
						workItemId={ticket.id}
						updateWorkItemTasks={workItemTasks => {
							setWorkItemTasks(workItemTasks);
						}}
						isEditable={editing && ticket.assignedTo == user.id}
					/>
				) : (
					<Grid
						container
						spacing={3}
						direction="column"
						justify="center"
						alignItems="center"
						className={classes.columnGrid}
						style={{ display: 'flex' }}
					>
						<Grid>
							<ListOutlined className={classes.giantIcon} />
						</Grid>
						<Grid>
							<Typography style={{ fontSize: '22px' }}>Sin Tareas.</Typography>
						</Grid>
					</Grid>
				)}
			</React.Fragment>
		);
	};

	const renderDiscussionMessages = () => {
		if (!editing || !ticket || !ticket.discussions) {
			return null;
		}

		return (
			<Grid item xs={12} className={classes.discussionMessages}>
				{ticket.discussions.map(i => {
					const creator = users.find(x => x.id == i.createdBy);
					const creatorName = creator
						? creator.displayName
						: 'Usuario inactivo';
					return (
						<Card key={i.order} className={classes.discussionMessage}>
							<CardHeader
								title={creatorName}
								subheader={
									'Comentó ' +
									Moment(i.createdDate)
										.local()
										.fromNow()
								}
							/>
							<Divider />
							<CardContent>
								{renderHistoryDetailField(i.message)}
							</CardContent>
						</Card>
					);
				})}
			</Grid>
		);
	};

	const renderAttachments = () => {
		const limitLabel = (
			<Typography style={{ fontSize: '12px' }}>
				Se permiten archivos adjuntos limite (
				{
					settingList && settingList.length > 0
						? settingList.find(
							x => x.code === 'FileAttachmentSize'
						).detail
						: 0
				}
				) MB.
			</Typography>
		);

		return (
			<React.Fragment>
				<Grid
					container
					spacing={3}
					direction="column"
					justify="center"
					alignItems="center"
					className={classes.columnGrid}
					style={{ display: attachments.length == 0 ? 'flex' : 'none' }}
				>
					<Grid>
						<AttachmentOutlined className={classes.giantIcon} />
					</Grid>
					<Grid>
						<Typography style={{ fontSize: '22px' }}>
							Sin archivos adjuntos
						</Typography>
					</Grid>
					<Grid>
						<FileInput
							key={attachments.length}
							id={`add-file-button-${relationship ? "modal" : "main"}`}
							loading={uploading}
							value={[]}
							onChange={onFileSelected}
						/>
					</Grid>
					{limitLabel}
				</Grid>
				{attachments.length > 0 && (
					<Grid
						container
						spacing={3}
						direction="row"
						justify="center"
						alignItems="center"
					>
						<Table
							key={attachments.length}
							columns={attachmentsColumns}
							items={attachments}
							actions={[
								{
									icon: 'delete',
									tooltip: 'Eliminar',
									onClick: (e, rowData) => {
										if (!attachments)
											return;

										setAttachments(attachments.filter(x => x.id != rowData.id));
									}
								},
								{
									icon: 'add',
									isFreeAction: true,
									tooltip: 'Crear',
									loading: uploading,
									isLoading: uploading,
									disabled: uploading,
									onClick: () => {
										$(`#add-file-button-${relationship ? "modal" : "main"}`).trigger("click");
									}
								}
							]}
							options={{ paging: false }}
						/>
						{limitLabel}
					</Grid>
				)}

			</React.Fragment>
		);
	};

	const renderHistory = () => {
		const historyObj = history.length ? history[historySelected] : null;
		let creator = null;
		if (historyObj) {
			creator = users.find(x => x.id == historyObj.creatorId);
		}

		return (
			<React.Fragment>
				<Grid
					container
					spacing={3}
					direction="column"
					justify="center"
					alignItems="center"
					className={classes.columnGrid}
					style={{
						display: history.length == 0 || users.length == 0 ? 'flex' : 'none'
					}}
				>
					<Grid>
						<HistoryRounded className={classes.giantIcon} />
					</Grid>
					<Grid>
						<Typography style={{ fontSize: '22px' }}>Sin historial</Typography>
					</Grid>
				</Grid>
				{history.length > 0 && users.length > 0 && (
					<Grid
						container
						spacing={3}
						direction="row"
						justify="space-between"
						alignItems="center"
					>
						<Grid item xs={12}>
							<h1>Historial</h1>
						</Grid>
						<Grid item xs={12}>
							<hr />
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							lg={4}
							alignItems="flex-start"
							className={classes.historyList}
						>
							<List>
								{history.map((i, index) => {
									const creator = users.find(x => x.id == i.creatorId);
									return (
										<React.Fragment key={index}>
											<ListItem
												selected={historySelected === index}
												alignItems="flex-start"
												onClick={() => setHistorySelected(index)}
												className={classes.historyItem}
											>
												<ListItemAvatar>
													<Avatar
														alt={
															creator ? creator.displayName : 'Administrador'
														}
														src="/images/avatars/avatar_generic_0.png"
													/>
												</ListItemAvatar>
												<ListItemText
													primary={
														(creator ? creator.displayName : 'Administrador') +
														' ' +
														(i.action == 'add'
															? 'creó el ticket'
															: 'modificó el ticket')
													}
													secondary={
														<React.Fragment>
															<Typography
																component="span"
																variant="body2"
																className={classes.inline}
																color="textPrimary"
															>
																{Moment(i.date)
																	.local()
																	.fromNow()}
															</Typography>
														</React.Fragment>
													}
												/>
											</ListItem>
											<Divider variant="inset" component="li" />
										</React.Fragment>
									);
								})}
							</List>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							lg={8}
							alignItems="flex-start"
							className={classes.historyDetails}
						>
							{historyObj && (
								<React.Fragment>
									<Grid container xs={12}>
										<List className={classes.historyTitle}>
											<React.Fragment>
												<ListItem alignItems="flex-start">
													<ListItemAvatar>
														<Avatar
															alt={
																creator ? creator.displayName : 'Administrador'
															}
															src="/images/avatars/avatar_generic_0.png"
														/>
													</ListItemAvatar>
													<ListItemText
														primary={
															(creator
																? creator.displayName
																: 'Administrador') +
															' ' +
															(historyObj.action == 'add'
																? 'creó el ticket'
																: 'modificó el ticket')
														}
														secondary={
															<React.Fragment>
																<Typography
																	component="span"
																	variant="body2"
																	className={classes.inline}
																	color="textPrimary"
																>
																	{Moment(historyObj.date)
																		.local()
																		.fromNow()}
																</Typography>
															</React.Fragment>
														}
													/>
												</ListItem>
												<Divider variant="inset" component="li" />
											</React.Fragment>
										</List>
									</Grid>
									<List>
										{historyObj.historyItems.map(i => {
											const hasOld = i.oldValue != null;
											return (
												<React.Fragment>
													<ListItem alignItems="flex-start">
														<ListItemText
															primary={getFieldName(i.fieldName)}
															secondary={
																<React.Fragment>
																	{hasOld && (
																		<div className={classes.oldDiv}>
																			{i.fieldName != 'Detail' ? (
																				<strike>{i.oldValueName}</strike>
																			) : (
																				<strike>
																					{renderHistoryDetailField(
																						i.oldValue
																					)}
																				</strike>
																			)}
																		</div>
																	)}
																	{i.newValue && (
																		<div className={classes.newDiv}>
																			{i.fieldName == 'Attachment'
																				? i.newValue
																					.replace('Add', 'Agregó archivo: ')
																					.replace(
																						'Delete',
																						'Eliminó archivo: '
																					)
																				: i.fieldName == 'Detail'
																					? renderHistoryDetailField(
																						i.newValueName
																					)
																					: i.newValueName}
																		</div>
																	)}
																</React.Fragment>
															}
														/>
													</ListItem>
												</React.Fragment>
											);
										})}
									</List>
								</React.Fragment>
							)}
						</Grid>
					</Grid>
				)}
			</React.Fragment>
		);
	};

	const renderTicketFormModal = () => {
		return (
			<Modal
				title="Crear ticket relacionado"
				isOpen={showTicketFormModal}
				onAfterOpen={() => { }}
				onRequestClose={() => {
					setModalTitle('');
					setShowTicketFormModal(false);
					setTicketOption(1);
					setRelationType(1);
				}}
				style={{ width: '95%', height: '85%' }}
			>
				<ChildTicketForm
					title={modalTitle}
					relationship={{ ticket: ticket, relationType }}
					onCreated={newTicket => {
						addRelationship(newTicket);
						getTicketHistory();
					}}
					onCancel={() => setShowTicketFormModal(false)}
				/>
			</Modal>
		);
	};

	const renderRelationShipsModal = () => {
		const relationIds = getRelationIds();
		let ticketsSelect = [...tickets];
		if (id) {
			ticketsSelect = ticketsSelect.filter(x => x.id != id);
		}

		if (relationType == 1) {
			ticketsSelect = ticketsSelect.filter(x => !x.parentId);
		}
		ticketsSelect = ticketsSelect.map(i => ({
			value: i.id,
			label: i.id.toString()
		}));
		ticketsSelect = ticketsSelect.filter(x => !relationIds.includes(x.value));

		let ticketDetails = tickets.find(x => x.id === ticketSelected);

		return (
			<Modal
				isOpen={showRelationModalOpen}
				title="Relacionar Ticket"
				onAfterOpen={onTicketRelationsOpen}
				onRequestClose={() => setShowRelationModalOpen(false)}
				style={{ width: '60%', height: '50%' }}
			>
				<Grid
					container
					className={classes.realtionshipsModalContainer}
					style={{ padding: '25px' }}
				>
					<Grid container justify="center" style={{ marginBottom: '10px' }}>
						<Grid item xs={12} md={6} lg={4}>
							<InputLabel className={classes.inputLabel}>Origen</InputLabel>
							<Select
								value={ticketOption}
								onChange={selected => setTicketOption(selected.value)}
								options={
									!editing
										? ticketOptions.filter(x => x.value == 1)
										: ticketOptions
								}
							/>
						</Grid>
					</Grid>
					<Grid container justify="center" style={{ marginBottom: '10px' }}>
						<Grid item xs={12} md={6} lg={4} justify="center">
							<InputLabel className={classes.inputLabel}>Relación</InputLabel>
							<Select
								value={relationType}
								onChange={selected => setRelationType(selected.value)}
								options={relationTypes}
							/>
						</Grid>
					</Grid>
					{ticketOption == 1 && (
						<React.Fragment>
							<Grid container justify="center" style={{ marginBottom: '10px' }}>
								<Grid item xs={12} md={6} lg={4} justify="center">
									<InputLabel className={classes.inputLabel}>Ticket</InputLabel>
									<Select
										key={ticketOption}
										value={ticketSelected}
										isValueFilter
										onChange={selected => setTicketSelected(selected.value)}
										options={ticketsSelect}
										isSearchable
									/>
								</Grid>
							</Grid>
							<Grid container justify="center" style={{ marginBottom: '10px' }}>
								<Grid item xs={12} md={6} lg={4} justify="center">
									<Typography>
										{ticketDetails &&
											`Ticket #: [${ticketDetails.id}] - [${ticketDetails.subject}]`}
									</Typography>
								</Grid>
							</Grid>
						</React.Fragment>
					)}
					{ticketOption == 2 && (
						<Grid container justify="center" style={{ marginBottom: '10px' }}>
							<Grid item xs={12} md={6} lg={4} justify="center">
								<InputLabel className={classes.inputLabel}>Título</InputLabel>
								<CoreTextField
									value={title}
									onChange={e => setModalTitle(e.target.value)}
									style={{ width: '100%' }}
								/>
							</Grid>
						</Grid>
					)}
					<Grid container justify="center" style={{ marginBottom: '10px' }}>
						<Grid item xs={12} md={6} lg={4} justify="center">
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								onClick={addRelationship}
							>
								Agregar
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Modal>
		);
	};

	const renderLinks = () => {
		return (
			<React.Fragment>
				<Grid
					container
					spacing={3}
					direction="column"
					justify="center"
					alignItems="center"
					className={classes.columnGrid}
					style={{
						display:
							!ticket || (!ticket.parent && (ticket.childs || []).length == 0)
								? 'flex'
								: 'none'
					}}
				>
					<Grid>
						<LinkOutlined className={classes.giantIcon} />
					</Grid>
					<Grid>
						<Typography style={{ fontSize: '22px' }}>Sin relaciones</Typography>
					</Grid>
					<Grid>
						<Button
							variant="contained"
							color="primary"
							className={classes.button}
							onClick={() => setShowRelationModalOpen(true)}
						>
							<AddCircleOutlineOutlined className={classes.leftIcon} />
							Agregar relación
						</Button>
					</Grid>
				</Grid>
				{ticket && (ticket.parent || ticket.childs.length > 0) && (
					<Grid
						container
						spacing={3}
						direction="row"
						justify="space-between"
						alignItems="center"
					>
						<Grid container xs={12} justify="space-between" alignItems="center">
							<h1>Relaciones</h1>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								onClick={() => setShowRelationModalOpen(true)}
							>
								Agregar
							</Button>
						</Grid>
						<Grid item xs={12}>
							<hr />
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							lg={6}
							alignItems="flex-start"
							className={classes.historyList}
						>
							<h3>Ticket padre</h3>
							<List>
								<React.Fragment>
									<Divider component="li" />
									<ListItem
										button
										onClick={onParentClick}
										alignItems="flex-start"
										className={ticket.parent ? classes.historyItem : null}
									>
										<ListItemText
											primary={
												ticket.parent
													? ticket.parent.id + ' - ' + ticket.parent.subject
													: 'Ninguno'
											}
											secondary={
												<Typography
													component="p"
													variant="body2"
													color="textPrimary"
												>
													{ticket.parent
														? 'Creado ' +
														Moment(ticket.parent.lastUpdate)
															.local()
															.fromNow()
														: ''}
												</Typography>
											}
										/>
										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												aria-label="delete"
												onClick={deleteParent}
											>
												<CloseOutlined />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</React.Fragment>
							</List>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							lg={6}
							alignItems="flex-start"
							className={classes.historyDetails}
						>
							<h3>Hijos</h3>
							<React.Fragment>
								<List>
									{ticket.childs.map(i => {
										return (
											<React.Fragment>
												<Divider component="li" />
												<ListItem
													button
													alignItems="flex-start"
													className={classes.historyItem}
													onClick={() => onChildClick(i.id)}
												>
													<ListItemText
														primary={i.id + ' - ' + i.subject}
														secondary={
															<Typography
																component="p"
																variant="body2"
																color="textPrimary"
															>
																{ticket.parent
																	? 'Creado ' +
																	Moment(i.lastUpdate)
																		.local()
																		.fromNow()
																	: ''}
															</Typography>
														}
													/>
													<ListItemSecondaryAction>
														<IconButton
															edge="end"
															aria-label="delete"
															onClick={() => deleteChild(i.id)}
														>
															<CloseOutlined />
														</IconButton>
													</ListItemSecondaryAction>
												</ListItem>
											</React.Fragment>
										);
									})}
								</List>
							</React.Fragment>
						</Grid>
					</Grid>
				)}
			</React.Fragment>
		);
	};

	const renderHistoryDetailField = editorHtml => {
		return <div dangerouslySetInnerHTML={{ __html: editorHtml }} />;
	};

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		if (ticketId) {
			loadTicketFromUrl();
		} else {
			cleanScreen();
		}
	}, [ticketId]);

	let isLoading = loading;

	if (editing && !ticket && !loading) {
		isLoading = true;
	}

	const componentLayout = () => (
		<Portlet className={classes.root}>
			<PortletContent noPadding>
				{isLoading ? <CircularProgress /> : renderTabs()}
				{renderRelationShipsModal()}
				{renderTicketFormModal()}
			</PortletContent>
		</Portlet>
	);

	if (title != null) {
		return componentLayout();
	}

	return (
		<DashboardLayout
			title={
				isLoading
					? ''
					: !editing
						? 'Nuevo ticket'
						: `Detalle de ticket ${ticket.id}`
			}
		>
			<div className={classes.root}>
				<div className={classes.content}>{componentLayout()}</div>
			</div>
		</DashboardLayout>
	);
}

TicketForm.propTypes = {
	classes: PropTypes.object.isRequired,
	id: PropTypes.number,
	title: PropTypes.string,
	relationship: PropTypes.object,
	onCreated: PropTypes.func,
	onBack: PropTypes.func
};

TicketForm.defaultProps = {
	id: null,
	onBack: () => { },
	onCreated: () => { },
	relationship: null,
	title: null
};

const validationSchema = Yup.object().shape({
	subject: Yup.string()
		.max(100, 'Maximo 100 caracteres')
		.required('Titulo requerido'),
	detail: Yup.string().required('Detalles requeridos'),
	categoryId: Yup.number().required('Categoria requerida'),
	subCategoryId: Yup.number().required('Subcategoria requerida'),
	assignedTo: Yup.number().nullable(),
	severityId: Yup.number().required('Severidad es requerida'),
	parentId: Yup.number().nullable()
});

export default WithNotifications(withRouter(WithTicket(withStyles(styles)(TicketForm))));
