import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RoleCreators, selectRoles, selectIsLoading, selectMessageOperation } from "redux/RoleRedux";

export const mapStateToProps = state => ({
	roles: selectRoles(state),
	isLoading: selectIsLoading(state),
	messageOperation: selectMessageOperation(state)
});

export const mapDispatchToProps = dispatch => bindActionCreators({
	getRoles: RoleCreators.getRolesRequest,
	createRole: RoleCreators.createRoleRequest,
	updateRole: RoleCreators.updateRoleRequest,
	deleteRole: RoleCreators.deleteRoleRequest,
	onCleanMessage: RoleCreators.rolesSetMessage
}, dispatch);

export const hocConector = WrappedComponent => {
	const hoc = ({ ...props }) => <WrappedComponent {...props} />;

	hoc.propTypes = {
		/** Get all company roles */
		getRoles: PropTypes.func,
		createRole: PropTypes.func,
		updateRole: PropTypes.func,
		deleteRole: PropTypes.func,
		roles: PropTypes.array
	};

	return hoc;
};

export default Component => connect(mapStateToProps, mapDispatchToProps)(hocConector(Component));