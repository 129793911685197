import EntityBase from '../EntityBase';

class SubCategory extends EntityBase {
  constructor(obj) {
    if (!obj) obj = {};
    super(obj);
    this.code = obj.code ? obj.code : '';
    this.detail = obj.detail ? obj.detail : '';
    this.descriptions = obj.descriptions ? obj.descriptions : '';
    this.categoryId = obj.categoryId ? obj.categoryId : 0;
    this.ownerId = obj.ownerId ? obj.ownerId : 0;
    this.ownerName = obj.ownerName ? obj.ownerName : '';
    this.sla = obj.sla ? obj.sla : 0;
    this.logo = obj.logo ? obj.logo : '';
    this.isPublic = obj.isPublic ? obj.isPublic : false;
    this.userGroupId = obj.userGroupId ? obj.userGroupId : undefined;
  }
}

export default SubCategory;
