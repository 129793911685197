import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  selectChartWorkItemCategories,
  selectChartWorkItemDepartments,
  selectChartWorkItemLocations,
  selectChartWorkItemMonthly,
  ChartWorkItemCreators
} from 'redux/ChartWorkItemRedux';

export const mapStateToProps = state => ({
  chartWorkItemCategories: selectChartWorkItemCategories(state),
  chartWorkItemDepartments: selectChartWorkItemDepartments(state),
  chartWorkItemLocations: selectChartWorkItemLocations(state),
  chartWorkItemMonthly: selectChartWorkItemMonthly(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getChartWorkItemData: ChartWorkItemCreators.chartWorkItemDataRequest
    },
    dispatch
  );

export const hocConector = WrappedComponent => {
  const hoc = ({ ...props }) => <WrappedComponent {...props} />;

  hoc.propTypes = {
    getChartWorkItemData: PropTypes.func
  };

  return hoc;
};

export default Component =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hocConector(Component));
