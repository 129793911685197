import React from "react";

import { withStyles } from "@material-ui/core";

import {
	Button,
	InputLabel,
	FormControlLabel,
	Grid,
	Divider,
	Typography
} from "@material-ui/core";

import classNames from 'classnames';

import { Select, Loader, SaveContainer } from "components";

import { WithGeneralInfo } from "../hoc";

import { Formik, Field, Form } from 'formik';

import { Switch, TextField } from 'formik-material-ui';

import { validationSchema, CompanyLocationModel } from "./resources";

import { withSnackbar } from 'notistack';

import Address from "contract/requests/Address";

import CompanyLocation from "contract/requests/CompanyLocation";

import { injectIntl } from "react-intl";

import styles from "./styles";

class GeneralInfo extends React.Component {

	state = {
		id: null,
		companyLocation: new CompanyLocationModel()
	}

	componentDidMount() {
		const { getCompanyLocation, id } = this.props;
		if (id)
			getCompanyLocation(id);
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.companyLocation !== this.props.companyLocation) {
			let companyLocation = new CompanyLocationModel({ ...nextProps.companyLocation });
			this.setState({ companyLocation: companyLocation }, () => true);
		}

		return true;
	}

	getCities = (form, stateId) => {
		form.setFieldValue('cityId', null);
		this.props.getCitiesByStateId(stateId);
	}

	render() {
		const {
			cities,
			users,
			states,
			classes,
			createCompanyLocation,
			updateCompanyLocation,
			isLoading,
			intl
		} = this.props;

		let { companyLocation } = this.state;

		const usersOptions = users.map(i => ({ value: i.id, label: i.displayName, isActive: i.isActive }));
		const statesOptions = states.map(i => ({ value: i.id, label: i.detail, isActive: i.isActive }));
		const citiesOptions = cities.map(i => ({ value: i.id, label: i.detail, isActive: i.isActive }));

		return (
			<React.Fragment>
				{isLoading
					?
					<Loader />
					:
					(
						<Formik
							enableReinitializex
							initialValues={companyLocation}
							validationSchema={validationSchema(intl)}
							onSubmit={(values) => {
								const address = new Address({ ...values, id: values.addressId });
								let companyLocation = new CompanyLocation(values);
								companyLocation.address = address;
								if (companyLocation.id > 0) {
									companyLocation.addressId = address.addressId;
									updateCompanyLocation(companyLocation);
								}
								else {
									createCompanyLocation(companyLocation);
								}
							}}
							render={({ submitForm, isSubmitting, values }) => (
								<SaveContainer saveContent={submitForm}>
								<Form autoComplete="off">
									<Grid container justify="center" className={classes.root} spacing={2}>
										<Grid item xs={12}  md={6} lg={6}>
											<Grid container spacing={1}>
												<Grid item xs={12} md={12} lg={12}>
													<Typography gutterBottom variant="h6">
														{ intl.formatMessage({id:"catalog.companylocations.label.generalinfo"}) }
													</Typography>
													<Divider variant="fullWidth" />
												</Grid>

												<Grid item xs={12} md={6} lg={6}>
													<Field
														name="code"
														type="text"
														label={intl.formatMessage({id:"catalog.companylocations.field.code"})}
														component={TextField}
														margin="normal"
														fullWidth
														autoFocus
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</Grid>

												<Grid item xs={12} md={12} lg={12}>
													<Field
														name="detail"
														label={intl.formatMessage({id:"catalog.companylocations.field.description"})}
														type="text"
														component={TextField}
														margin="normal"
														fullWidth
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</Grid>

												<Grid item xs={12} md={6} lg={6}>
													<Field
														name="ownerId"
														render={({ field, form }) => {
															return (
																<div
																	className={
																		classNames(
																			classes.selectRoot,
																			{ "error": form.touched[field.name] && form.errors[field.name] }
																		)
																	}
																>
																	<InputLabel className={classes.inputLabel}>{intl.formatMessage({id:"catalog.companylocations.field.owner"})}</InputLabel>
																	<Select
																		isSearchable
																		value={field.value}
																		onChange={(selected) => {
																			form.setFieldValue(field.name, selected.value);
																			form.setFieldValue("ownerName", usersOptions.find(x => x.value === selected.value).label);
																		}}
																		options={usersOptions}
																		onMenuClose={() => form.setFieldTouched(field.name, true, true)}
																		onMenuOpen={() => form.setFieldTouched(field.name, false)}
																	/>
																	{
																		form.touched[field.name] && form.errors[field.name] &&
																		<p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
																	}
																</div>
															);
														}}
													/>
												</Grid>

												<Grid item xs={12} md={12} lg={12}>
													<Typography gutterBottom variant="h6">
														{intl.formatMessage({id:"catalog.companylocations.label.address"})}
													</Typography>
													<Divider variant="fullWidth" />
												</Grid>

												<Grid item xs={12} md={12} lg={12}>
													<Field
														name="address1"
														label={intl.formatMessage({id:"catalog.companylocations.field.addres"}, {itemValue:"1"})}
														type="text"
														component={TextField}
														margin="normal"
														fullWidth
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</Grid>

												<Grid item xs={12} md={12} lg={12}>
													<Field
														name="address2"
														label={intl.formatMessage({id:"catalog.companylocations.field.addres"}, {itemValue:"2"})}
														type="text"
														component={TextField}
														margin="normal"
														fullWidth
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</Grid>

												<Grid item xs={12} md={4} lg={4}>
													<Field
														name="zipCode"
														label={intl.formatMessage({id:"catalog.companylocations.field.zipcode"})}
														type="text"
														component={TextField}
														margin="normal"
														fullWidth
														InputLabelProps={{
															shrink: true,
														}}
														inputProps={{
															maxLength: 5
														}}
													/>
												</Grid>

												<Grid item xs={12} md={4} lg={4}>
													<Field
														name="latitude"
														label={intl.formatMessage({id:"catalog.companylocations.field.latitude"})}
														type="text"
														component={TextField}
														margin="normal"
														fullWidth
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</Grid>

												<Grid item xs={12} md={4} lg={4}>
													<Field
														name="longitude"
														label={intl.formatMessage({id:"catalog.companylocations.field.longitude"})}
														type="text"
														component={TextField}
														margin="normal"
														fullWidth
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</Grid>

												<Grid item xs={12} md={12} lg={12}>
													<Field
														name="businessName"
														label={intl.formatMessage({id:"catalog.companylocations.field.businessname"})}
														type="text"
														component={TextField}
														margin="normal"
														fullWidth
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</Grid>

												<Grid item xs={12} md={6} lg={6}>
													<Field
														name="phoneNumber"
														label={intl.formatMessage({id:"catalog.companylocations.field.phonenumber"})}
														type="text"
														component={TextField}
														margin="normal"
														fullWidth
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</Grid>

												<Grid item xs={12} md={6} lg={6}>
													<Field
														name="taxId"
														label={intl.formatMessage({id:"catalog.companylocations.field.taxid"})}
														type="text"
														component={TextField}
														placeholder="AAAA-1111-A11"
														margin="normal"
														fullWidth
													/>
												</Grid>

												<Grid item xs={12} md={6} lg={6}>

													<Field
														name="stateId"
														render={({ field, form }) => {
															return (
																<div
																	className={
																		classNames(
																			classes.selectRoot,
																			{ "error": form.touched[field.name] && form.errors[field.name] }
																		)
																	}
																>
																	<InputLabel className={classes.inputLabel}>{intl.formatMessage({id:"catalog.companylocations.field.state"})}</InputLabel>
																	<Select
																		isSearchable
																		value={field.value}
																		onChange={(selected) => { form.setFieldValue(field.name, selected.value); this.getCities(form, selected.value); }}
																		options={statesOptions}
																		onMenuClose={() => form.setFieldTouched(field.name, true, true)}
																		onMenuOpen={() => form.setFieldTouched(field.name, false)}
																	/>
																	{
																		form.touched[field.name] && form.errors[field.name] &&
																		<p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
																	}
																</div>
															);
														}}
													/>
												</Grid>

												<Grid item xs={12} md={6} lg={6}>

													<Field
														name="cityId"
														render={({ field, form }) => {
															return (
																<div
																	className={
																		classNames(
																			classes.selectRoot,
																			{ "error": form.touched[field.name] && form.errors[field.name] }
																		)
																	}
																>
																	<InputLabel className={classes.inputLabel}>{intl.formatMessage({id:"catalog.companylocations.field.city"})}</InputLabel>
																	<Select
																		key={`city-${values.stateId}`}
																		isSearchable
																		value={field.value}
																		onChange={(selected) => form.setFieldValue(field.name, selected.value)}
																		options={citiesOptions}
																		onMenuClose={() => form.setFieldTouched(field.name, true, true)}
																		onMenuOpen={() => form.setFieldTouched(field.name, false)}
																	/>
																	{
																		form.touched[field.name] && form.errors[field.name] &&
																		<p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
																	}
																</div>
															);
														}}
													/>
												</Grid>
												<Grid item xs={12} md={6} lg={6}>
													<FormControlLabel
														control={
															<Field label={intl.formatMessage({id:"catalog.companylocations.field.isactive"})} name="isActive" component={Switch} />
														}
														label={intl.formatMessage({id:"catalog.companylocations.field.isactive"})}
													/>
												</Grid>
												<Grid item xs={12}>
													<Button
														variant="contained"
														color="primary"
														disabled={isSubmitting}
														onClick={submitForm}
													>
														{intl.formatMessage({id:"button.label.save"})}
													</Button>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Form>
								</SaveContainer>
							)
							}
						/>
					)
				}
			</React.Fragment>);
	}
}

GeneralInfo.defaultProps = {
	states: [],
	cities: [],
	users: []
};

export default withStyles(styles)(withSnackbar(WithGeneralInfo(injectIntl(GeneralInfo))));