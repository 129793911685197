import React from 'react';

// Material helpers
import { withStyles } from '@material-ui/core';

// Shared components
import { Table } from 'components';

// Component styles
import styles from './styles';

import { injectIntl } from 'react-intl';

class EntityTypeTable extends React.Component {
  state = {
    rowsPerPage: 10,
    page: 0,
    itemToDelete: null,
    columns: [],
    actions: []
  };

  componentDidMount() {
    this.setState({ columns: this.getColumns() });
  }

  getColumns = () => {
    const { intl } = this.props;

    return [
      {
        title: intl.formatMessage({
          id: 'catalog.categories.table.columns.entitytypes'
        }),
        field: 'code'
      }
    ];
  };

  handleSelectedRows = rows => {
    const { postSelectedRows } = this.props;
    postSelectedRows(rows);
  };

  render() {
    const { items } = this.props;
    const { columns } = this.state;

    const options = {
      search: false,
      sorting: false,
      showTitle: false,
      paging: false,
      toolbar: false,
      header: true,
      selection: true,
      headerStyle: {
        zIndex: 0
      }
    };

    return (
      <Table
        styles={{ zIndex: 0 }}
        columns={columns}
        items={items}
        options={options}
        onSelectionChange={rows => this.handleSelectedRows(rows)}
      />
    );
  }
}

export default withStyles(styles)(injectIntl(EntityTypeTable));
