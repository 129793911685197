import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  code: Yup.string()
    .max(100, 'Maximo 100 caracteres')
    .required('Requerido'),
  detail: Yup.string()
    .max(200, 'Maximo 200 caracteres')
    .required('Requerido'),
  isActive: Yup.bool().required('Requerido')
});
