import EntityBase from '../EntityBase';

class WorkItemTask extends EntityBase {
  constructor(obj) {
    if (!obj) obj = {};
    super(obj);
    this.workItemId = obj.workItemId ? obj.workItemId : undefined;
    this.taskId = obj.taskId ? obj.taskId : undefined;
    this.notes = obj.notes ? obj.notes : '';
    this.realizedBy = obj.realizedBy ? obj.realizedBy : undefined;

    this.task = obj.task ? obj.task : undefined;
  }
}

export default WorkItemTask;
