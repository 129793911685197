import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import {
	withStyles,
	Hidden,
	AppBar,
	Tabs,
	Tab
} from '@material-ui/core';

// Shared components
import {
	Portlet,
	PortletContent,
	TabPanel,
	tabProps,
	ButtonToolTip
} from 'components';

import { GeneralInfo } from '..';

import {
	InfoRounded,
	ArrowBack
} from '@material-ui/icons';

import { injectIntl } from  "react-intl";

import styles from './styles';

class UserEditForm extends Component {

	state = {
		tab: 0
	};

	render() {
		const { classes, onBack, id, intl } = this.props;

		const { tab } = this.state;

		return (
			<Portlet className={classes.root}>
				<PortletContent noPadding>
					{
						onBack &&
						<ButtonToolTip
							className={classes.backButton}
							onClick={onBack}
							title={intl.formatMessage({id: "button.label.back"})}
						>
							<ArrowBack />
						</ButtonToolTip>
					}
					<AppBar
						position="static"
						color="default"
					>
						<Tabs
							value={tab}
							onChange={(e, tab) => {
								this.setState({ tab });
							}}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="auto"
						>
							<Tab
								icon={<InfoRounded />}
								label={<Hidden xsDown>{intl.formatMessage({id:"catalog.tab.generalinfo"})}</Hidden>}
								{...tabProps(0)}
							/>
						</Tabs>
					</AppBar>
					<TabPanel value={tab} index={0}>
						<GeneralInfo id={id} />
					</TabPanel>
				</PortletContent>
			</Portlet>
		);
	}
}

UserEditForm.propTypes = {
	classes: PropTypes.object.isRequired,
	id: PropTypes.number,
	onBack: PropTypes.func
};

UserEditForm.defaultProps = {
	id: null,
	onBack: () => { },
};


export default withStyles(styles)(injectIntl(UserEditForm));