import Api from './Api';

class DepartmentService extends Api {
  constructor(controller) {
    super(controller);
  }
}

let service = new DepartmentService('deparments');

export default service;
