import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Shared components
import { Portlet, PortletContent, Table, ConfirmDialog } from 'components';

// Component styles
import styles from './styles';

import { withSnackbar } from 'notistack';

import { Loader } from 'components';

import { injectIntl } from 'react-intl';

class TaskTypesTable extends Component {
  state = {
    rowsPerPage: 10,
    page: 0,
    itemToDelete: null,
    columns: [],
    actions: []
  };

  componentDidMount() {
    this.setState({ columns: this.getColumns(), actions: this.getActions() });
  }

  getColumns = () => {
    const { intl } = this.props;

    return [
      {
        title: intl.formatMessage({
          id: 'catalog.tasktype.table.columns.code'
        }),
        field: 'code'
      },
      {
        title: intl.formatMessage({
          id: 'catalog.tasktype.table.columns.detail'
        }),
        field: 'detail'
      },
      {
        title: intl.formatMessage({
          id: 'catalog.tasktype.table.columns.isactive'
        }),
        field: 'isActive',
        render: rowData => (
          <span>
            {rowData.isActive
              ? intl.formatMessage({ id: 'item.status.active' })
              : intl.formatMessage({ id: 'item.status.inactive' })}
          </span>
        )
      }
    ];
  };

  getActions = () => {
    const { intl } = this.props;

    return [
      {
        icon: 'edit',
        tooltip: intl.formatMessage({ id: 'catalog.table.action.edit' }),
        onClick: (e, rowData) => this.props.onEditClick(rowData.id)
      },
      {
        icon: 'delete',
        tooltip: intl.formatMessage({ id: 'catalog.table.action.delete' }),
        onClick: (e, rowData) => {
          this.setState({ itemToDelete: rowData });
        }
      },
      {
        icon: 'add',
        isFreeAction: true,
        tooltip: intl.formatMessage({ id: 'catalog.table.action.create' }),
        onClick: this.props.onAddClick
      }
    ];
  };

  delete = () => {
    const { itemToDelete } = this.state;

    this.props.onDelete(itemToDelete.id);
    this.setState({ itemToDelete: null });
  };

  render() {
    const { classes, className, items, isLoading, intl } = this.props;
    const { columns, actions, itemToDelete } = this.state;
    const rootClassName = classNames(classes.root, className);

    return (
      <React.Fragment>
        <Portlet className={rootClassName}>
          <PortletContent noPadding>
            {itemToDelete && (
              <ConfirmDialog
                text={intl.formatMessage(
                  { id: 'action.delete.warning' },
                  { itemName: `${itemToDelete.code}` }
                )}
                open
                onClose={() => this.setState({ itemToDelete: null })}
                onConfirm={this.delete}
              />
            )}
            {isLoading ? (
              <Loader />
            ) : (
              <Table columns={columns} items={items} actions={actions} />
            )}
          </PortletContent>
        </Portlet>
      </React.Fragment>
    );
  }
}

TaskTypesTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onAddClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDelete: PropTypes.func,
  items: PropTypes.array.isRequired
};

TaskTypesTable.defaultProps = {
  items: [],
  onAddClick: () => {},
  onEditClick: () => {},
  onDeleteClick: () => {}
};

export default withStyles(styles)(withSnackbar(injectIntl(TaskTypesTable)));
