import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, Box } from '@material-ui/core';
import WithSubCategories from './components/hoc/WithSubCategories';
import WithUsers from '../../hoc/WithUsers';
import { withSnackbar } from 'notistack';

import { Dashboard as DashboardLayout } from 'layouts';
import { Table } from './components';
import EditForm from './EditForm/index.jsx';
import { injectIntl } from 'react-intl';
import subCategoriesService from 'services/subCategoriesService';

// Component styles
import styles from './style';

class SubCategories extends Component {
	state = {
		isLoading: false,
		items: [],
		users: [],
		selectedUsers: [],
		error: null,
		showDetailOf: null,
		addItem: false,
		embeded: false,
		category: null
	};

	constructor(props) {
		super(props);

		if (props.categoryId) {
			this.state.embeded = true;
		}
	}

	componentDidUpdate = () => {
	}

	componentDidMount() {
		const {
			getUsers,
			categoryId
		} = this.props;

		if (this.state.embeded) {
			this.getSubCategories(categoryId);
		}
		else {
			this.getSubCategoriesByResponsible();
		}

		if (!this.props.users.length) {
			getUsers();
		}
	}

	componentWillUnmount = () => {
		this.props.clearSubCategories([]);
	}

	getSubCategories = async (categoryId) => {
		const response = await subCategoriesService.getByCategoryId(categoryId);
		this.setState({ subCategories: response.data.data });
	}

	getSubCategoriesByResponsible = async () => {
		const response = await subCategoriesService.getByResponsible();
		this.setState({ subCategories: response.data.data }, this.updateCategoriesList);
	}

	updateCategoriesList = () => {
		const {
			subCategories
		} = this.state;

		let categories = [];

		if (subCategories) {
			subCategories.forEach(i => {
				if (!categories.some(x => x.value == i.categoryId)) {
					categories.push({
						value: i.categoryId,
						label: i.categoryName
					});
				}
			});
		}

		this.setState({ categories });
	}

	shouldComponentUpdate(nextProps) {
		const { enqueueSnackbar, clearMessage, intl } = this.props;

		if (
			nextProps.messageOperation &&
			nextProps.messageOperation !== this.props.messageOperation
		) {
			enqueueSnackbar(
				intl.formatMessage({ id: nextProps.messageOperation.message }),
				{ variant: nextProps.messageOperation.type }
			);
			clearMessage();
		}

		return true;
	}

	getTitle = () => {
		const {
			subCategories,
		} = this.state;

		const { showDetailOf } = this.state;

		if (!subCategories || !showDetailOf)
			return "SubCategorias";

		const subCategory = subCategories.find(x => x.id == showDetailOf);

		return (
			<React.Fragment>
				<Box component="span" fontWeight="fontWeightBold" display="inline">Categoria:{" "}</Box>
				<Box component="span" display="inline">{subCategory.categoryName}</Box>
				<Box component="span" display="inline">{" > "}</Box>
				<Box component="span" fontWeight="fontWeightBold" display="inline">SubCategoria:{" "}</Box>
				<Box component="span" display="inline">{subCategory.code}</Box>
			</React.Fragment>
		);
	}

	renderContent = () => {
		return (
			<div>
				{this.renderTable()}
				{this.renderForm()}
			</div>
		);
	}

	renderTable = () => {
		const {
			classes,
			isLoading,
			users,
			clearSubCategory
		} = this.props;

		const {
			showDetailOf,
			addItem,
			embeded,
			categories,
			subCategories,
			category
		} = this.state;

		let filteredSubCategories = subCategories;

		if (category) {
			filteredSubCategories = subCategories.filter(x => x.categoryId == category);
		}

		return (
			<div
				className={classNames(classes.root, {
					[classes.hidden]: showDetailOf || addItem
				})}
			>
				<div className={classes.content}>
					<Table
						embeded={embeded}
						items={filteredSubCategories}
						category={this.props.category}
						users={users}
						onDelete={this.props.deleteSubCategory}
						onEditClick={id => {
							this.setState({ showDetailOf: id });
						}}
						onAddClick={() => {
							clearSubCategory();
							this.setState({ addItem: true });
						}}
						isLoading={isLoading}
						categories={categories}
						onCategorySelected={(category) => this.setState({ category })}
					/>
				</div>
			</div>
		);
	}

	renderForm = () => {
		const {
			classes,
			subCategory,
			categoryId,
			clearSubCategory
		} = this.props;

		const { showDetailOf, addItem } = this.state;

		if (showDetailOf == null && !addItem) {
			return null;
		}

		return (
			<div
				className={classNames(classes.root, {
					[classes.hidden]: !showDetailOf && !addItem
				})}
			>
				<div className={classes.content}>
					<EditForm
						id={showDetailOf}
						subCategory={subCategory}
						categoryId={categoryId}
						onClearSubCategory={clearSubCategory}
						onBack={() =>
							this.setState({ showDetailOf: null, addItem: false })
						}
					/>
				</div>
			</div>
		);
	}

	render() {
		const {
			embeded
		} = this.state;

		if (embeded) {
			return this.renderContent();
		}

		let title = this.getTitle();

		return (
			<DashboardLayout title={title}>
				{this.renderContent()}
			</DashboardLayout>
		);
	}
}

SubCategories.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
	WithUsers(WithSubCategories(withSnackbar(injectIntl(SubCategories))))
);
