import React from 'react';
import { withStyles } from '@material-ui/core';
import { LocationsOwners, Loader, SaveContainer } from 'components';
import { Button, Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import WithCategoryLocation from '../hoc/WithCategoryLocation';
import { injectIntl } from 'react-intl';
import { withSnackbar } from 'notistack';
import styles from './styles';
import companyLocationService from 'services/companyLocationService';
import categoryLocationService from 'services/categoryLocationService';

class CategoryLocationComponent extends React.Component {
	state = {};

	// shouldComponentUpdate(nextProps) {
	// 	if (nextProps.categoryLocations !== this.props.categoryLocations) {
	// 		this.setState({ categoryLocations: nextProps.categoryLocations });
	// 	}

	// 	return true;
	// }

	componentDidMount() {
		const { categoryId } = this.props;
		this.getCatalogs();
		this.getCategoryLocations(categoryId);
	}

	getCatalogs = async () => {
		const response = await companyLocationService.getAll();
		this.setState({ catalogs: response.data.data });
	}

	getCategoryLocations = async (categoryId) => {
		const response = await categoryLocationService.getByCategoryId(categoryId);
		this.setState({ categoryLocations: response.data.data });
	}

	handleLocationsChange = locations => {
		this.setState(ps => ({ ...ps, categoryLocations: locations }));
	}

	saveLocationOwners = () => {
		const { categoryLocations } = this.state;

		const { createCategoryLocation } = this.props;

		categoryLocations.forEach(i => {
			delete i.companyLocation;
		});
		createCategoryLocation(categoryLocations);
	};

	render() {
		const { users, classes, isLoading, categoryId } = this.props;

		const { categoryLocations } = this.state;

		const usersOptions = users.map(i => ({
			value: i.id,
			label: i.displayName,
			isActive: i.isActive
		}));

		return (
			<React.Fragment>
				{isLoading ? (
					<Loader />
				) : (
					<Formik
						enableReinitialize
						initialValues={{ locations: categoryLocations }}
						onSubmit={this.saveLocationOwners}
						render={({ submitForm, isSubmitting, values }) => (
							<SaveContainer saveContent={submitForm}>
								<Form autoComplete="off">
									<Grid
										container
										justify="center"
										className={classes.root}
										spacing={2}
									>
										<Grid item xs={12} md={6}>
											<Grid container spacing={1}>
												<Grid item xs={12}>
													<LocationsOwners
														key={'c-' + categoryId}
														locations={values.locations}
														onChange={this.handleLocationsChange}
														users={usersOptions}
													/>
												</Grid>
												<Grid item xs={12}>
													<Button
														variant="contained"
														color="primary"
														disabled={isSubmitting}
														onClick={submitForm}
													>
														Guardar
													</Button>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Form>
							</SaveContainer>
						)}
					/>
				)}
			</React.Fragment>
		);
	}
}

CategoryLocationComponent.defaultProps = {
	users: [],
	categoryLocations: []
};

export default withStyles(styles)(
	withSnackbar(WithCategoryLocation(injectIntl(CategoryLocationComponent)))
);
