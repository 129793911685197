import React from 'react';

import MUIDataTable from 'mui-datatables';

import { injectIntl } from 'react-intl';

import CustomToolbarSelect from './CustomToolbarSelect';

class SingleCheckBoxTable extends React.Component {
	state = {
		rowsSelected: this.props.rowsSelected
	};

	componentDidMount() {
		this.setState({
			columns: this.getColumns()
		});
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.rowsSelected !== this.props.rowsSelected) {
			const { rowsSelected } = nextProps;

			this.setState({ rowsSelected: rowsSelected });
		}
		return true;
	}

	getColumns = () => {
		return [
			{
				label: 'Subcategorías',
				name: 'code',
				options: {
					filter: true,
					sort: true
				}
			}
		];
	};

	render() {
		const { items, handleRowsSelected } = this.props;
		const { columns } = this.state;
		return (
			<MUIDataTable
				data={items}
				columns={columns}
				options={{
					print: false,
					viewColumns: false,
					download: false,
					search: false,
					filter: false,
					customToolbar: null,

					customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
						<CustomToolbarSelect
							selectedRows={selectedRows}
							displayData={displayData}
							setSelectedRows={setSelectedRows}
						/>
					),
					filterType: 'checkbox',
					selectableRows: 'multiple',
					selectableRowsOnClick: true,
					rowsSelected: this.state.rowsSelected,
					onRowsSelect: (rowsSelected, allRows) => {
						// rowsSelected = allRows.map(row => items[row.index].id);
						handleRowsSelected(allRows.map(row => items[row.index].id));
						this.setState({ rowsSelected: rowsSelected });
					},
					rowsPerPage: 5,
					rowsPerPageOptions: [5, 10, 15, 20],
					textLabels: {
						body: {
							noMatch: 'No se encontrarón registros'
						},
						pagination: {
							next: 'Siguiente',
							previous: 'Previo',
							rowsPerPage: 'registros por pagina:',
							displayRows: 'de'
						},
						selectedRows: {
							text: 'Registros seleccionados',
							delete: 'Borrar',
							deleteAria: 'Borrar registros seleccionados'
						}
					}
				}}
			/>
		);
	}
}

export default injectIntl(SingleCheckBoxTable);
