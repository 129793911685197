import * as React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Button, Grid, InputLabel } from '@material-ui/core';

import { Loader, Select, SaveContainer } from 'components';
import WithGeneralInfo from '../hoc/WithGeneralInfo';
import SubCategoryTaskTable from '../SubCategoryTaskTable';
import { Formik, Field, Form } from 'formik';
import { validationSchema } from './resources';
import Task from 'contract/requests/Task';
import { injectIntl } from 'react-intl';
import styles from './styles';

class GeneralInfo extends React.Component {
	state = {
		task: {
			subCategories: [],
			taskTypeId: undefined,
			categoryId: undefined
		},
		rowsSelected: []
	};

	componentDidMount() {
		const { getTask, id } = this.props;
		const { task } = this.state;
		if (id) {
			getTask(id);
			this.setState({ selectedTask: id, task: { ...task, taskTypeId: id } });
		}
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.task !== this.props.task) {
			this.setState(
				{ task: { ...nextProps.task, categoryId: this.state.task.categoryId } },
				() => true
			);
		}

		if (nextProps.subCategories !== this.props.subCategories) {
			let subCategories = nextProps.subCategories.map(x => x);

			for (let index = 0; index < subCategories.length; index++) {
				let item = subCategories[index];
				subCategories[index] = {
					...item,
					tableData: { id: index, checked: item.isActive }
				};
			}

			let rowsSelected = subCategories.filter(x => x.isActive);

			this.setState({
				rowsSelected: rowsSelected,
				subCategories: subCategories
			});
		}

		return true;
	}

	handleRowsSelected = rowsSelected => {
		this.setState({ rowsSelected: rowsSelected });
	};

	handleUpdateRowData = row => {
		const { subCategories } = this.state;
		const newSubCategories = subCategories.map(x => x);
		let found = newSubCategories.find(x => x.id == row.id);
		if (found) found.isRequired = row.isRequired;
		this.setState({
			subCategories: newSubCategories
		});
	};

	render() {
		const {
			classes,
			saveMultiple,
			isLoading,
			intl,
			taskTypes,
			categories,
			getByCategoryId
		} = this.props;

		const { task, subCategories } = this.state;

		const taskTypesOptions = taskTypes.map(i => ({
			value: i.id,
			label: i.code
		}));

		const categoriesOptions = categories.map(i => ({
			value: i.id,
			label: i.code
		}));

		return (
			<React.Fragment>
				{isLoading ? (
					<Loader />
				) : (
						<Formik
							enableReinitialize
							initialValues={task}
							validationSchema={validationSchema(intl)}
							onSubmit={values => {
								let taskRequest = new Task(values);
								taskRequest.subCategories = this.state.rowsSelected.map(x => ({
									subCategoryId: x.id,
									isRequired: x.isRequired
								}));
								let foundTaskTypes = taskTypes.find(
									x => x.id === values.taskTypeId
								);
								taskRequest.taskTypeCode = foundTaskTypes.code;
								saveMultiple(taskRequest);
							}}
							render={({ submitForm, isSubmitting }) => (
								<SaveContainer saveContent={submitForm}>
									<Form>
										<Grid container className={classes.root} spacing={1}>
											<Grid item xs={12} md={6} lg={6}>
												<Grid container className={classes.root} spacing={1}>
													<Grid item xs={12} md={12} lg={12}>
														<Field
															name="taskTypeId"
															render={({ field, form }) => {
																return (
																	<div
																		className={classNames(classes.selectRoot, {
																			error:
																				form.touched[field.name] &&
																				form.errors[field.name]
																		})}
																	>
																		<InputLabel className={classes.inputLabel}>
																			{intl.formatMessage({
																				id: 'catalog.task.field.tasktype'
																			})}
																		</InputLabel>
																		<Select
																			isSearchable
																			value={field.value}
																			onChange={selected => {
																				form.setFieldValue(
																					field.name,
																					selected.value
																				);
																				form.setFieldValue(
																					'taskType',
																					taskTypesOptions.find(
																						x => x.value === selected.value
																					).label
																				);
																				form.setFieldValue(
																					'categoryId',
																					undefined
																				);
																				this.setState({
																					task: {
																						...this.state.task,
																						taskTypeId: selected.value,
																						categoryId: undefined
																					},
																					subCategories: [],
																					rowsSelected: []
																				});
																			}}
																			options={taskTypesOptions}
																			onMenuClose={() =>
																				form.setFieldTouched(
																					field.name,
																					true,
																					true
																				)
																			}
																			onMenuOpen={() =>
																				form.setFieldTouched(field.name, false)
																			}
																		/>
																		{form.touched[field.name] &&
																			form.errors[field.name] && (
																				<p className="MuiFormHelperText-root Mui-error">
																					{form.errors[field.name]}
																				</p>
																			)}
																	</div>
																);
															}}
														/>
													</Grid>
												</Grid>

												<Grid container className={classes.root} spacing={1}>
													<Grid item xs={12} md={12} lg={12}>
														<Field
															name="categoryId"
															render={({ field, form }) => {
																return (
																	<div
																		className={classNames(classes.selectRoot, {
																			error:
																				form.touched[field.name] &&
																				form.errors[field.name]
																		})}
																	>
																		<InputLabel className={classes.inputLabel}>
																			{intl.formatMessage({
																				id: 'catalog.task.field.category'
																			})}
																		</InputLabel>
																		<Select
																			disabled={!this.state.task.taskTypeId > 0}
																			key={field.value}
																			isSearchable
																			value={field.value}
																			onChange={selected => {
																				form.setFieldValue(
																					field.name,
																					selected.value
																				);
																				form.setFieldValue(
																					'category',
																					categoriesOptions.find(
																						x => x.value === selected.value
																					).label
																				);
																				this.setState({
																					task: {
																						...this.state.task,
																						categoryId: selected.value
																					},
																					subCategories: [],
																					rowsSelected: []
																				});
																				getByCategoryId(
																					this.state.task.taskTypeId,
																					selected.value
																				);
																			}}
																			options={categoriesOptions}
																			onMenuClose={() =>
																				form.setFieldTouched(
																					field.name,
																					true,
																					true
																				)
																			}
																			onMenuOpen={() =>
																				form.setFieldTouched(field.name, false)
																			}
																		/>
																		{form.touched[field.name] &&
																			form.errors[field.name] && (
																				<p className="MuiFormHelperText-root Mui-error">
																					{form.errors[field.name]}
																				</p>
																			)}
																	</div>
																);
															}}
														/>
													</Grid>
												</Grid>

												<Grid container className={classes.root} spacing={1}>
													<Grid item xs={12} md={12} lg={12}>
														<div className={classNames(classes.selectRoot)}>
															<InputLabel className={classes.inputLabel}>
																{intl.formatMessage({
																	id: 'catalog.task.field.subcategories'
																})}
															</InputLabel>
															<SubCategoryTaskTable
																items={subCategories}
																updateRowData={this.handleUpdateRowData}
																postSelectedRows={this.handleRowsSelected}
															/>
														</div>
													</Grid>
												</Grid>

												<Grid container className={classes.root} spacing={1}>
													<Grid item xs={12}>
														<br />
														<Button
															variant="contained"
															color="primary"
															disabled={isSubmitting}
															onClick={() => {
																submitForm();
															}}
														>
															{intl.formatMessage({ id: 'button.label.save' })}
														</Button>
													</Grid>
												</Grid>
											</Grid>

											<Grid item xs={12} md={6} lg={6} />
										</Grid>
									</Form>
								</SaveContainer>
							)}
						/>
					)}
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(WithGeneralInfo(injectIntl(GeneralInfo)));
