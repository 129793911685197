import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, Hidden, AppBar, Tabs, Tab } from '@material-ui/core';

// Shared components
import {
	Portlet,
	PortletContent,
	ButtonToolTip,
	TabPanel,
	tabProps
} from 'components';
import { GeneralInfo } from '../../components';

import { InfoRounded, ArrowBack } from '@material-ui/icons';

import { injectIntl } from 'react-intl';

import styles from './styles';

class EditForm extends Component {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		id: PropTypes.number,
		onUpdate: PropTypes.func,
		onBack: PropTypes.func
	};

	static defaultProps = {
		id: null,
		onUpdate: () => { },
		onBack: () => { }
	};

	state = {
		id: this.props.id,
		tab: 0
	};

	render() {
		const { classes, onBack, intl, id } = this.props;

		const { tab } = this.state;

		return (
			<Portlet className={classes.root}>
				<PortletContent noPadding>
					{onBack && (
						<ButtonToolTip
							className={classes.backButton}
							onClick={onBack}
							title={intl.formatMessage({ id: 'button.label.back' })}
						>
							<ArrowBack />
						</ButtonToolTip>
					)}
					<AppBar position="static" color="default">
						<Tabs
							value={tab}
							onChange={(e, tab) => {
								this.setState({ tab });
							}}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="auto"
						>
							<Tab
								icon={<InfoRounded />}
								label={
									<Hidden xsDown>
										{intl.formatMessage({ id: 'catalog.tab.generalinfo' })}
									</Hidden>
								}
								{...tabProps(0)}
							/>
						</Tabs>
					</AppBar>
					<TabPanel value={tab} index={0}>
						<GeneralInfo id={id} />
					</TabPanel>
				</PortletContent>
			</Portlet>
		);
	}
}

export default withStyles(styles)(injectIntl(EditForm));
