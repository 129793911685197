import EntityBase from '../EntityBase';
import Immutable from 'seamless-immutable';

class Department extends EntityBase {
	constructor(obj) {
		if (!obj) obj = {};
		super(obj);
		this.code = obj.code ? obj.code : '';
		this.detail = obj.detail ? obj.detail : '';
		this.ownerId = obj.ownerId ? obj.ownerId : '';
		this.ownerName = obj.ownerName ? obj.ownerName : '';
		this.companyId = obj.companyId ? obj.companyId : undefined;
		//this.departmentMembers = obj.departmentMembers;

		if (!obj.departmentMembers) {
			this.departmentMembers = obj.departmentMembers;
		}
		else {
			if (Immutable.isImmutable(obj.departmentMembers)) {
				this.departmentMembers = Immutable.asMutable(obj.departmentMembers);
			}
			else {
				this.departmentMembers = obj.departmentMembers;
			}
		}
	}
}

export default Department;
