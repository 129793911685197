import React from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';
import { Button, Grid, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import WithSubCategoryLocation from '../hoc/WithSubCategoryLocation';
import { withSnackbar } from 'notistack';
import { injectIntl } from 'react-intl';
import styles from './styles';
import { LocationsOwners, SaveContainer } from 'components';
import { ErrorOutlineOutlined } from '@material-ui/icons';

class SubCategoryLocationComponent extends React.Component {
  state = {};

  shouldComponentUpdate(nextProps) {
    const { intl, enqueueSnackbar } = this.props;

    if (nextProps.subCategoryLocations !== this.props.subCategoryLocations) {
      this.setState({ subCategoryLocations: nextProps.subCategoryLocations });
    }

    if (
      nextProps.messageOperation &&
      nextProps.messageOperation !== this.props.messageOperation
    ) {
      enqueueSnackbar(
        intl.formatMessage({ id: nextProps.messageOperation.message }),
        { variant: nextProps.messageOperation.type }
      );
    }

    return true;
  }

  componentDidMount() {
    const { getCatalogs, getSubCategoryLocations, subCategoryId } = this.props;
    getCatalogs();
    getSubCategoryLocations(subCategoryId);
  }

  handleLocationsChange = locations =>
    this.setState(ps => ({ ...ps, subCategoryLocations: locations }));

  saveLocationOwners = () => {
    const { subCategoryLocations } = this.state;

    const { createSubCategoryLocation } = this.props;

    subCategoryLocations.forEach(i => {
      delete i.companyLocation;
    });
    createSubCategoryLocation(subCategoryLocations);
  };

  render() {
    const { users, classes, isLoading, subCategoryId } = this.props;

    const { subCategoryLocations } = this.state;

    if (
      Array.isArray(subCategoryLocations) &&
      subCategoryLocations.length == 0
    ) {
      return (
        <Grid
          container
          spacing={3}
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.columnGrid}
        >
          <Grid>
            <ErrorOutlineOutlined className={classes.giantIcon} />
          </Grid>
          <Grid>
            <Typography style={{ fontSize: '22px' }}>
              Primero se necesita configurar responsables a sucursales de
              categoria
            </Typography>
          </Grid>
        </Grid>
      );
    }

    const usersOptions = users.map(i => ({
      value: i.id,
      label: i.displayName,
      isActive: i.isActive
    }));

    return (
      <React.Fragment>
        {isLoading ? (
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        ) : (
          <Formik
            enableReinitialize
            initialValues={{
              locations:
                subCategoryLocations == null ? [] : subCategoryLocations
            }}
            onSubmit={this.saveLocationOwners}
            render={({ submitForm, isSubmitting, values }) => (
              <SaveContainer saveContent={submitForm}>
                <Form autoComplete="off">
                  <Grid
                    container
                    justify="center"
                    className={classes.root}
                    spacing={2}
                  >
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <LocationsOwners
                            key={'sb-' + subCategoryId}
                            locations={values.locations}
                            onChange={this.handleLocationsChange}
                            users={usersOptions}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={submitForm}
                          >
                            Guardar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </SaveContainer>
            )}
          />
        )}
      </React.Fragment>
    );
  }
}

SubCategoryLocationComponent.defaultProps = {
  users: [],
  subCategoryLocations: null
};

export default withStyles(styles)(
  withSnackbar(
    WithSubCategoryLocation(injectIntl(SubCategoryLocationComponent))
  )
);
