import API from './Api';

class LookupSettingService extends API {
  constructor(controller) {
    super(controller);
  }
}

let service = new LookupSettingService('lookupsettings');

export default service;
