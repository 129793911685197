import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  tasks: yup.array().of(
    yup.object().shape({
      realizedBy: yup
        .string()
        .required('Este campo es requerido.')
        .nullable()
    })
  )
});

export const styles = theme => ({
  root: {
    flexGrow: 1
  },
  selectRoot: {
    marginTop: theme.spacing.unit * 3
  }
});

export class TaskModel {
  constructor(obj) {
    if (!obj) obj = {};
    this.realizedBy = obj.realizedBy ? obj.realizedBy : undefined;
    this.notes = obj.notes ? obj.notes : undefined;
  }
}
