import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button } from '@material-ui/core';

// Material icons
import {
	ArrowDropDown as ArrowDropDownIcon,
	ArrowRight as ArrowRightIcon
} from '@material-ui/icons';

// Shared components
import {
	Portlet,
	PortletHeader,
	PortletLabel,
	PortletToolbar,
	PortletContent,
	PortletFooter
} from 'components';

// Component styles
import styles from './styles';

class TicketsChart extends Component {
	static propTypes = {
		className: PropTypes.string,
		classes: PropTypes.object.isRequired
	};

	render() {
		const { classes, className, ...rest } = this.props;

		const rootClassName = classNames(classes.root, className);

		return (
			<Portlet
				{...rest}
				className={rootClassName}
			>
				<PortletHeader noDivider>
					<PortletLabel title="Ultimos Tickets" />
					<PortletToolbar>
						<Button
							className={classes.dropdownButton}
							size="small"
							variant="text"
						>
							Ultimos 7 días <ArrowDropDownIcon />
						</Button>
					</PortletToolbar>
				</PortletHeader>
				<PortletContent>
					<div className={classes.chartWrapper}>
						{/* <Bar
			  data={data}
			  options={options}
			/> */}
					</div>
				</PortletContent>
				<PortletFooter className={classes.portletFooter}>
					<Button
						color="primary"
						size="small"
						variant="text"
						onClick={() => { window.location = "/received-tickets"; }}
					>
						Ver detalle <ArrowRightIcon />
					</Button>
				</PortletFooter>
			</Portlet>
		);
	}
}

export default withStyles(styles)(TicketsChart);
