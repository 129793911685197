export default theme => {
	return {
		root: {
			backgroundColor: theme.palette.common.white,
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			paddingLeft: theme.spacing.unit,
			paddingRight: theme.spacing.unit
		},
		logoWrapper: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '63px',
			flexShrink: 0
		},
		logoLink: {
			fontSize: 0,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center'
		},
		logoImage: {
			cursor: 'pointer',
			width: '160px'
		},
		logoDivider: {
			marginBottom: theme.spacing.unit * 2
		},
		profile: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			minHeight: 'fit-content'
		},
		avatar: {
			width: '100px',
			height: '100px'
		},
		nameText: {
			marginTop: theme.spacing.unit * 2
		},
		bioText: {},
		profileDivider: {
			marginBottom: theme.spacing.unit * 2,
			marginTop: theme.spacing.unit * 2
		},
		listSubheader: {
			color: theme.palette.text.secondary
		},
		listItem: {
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: "#F6F9FD",
				borderLeft: `4px solid ${theme.palette.primary.main}`,
				borderRadius: '4px',
				'& $listItemIcon': {
					color: theme.palette.primary.main,
					marginLeft: '-4px'
				}
			},
			'& + &': {
				marginTop: theme.spacing.unit
			}
		},
		activeListItem: {
			borderLeft: `4px solid ${theme.palette.primary.main}`,
			borderRadius: '4px',
			backgroundColor: "#F6F9FD",
			'& $listItemText': {
				color: theme.palette.text.primary
			},
			'& $listItemIcon': {
				color: theme.palette.primary.main,
				marginLeft: '-4px'
			}
		},
		listItemIcon: {
			marginRight: 0
		},
		listItemText: {
			fontWeight: 500,
			color: theme.palette.text.secondary
		},
		listDivider: {
			marginBottom: theme.spacing.unit * 2,
			marginTop: theme.spacing.unit * 2
		},
		groupItems: {
			paddingLeft: theme.spacing(5)
		}
	};
};
