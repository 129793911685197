import { fork, all, call } from 'redux-saga/effects';
import userSagas from './userSagas';
import companyLocationSagas from './companyLocationSagas';
import categorySagas from './categorySagas';
import roleSagas from './roleSagas';
import stateSagas from './stateSagas';
import entityTypeSagas from './entityTypeSagas';
import prioritySagas from './prioritySagas';
import priorityLevelSagas from './priorityLevelSagas';
import citySagas from './citySagas';
import chartWorkItemSagas from './chartWorkItemSagas';
import departmentSagas from './departmentSagas';
import subCategorySagas from './subCategorySagas';
import subCategoryLocationSagas from './subCategoryLocationSagas';
import attachmentConfigSagas from './attachmentConfigSagas';
import notificationSagas from './notificationSagas';
import responseTimeTypeSagas from './responseTimeTypeSagas';
import taskTypeSagas from './taskTypeSagas';
import taskSagas from './taskSagas';
import workItemTaskSagas from './workItemTaskSagas';
import userGroupSagas from './userGroupSagas';
import externalEntitySagas from './externalEntitySagas';
import lookupSettingSagas from './lookupSettingSagas';

export default function* rootSagas() {
  yield all([
    fork(userSagas),
    fork(companyLocationSagas),
    fork(categorySagas),
    fork(entityTypeSagas),
    fork(subCategorySagas),
    fork(subCategoryLocationSagas),
    fork(attachmentConfigSagas),
    fork(priorityLevelSagas),
    fork(prioritySagas),
    fork(departmentSagas),
    fork(roleSagas),
    fork(stateSagas),
    fork(chartWorkItemSagas),
    fork(citySagas),
    fork(notificationSagas),
    fork(taskTypeSagas),
    fork(taskSagas),
    fork(workItemTaskSagas),
    fork(responseTimeTypeSagas),
    fork(userGroupSagas),
    fork(lookupSettingSagas),
    fork(externalEntitySagas)
  ]);
}

export const safeCall = (saga, ...args) =>
  call(function*() {
    try {
      return { err: false, result: yield call(saga, ...args) };
    } catch (err) {
      return { err: true, result: err };
    }
  });
