import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  TaskTypeCreators,
  selectMessageOperation,
  selectTaskTypes,
  selectIsLoading
} from 'redux/TaskTypeRedux';

export const mapStateToProps = state => ({
  messageOperation: selectMessageOperation(state),
  taskTypes: selectTaskTypes(state),
  isLoading: selectIsLoading(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTaskTypes: TaskTypeCreators.taskTypeGetListRequest,
      deleteTaskType: TaskTypeCreators.taskTypeDeleteRequest,
      clearMessage: TaskTypeCreators.taskTypeSetMessage
    },
    dispatch
  );

export const hoc = WrappedComponent => {
  const hocComponent = ({ ...props }) => <WrappedComponent {...props} />;

  hocComponent.propTypes = {
    /** Company locations collection */
    taskTypes: PropTypes.array,
    isLoading: PropTypes.bool,
    getTaskTypes: PropTypes.func,
    deleteTaskType: PropTypes.func
  };

  return hocComponent;
};

export default WithTaskTypes =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hoc(WithTaskTypes));
