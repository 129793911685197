import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletContent,
  Table,
  ConfirmDialog,
  Loader
} from 'components';

// Component styles
import styles from './styles';

//Helper for popup messages
import { withSnackbar } from 'notistack';

import { injectIntl } from 'react-intl';

class UserTable extends Component {
  state = {
    rowsPerPage: 15,
    page: 0,
    itemToDelete: null,
    columns: [],
    actions: []
  };

  componentDidMount() {
    this.setState({ columns: this.getColumns(), actions: this.getActions() });
  }

  shouldComponentUpdate(nextProps) {
    const { intl } = this.props;
    if (
      nextProps.messageOperation &&
      nextProps.messageOperation !== this.props.messageOperation
    ) {
      if (typeof nextProps.messageOperation == 'string') {
        this.props.enqueueSnackbar(nextProps.messageOperation, {
          variant: 'error'
        });
      } else {
        this.props.enqueueSnackbar(
          intl.formatMessage({ id: nextProps.messageOperation.message }),
          { variant: nextProps.messageOperation.type }
        );
      }
      this.props.onClearMessage();
    }

    return true;
  }

  getColumns = () => {
    const { intl } = this.props;
    var columns = [
      {
        title: intl.formatMessage({
          id: 'catalog.users.table.columns.username'
        }),
        field: 'userName'
      },
      {
        title: intl.formatMessage({
          id: 'catalog.users.table.columns.displayname'
        }),
        field: 'displayName'
      },
      {
        title: intl.formatMessage({ id: 'catalog.users.table.columns.email' }),
        field: 'email'
      },
      {
        title: intl.formatMessage({ id: 'catalog.users.table.columns.mobile' }),
        field: 'mobilePhone'
      },
      {
        title: intl.formatMessage({
          id: 'catalog.users.table.columns.rolename'
        }),
        field: 'roleName'
      },
      {
        title: intl.formatMessage({
          id: 'catalog.users.table.columns.entitytype'
        }),
        field: 'entityTypeName'
      },
      {
        title: intl.formatMessage({
          id: 'catalog.users.table.columns.isactive'
        }),
        field: 'isActive',
        render: rowData => (
          <span>
            {rowData.isActive
              ? intl.formatMessage({ id: 'item.status.active' })
              : intl.formatMessage({ id: 'item.status.inactive' })}
          </span>
        )
      }
    ];

    return columns;
  };

  getActions = () => {
    const { intl } = this.props;
    var actions = [
      {
        icon: 'edit',
        tooltip: intl.formatMessage({ id: 'catalog.table.action.edit' }),
        onClick: (e, rowData) => this.props.onEditClick(rowData.id)
      },
      {
        icon: 'delete',
        tooltip: intl.formatMessage({ id: 'catalog.table.action.delete' }),
        onClick: (e, rowData) => {
          this.setState({ itemToDelete: rowData });
        }
      },
      {
        icon: 'add',
        tooltip: intl.formatMessage({ id: 'catalog.table.action.create' }),
        isFreeAction: true,
        onClick: this.props.onAddClick
      }
    ];
    return actions;
  };

  delete = () => {
    const { itemToDelete } = this.state;

    this.props.onDelete(itemToDelete.id);
    this.setState({ itemToDelete: null });
  };

  render() {
    const { classes, className, items, isLoading, intl } = this.props;
    const { columns, actions, itemToDelete } = this.state;
    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          {itemToDelete && (
            <ConfirmDialog
              text={intl.formatMessage(
                { id: 'action.delete.warning' },
                {
                  itemName: `${itemToDelete.userName} (${itemToDelete.displayName})`
                }
              )}
              open
              onClose={() => this.setState({ itemToDelete: null })}
              onConfirm={this.delete}
            />
          )}
          {isLoading ? (
            <Loader />
          ) : (
            <Table columns={columns} items={items} actions={actions} />
          )}
        </PortletContent>
      </Portlet>
    );
  }
}

UserTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onAddClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDelete: PropTypes.func,
  items: PropTypes.array.isRequired
};

UserTable.defaultProps = {
  items: [],
  onAddClick: () => {},
  onEditClick: () => {},
  onDeleteClick: () => {}
};

export default withStyles(styles)(injectIntl(withSnackbar(UserTable)));
