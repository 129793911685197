import React from 'react';
import { connect } from 'react-redux';
import { PriorityLevelCreators, selectSubcategoryPriorityLevels, selectIsLoading, selectMessageOperation } from "redux/PriorityLevelRedux";
import { ResponseTimeTypesCreators, selectResponseTimeTypes } from 'redux/ResponseTimeTypeRedux';

export const mapStateToProps = state => ({
	isLoading: selectIsLoading(state),
	priorityLevels: selectSubcategoryPriorityLevels(state),
	messageOperation: selectMessageOperation(state),
    responseTimeTypes: selectResponseTimeTypes(state),
});

export const mapDispatchToProps = {
	getPriorityLevels: PriorityLevelCreators.priorityLevelGetRequest,
	savePriorityLevels: PriorityLevelCreators.priorityLevelCreateRequest,
	updatePriorityLevels: PriorityLevelCreators.priorityLevelUpdateRequest,
    clearMessage: PriorityLevelCreators.priorityLevelSetMessage,
    getResponseTimeTypes: ResponseTimeTypesCreators.responseTimeTypeListRequest
};

export const hocPriorityLevels = (WrappedComponent) => {
	const hoc = ({ ...props }) => <WrappedComponent {...props} />;

	hoc.propTypes = {
	};

	return hoc;
};

export default WithPriorityLevels => connect(mapStateToProps, mapDispatchToProps)(hocPriorityLevels(WithPriorityLevels));
