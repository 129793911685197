import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Shared components
import { Portlet, PortletContent, Table, ConfirmDialog } from 'components';

// Component styles
import styles from './styles';

class TableComponent extends Component {
    static propTypes = {
        className: PropTypes.string,
        classes: PropTypes.object.isRequired,
        onBack: PropTypes.func.isRequired,
        onAddClick: PropTypes.func.isRequired,
        onEditClick: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        items: PropTypes.array.isRequired,
    };
    
     static defaultProps = {
        items: [],
        users: [],
        onDelete: () => { },
        onEditClick: () => { },
        onAddClick: () => { },
        onBack: () => { }
    };

	state = {
		rowsPerPage: 5,
		page: 0,
		suggestions: [],
		columns: [
			{
				title: "Nombre",
				field: 'code'
			},
			{
				title: "Usuarios",
				field: 'members',
				render: rowData => <span>{rowData.members ? rowData.members.length : "0"}</span>
			},
			{
				title: "Activo",
				field: 'isActive',
				render: rowData => <span>{rowData.isActive ? "Si" : "No"}</span>
			}
		],
		actions: [
			{
				icon: 'edit',
				tooltip: 'Editar',
				onClick: (e, rowData) => this.props.onEditClick(rowData.id)
			},
			{
				icon: 'add',
				isFreeAction: true,
				tooltip: 'Crear',
				onClick: this.props.onAddClick
			},
		]
	};

	delete = () => {
		const {
			itemToDelete
		} = this.state;

		this.props.onDelete(itemToDelete.id);
		this.setState({ itemToDelete: null });
	}

	render() {
		const { classes, className, items } = this.props;
		const { columns, itemToDelete, actions } = this.state;
		const rootClassName = classNames(classes.root, className);

		return (
			<Portlet className={rootClassName}>
				<PortletContent noPadding>
					{
						itemToDelete &&
						<ConfirmDialog
							text={`¿ Seguro que desea borrar el grupo '${itemToDelete.code}' ?`}
							open
							onClose={() => this.setState({ itemToDelete: null })}
							onConfirm={this.delete}
						/>
					}
					<Table
						columns={columns}
						items={items}
						actions={actions}
					/>
				</PortletContent>
			</Portlet>
		);
	}
}

export default withStyles(styles)(TableComponent);
