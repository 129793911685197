import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';

class PrivateRoute extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        this.verifyPermissions();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.path != this.props.path || prevProps.permissionsLoaded != this.props.permissionsLoaded) {
            this.verifyPermissions();
        }
    }

    verifyPermissions = () => {
        if (!this.props.permissionsLoaded)
            return;

        const hasPermission = this.props.permissions.find(x => this.props.path == x.url);
        if (!hasPermission) {
            this.props.history.push("/");
        }
    }

    render() {
        const { isAuthenticated, Component, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated ?
                        (
                            <Component {...props} />
                        )
                        :
                        (
                            <Redirect
                                to={{ pathname: 'sign-in', state: { from: props.location } }}
                            />
                        )
                }
            />
        );
    }
}

export default withRouter(PrivateRoute);