import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import qs from 'qs';

// UI Components
import {
    withStyles,
    Grid,
    CircularProgress
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table } from 'components';

//Services
import { WithTicket, WithSession } from "hoc";
import severitiesService from "services/severitiesService";
import ticketsService from "services/ticketsService";
import companyLocationsService from "services/companyLocationService";

//Assets
import styles from './styles';

class TicketsList extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        tickets: [],
        selectedItems: []
    };

    columns = [
        {
            title: "Id",
            field: 'id'
        },
        {
            title: "Tema",
            field: 'subject',
            render: row => {
                return (
                    <Link to={`/ticket/${row.id}`}>
                        {row.subject}
                    </Link>
                );
            }
        },
        {
            title: "SubCategoria",
            field: 'subCategoryId',
            render: row => {
                const subCategory = this.props.subCategories ? this.props.subCategories.find(x => x.id == row.subCategoryId) : null;
                return (
                    <span>{subCategory ? subCategory.code : ""}</span>
                );
            }
        },
        {
            title: "Estado",
            field: 'statusId',
            render: row => {
                const status = this.statuses.find(x => x.value == row.statusId);
                return (
                    <span>{status.label}</span>
                );
            }
        },
        {
            title: "Creador",
            field: 'createdBy',
            render: row => {
                const user = this.props.users ? this.props.users.find(x => x.id == row.createdBy) : null;
                return (
                    <span>{user ? user.displayName : ""}</span>
                );
            }
        },
        {
            title: "Actualización",
            field: 'createdDate',
            render: row => {
                return (
                    <span>
                        {
                            row.modifiedDate
                                ? Moment.utc(row.modifiedDate).local().fromNow()
                                : Moment.utc(row.createdDate).local().fromNow()}
                    </span>
                );
            }
        }
    ];

    statuses = [
        { value: 1, label: "Inicial" },
        { value: 2, label: "En progreso" },
        { value: 3, label: "Finalizado" }
    ]

    componentDidMount = () => {
        this.getTicketsFromUrl();
        this.getFiltersFromUrl();
        this.props.getUsers();
        this.props.getSubCategories();

        severitiesService.getAll().then((response) => {
            this.setState({ severities: response.data.data });
        });

        companyLocationsService.getAll().then((response) => {
            this.setState({ companyLocations: response.data.data });
        });
    }

    componentDidUpdate = (prevProps) => {
        const {
            location: {
                search,
                pathname
            }
        } = this.props;

        if (search != prevProps.location.search) {
            this.getFiltersFromUrl();
        }

        if (pathname != prevProps.location.pathname) {
            this.getTicketsFromUrl();
        }
    }

    getTicketsFromUrl = () => {
        const {
            location: {
                pathname
            }
        } = this.props;

        let ticketsFunction = () => { };
        if (pathname == '/sent-tickets') {
            ticketsFunction = ticketsService.getSent;
        } else if (pathname == '/received-tickets') {
            ticketsFunction = ticketsService.getReceived;
        } else if (pathname == '/subscribed-tickets') {
            ticketsFunction = ticketsService.getSubscribedTickets;
        }

        ticketsFunction().then((response) => {
            if (!response.data || response.data.status == "Fail" || !response.data.data || !Array.isArray(response.data.data)) {
                return;
            }

            this.setState({ tickets: response.data.data }, () => {
            });
        });
    }

    getFiltersFromUrl = () => {
        const {
            location: {
                search
            }
        } = this.props;

        var qsParams = qs.parse(search.replace("?", ""));

        if (qsParams.filter) {
            this.setState({ filter: qsParams.filter });
        }
    }

    copyRows = () => {
        this.setState({ copiyng: true }, () => {
            let table = document.getElementsByTagName('table')[0];

            if (window.getSelection) {
                const selection = window.getSelection();
                const range = document.createRange();
                range.selectNodeContents(table);
                selection.removeAllRanges();
                selection.addRange(range);
            }

            document.execCommand('copy', true);
            window.getSelection().removeAllRanges();
            this.props.enqueueSnackbar("Listado de tickets copiado al porta papeles", { variant: "success" });

            window.setTimeout(() => {
                this.setState({ copiyng: false });
            }, 500);
        });
    }

    getTitle = () => {
        const {
            location: {
                pathname
            }
        } = this.props;

        const { filter } = this.state;

        if (pathname == '/sent-tickets') {
            return !filter ? "Tickets creados" : (filter == "closed" ? "Tickets cerrados" : "Tickets activos");
        } else if (pathname == '/received-tickets') {
            return "Tickets recibidos";
        } else if (pathname == '/subscribed-tickets') {
            return "Tickets suscritos";
        }
    }

    renderTable = () => {
        const {
            tickets,
            selectedItems,
            copiyng,
            filter
        } = this.state;

        let items = tickets;
        if (filter) {
            if (filter == "active") {
                items = items.filter(i => i.statusId != 3);
            }
            else if (filter == "closed") {
                items = items.filter(i => i.statusId == 3);
            }
        }

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    <Table
                        columns={this.columns}
                        items={copiyng ? selectedItems : items}
                        options={{ pageSize: copiyng ? 100 : 10, selection: copiyng ? false : true }}
                        actions={copiyng ? [] : [
                            {
                                icon: 'content_copy',
                                tooltip: 'Copiar al porta papeles',
                                onClick: this.copyRows
                            }
                        ]}
                        onSelectionChange={(selectedItems) => {
                            this.setState({ selectedItems });
                        }}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            loading,
        } = this.state;

        let isLoading = loading;
        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {
                                    isLoading
                                        ? <CircularProgress />
                                        : this.renderTable()
                                }
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(withSnackbar(WithSession(WithTicket(withStyles(styles)(TicketsList)))));