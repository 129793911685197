import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from '../routes/PrivateRoute';

// Views
import ListTickets from '../views/Tickets/List';
import SearchTickets from '../views/Tickets/Search';
import AssignedTicketsReport from '../views/Tickets/Report';
import Dashboard from '../views/Dashboard';
import Users from '../views/Users';
import Typography from '../views/Typography';
import Icons from '../views/Icons';
import Account from '../views/Account';
import ChangePassword from '../views/ChangePassword';
import Settings from '../views/Settings';
import SignUp from '../views/SignUp';
import SignIn from '../views/SignIn';
import UnderDevelopment from '../views/UnderDevelopment';
import Categories from '../views/Categories';
import SubCategories from '../views/SubCategories';
import UserGroups from '../views/Catalogs/UserGroups';
import Roles from '../views/Roles';
import Permissions from '../views/Permissions';
import Departments from '../views/Departments';
import TicketForm from '../views/Tickets/Form';
import CompanyLocations from '../views/CompanyLocations';
import AttachmentConfig from '../views/AttachmentConfig';
import TaskTypes from '../views/TaskTypes';
import Task from '../views/Task';
import NotFound from '../views/NotFound';
import WithAuth from '../hoc/WithAuth';
import { withSnackbar } from 'notistack';
import { WithNotifications, WithPushNotifications } from 'hoc';

const EnhacedSignIn = WithAuth(SignIn);
const EnhancedUserGroups = WithAuth(UserGroups);

const EnhacedPrivateRoute = WithAuth(PrivateRoute);

class Routes extends Component {
  componentWillMount = () => {
    if (!localStorage.appUser) {
      this.props.setSession(null);
    }

    try {
      const user = JSON.parse(localStorage.appUser);
      this.props.setSession(user);
    } catch (e) {
      this.props.setSession(null);
    }
  };

  componentDidMount = () => { };

  componentDidUpdate = prevProps => {
    const { pushNotification } = this.props;

    if (prevProps.notification == null && this.props.notification != null) {
      this.props.enqueueSnackbar(
        this.props.notification.message,
        this.props.notification.options
      );
      this.props.clearNotification();
    }

    //Web push notifications subscribe
    if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      this.props.getSettings();
      this.props.getPermissions();
      pushNotification && pushNotification.subscribeToPushNotification();
    }

    //Web push notifications unsubscribed
    if (prevProps.isAuthenticated && !this.props.isAuthenticated) {
      pushNotification && pushNotification.unsubscribePushNotification();
    }
  };

  render() {
    return (
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <EnhacedPrivateRoute component={Dashboard} path="/dashboard" />
        <EnhacedPrivateRoute component={Users} path="/users" />
        <EnhacedPrivateRoute
          component={CompanyLocations}
          path="/companylocations"
        />
        <EnhacedPrivateRoute
          component={AttachmentConfig}
          path="/attachmentconfig"
        />
        <EnhacedPrivateRoute component={Typography} path="/typography" />
        <EnhacedPrivateRoute component={Icons} path="/icons" />
        <EnhacedPrivateRoute component={Account} path="/account" />
        <EnhacedPrivateRoute
          component={ChangePassword}
          path="/change-password"
        />
        <EnhacedPrivateRoute component={ChangePassword} path="/profile" />
        <EnhacedPrivateRoute component={Settings} path="/settings" />
        <EnhacedPrivateRoute component={Departments} path="/departments" />
        <EnhacedPrivateRoute component={Categories} path="/categories" />
        <EnhacedPrivateRoute component={SubCategories} path="/sub-categories" />
        <EnhacedPrivateRoute component={Roles} path="/roles" />
        <EnhacedPrivateRoute component={Permissions} path="/permissions" />
        <EnhacedPrivateRoute
          component={EnhancedUserGroups}
          path="/user-groups"
        />
        <EnhacedPrivateRoute component={TicketForm} path="/ticket/:id?" />
        <EnhacedPrivateRoute component={ListTickets} path="/received-tickets" />
        <EnhacedPrivateRoute component={ListTickets} path="/sent-tickets" />
        <EnhacedPrivateRoute
          component={ListTickets}
          path="/subscribed-tickets"
        />
        <EnhacedPrivateRoute component={SearchTickets} path="/search-tickets" />
        <EnhacedPrivateRoute component={AssignedTicketsReport} path="/assigned-tickets-report" />
        <EnhacedPrivateRoute component={TaskTypes} path="/tasks" />
        <EnhacedPrivateRoute component={Task} path="/task-configuration" />
        <Route component={SignUp} path="/sign-up" />
        <Route component={EnhacedSignIn} path="/sign-in" />
        <Route component={UnderDevelopment} path="/under-development" />
        <Route component={NotFound} path="/not-found" />
        <Redirect to="/not-found" />
      </Switch>
    );
  }
}

export default WithNotifications(
  withSnackbar(WithAuth(WithPushNotifications(Routes)))
);
