import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  DepartmentCreators,
  selectDepartment,
  selectIsLoading
} from 'redux/DepartmentRedux';
import { selectUsers } from 'redux/UserRedux';

export const mapStateToProps = state => ({
  users: selectUsers(state),
  isLoading: selectIsLoading(state),
  department: selectDepartment(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDepartment: DepartmentCreators.departmentGetRequest,
      createDepartment: DepartmentCreators.departmentCreateRequest,
      updateDepartment: DepartmentCreators.departmentUpdateRequest
    },
    dispatch
  );

export const hoc = WrappedComponent => {
  const hocComponent = class HocComponent extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  hocComponent.propTypes = {
    isLoading: PropTypes.bool,
    department: PropTypes.object,
    getDepartment: PropTypes.func,
    createDepartment: PropTypes.func,
    updateDepartment: PropTypes.func
  };

  return hocComponent;
};

export default WrapperComponent =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hoc(WrapperComponent));
