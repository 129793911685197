import React from 'react';
import PropTypes from 'prop-types';
// Material UI components
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
// React Int Helper
import { injectIntl } from 'react-intl';
// Component assets
import styles from './styles';

class PriorityTable extends React.Component {
	render() {
		const {
			classes,
			intl,
			items,
			responseTimeTypes,
			errors,
			setFieldValue
		} = this.props;

		const options = responseTimeTypes.map(item => ({
			value: item.id,
			label: item.code
		}));

		const table = (
			<Table>
				<TableHead>
					<TableRow>
						<TableCell align="center">
							{intl.formatMessage({
								id: 'catalog.subcategories.priority.table.header.priority'
							})}
						</TableCell>
						<TableCell align="center">
							{intl.formatMessage({
								id: 'catalog.subcategories.priority.table.header.responseTime'
							})}
						</TableCell>
						<TableCell align="center">
							{intl.formatMessage({
								id: 'catalog.subcategories.priority.table.header.type'
							})}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((item, index) => (
						<TableRow key={index}>
							<TableCell style={{ width: '20%' }} component="th" scope="row">
								{item.code}
							</TableCell>
							<TableCell style={{ width: '30%' }} align="center">
								<TextField
									name={`priorityLevels[${index}].responseTimeSetting`}
									placeholder="Tiempo de respuesta"
									margin="normal"
									fullWidth
									inputProps={{
										style: { textAlign: 'center' },
										onFocus: event => {
											event.target.select();
										}
									}}
									InputLabelProps={{
										shrink: true
									}}
									className={classes.textField}
									onChange={event => {
										const { value } = event.target;
										setFieldValue(
											`priorityLevels[${index}].responseTimeSetting`,
											value
										);
									}}									
									onBlur={event => {
										const { value } = event.target;
										setFieldValue(
											`priorityLevels[${index}].responseTimeSetting`,
											value
										);
									}}				
									value={item.responseTimeSetting}
									error={
										errors.priorityLevels !== undefined &&
										errors.priorityLevels[index] !== undefined &&
										errors.priorityLevels[index].responseTimeSetting !==
										undefined
									}
									helperText={
										errors.priorityLevels !== undefined &&
											errors.priorityLevels[index] !== undefined &&
											errors.priorityLevels[index].responseTimeSetting !== undefined
											? errors.priorityLevels[index].responseTimeSetting
											: ''
									}
								/>
							</TableCell>
							<TableCell style={{ width: '50%' }} align="center">
								<TextField
									name={`priorityLevels[${index}].responseTimeTypeId`}
									placeholder="Tipo"
									margin="normal"
									fullWidth
									select
									InputLabelProps={{
										shrink: true
									}}
									className={classes.textField}
									onChange={event => {
										const { value } = event.target;
										setFieldValue(
											`priorityLevels[${index}].responseTimeTypeId`,
											value
										);
									}}
									value={item.responseTimeTypeId}
									error={
										errors.priorityLevels !== undefined &&
										errors.priorityLevels[index] !== undefined &&
										errors.priorityLevels[index].responseTimeTypeId !==
										undefined
									}
									helperText={
										errors.priorityLevels !== undefined &&
											errors.priorityLevels[index] !== undefined &&
											errors.priorityLevels[index].responseTimeTypeId !==
											undefined
											? errors.priorityLevels[index].responseTimeTypeId
											: ''
									}
								>
									{options.map(option => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</TextField>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		);
		return <Paper className={classes.root}>{table}</Paper>;
	}
}

PriorityTable.propTypes = {
	items: PropTypes.array
};

export default withStyles(styles)(injectIntl(PriorityTable));
