import * as Yup from 'yup';

export const validationSchema = intl => {
  const required = intl.formatMessage({ id: 'field.validation.required' });

  return Yup.object().shape({
    taskTypeId: Yup.string()
      .required(required)
      .nullable(),
    categoryId: Yup.string()
      .required(required)
      .nullable()
  });
};
