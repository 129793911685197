import API from "./Api";

class DocumentService extends API {

    constructor(controller){
        super(controller);
    }

    getWorkItemReport = (id) => {

        let url = `${this.controller}/GetWorkItemReport/${id}`;
        let filename = `ticketreport-${id}.pdf`;

        this.API.get(
            url,
            null,
           { responseType: 'arraybuffer'}
        ).then((response) => {

            const file = new Blob([response.data], { type: "application/pdf" });

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(file, filename);
            }
    
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        });
    }
}

let documentService = new DocumentService("documents");

export default documentService;