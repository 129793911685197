import React, { Component } from "react";
import classNames from "classnames";

// Externals
import PropTypes from "prop-types";

//Material components
import { withStyles } from "@material-ui/core";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

import { WithUsers } from "./components/hoc";

// Custom components
import { UserTable, UserEditForm } from "./components";

// Component styles
import styles from "./style";

class Users extends Component {

	state = {
		isLoading: false,
		users: [],
		showDetailOf: null,
		addItem: false
	}

	componentDidMount() {
		const { getUsers } = this.props;
		getUsers();
	}

	render() {
		const { classes, users, roles, isLoading, deleteUser, getUser, messageOperation, clearMessage } = this.props;
		const { showDetailOf, addItem } = this.state;

		return (
			<DashboardLayout title="Usuarios">
				<div className={classNames(classes.root, { [classes.hidden]: showDetailOf || addItem })}>
					<div className={classes.content}>
						<UserTable
							items={users}
							roles={roles}
							onDelete={deleteUser}
							onAddClick={() => this.setState({ addItem: true })}
							onEditClick={(id) => { getUser(id); this.setState({ showDetailOf: id }); }}
							isLoading={isLoading}
							messageOperation={messageOperation}
							onClearMessage={clearMessage}
						/>
					</div>
				</div>
				{
					(showDetailOf != null || addItem) &&
					<div className={classes.root}>
						<div className={classes.content}>
							<UserEditForm
								id={showDetailOf}
								onBack={() => this.setState({ showDetailOf: null, addItem: false })}
							/>
						</div>
					</div>
				}
			</DashboardLayout>
		);
	}
}

Users.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WithUsers(Users));
