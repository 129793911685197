import EntityBase from '../EntityBase';

class Task extends EntityBase {
  constructor(obj) {
    if (!obj) obj = {};
    super(obj);
    this.subCategories = obj.subCategories ? obj.subCategories : [];
    this.isRequired = obj.isRequired ? obj.isRequired : false;
    this.taskTypeId = obj.taskTypeId ? obj.taskTypeId : undefined;
    this.categoryId = obj.categoryId ? obj.categoryId : undefined;
    this.companyId = obj.companyId ? obj.companyId : undefined;
  }
}

export default Task;
