import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import Moment from 'moment';
import Modal from 'react-modal';

// Redux
import { Provider } from 'react-redux';
import store from 'stores';

//Components
import Routes from '../../routes/index.jsx';
import { Chart } from 'react-chartjs-2';
import { chartjs } from '../../helpers';
import theme from '../../theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

//Assets
import messages from '../../assets/locales/es-MX.json';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '../../assets/scss/index.scss';

// Browser history
const browserHistory = createBrowserHistory();

// Configure ChartJS
Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
    draw: chartjs.draw
});

Moment.locale('es-MX');
Modal.setAppElement('#root');

export default class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <IntlProvider locale={'en'} messages={messages}>
                    <ThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Router history={browserHistory}>
                                <SnackbarProvider>
                                    <Routes />
                                </SnackbarProvider>
                            </Router>
                        </MuiPickersUtilsProvider>
                    </ThemeProvider>
                </IntlProvider>
            </Provider>
        );
    }
}
