export default theme => ({
	root: {
		padding: theme.spacing.unit * 3
	},
	content: {
		marginTop: theme.spacing.unit * 2
	},
	progressWrapper: {
		paddingTop: '48px',
		paddingBottom: '24px',
		display: 'flex',
		justifyContent: 'center'
	}
});