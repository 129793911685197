import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Shared components
import { Portlet, PortletContent, Table, ConfirmDialog } from 'components';

// Component styles
import styles from './styles';

import { withSnackbar } from 'notistack';

import { Loader } from 'components';

import { injectIntl } from 'react-intl';

class CompanyLocationTable extends Component {
  state = {
    rowsPerPage: 15,
    page: 0,
    itemToDelete: null,
    columns: [],
    actions: []
  };

  componentDidMount() {
    this.setState({ columns: this.getColumns(), actions: this.getActions() });
  }

  getColumns = () => {
    const { intl } = this.props;

    return [
      {
        title: intl.formatMessage({
          id: 'catalog.companylocations.table.columns.code'
        }),
        field: 'code'
      },
      {
        title: intl.formatMessage({
          id: 'catalog.companylocations.table.columns.detail'
        }),
        field: 'detail'
      },
      {
        title: intl.formatMessage({
          id: 'catalog.companylocations.table.columns.ownername'
        }),
        field: 'ownerName'
      },
      {
        title: intl.formatMessage({
          id: 'catalog.companylocations.table.columns.address'
        }),
        field: 'address.address1'
      },
      {
        title: intl.formatMessage({
          id: 'catalog.companylocations.table.columns.isactive'
        }),
        field: 'isActive',
        render: rowData => (
          <span>
            {rowData.isActive
              ? intl.formatMessage({ id: 'item.status.active' })
              : intl.formatMessage({ id: 'item.status.inactive' })}
          </span>
        )
      }
    ];
  };

  getActions = () => {
    const { intl, onIsValidEdit } = this.props;

    return [
      {
        icon: 'edit',
        tooltip: intl.formatMessage({ id: 'catalog.table.action.edit' }),
        onClick: (e, rowData) => this.props.onEditClick(rowData.id)
      },
      {
        icon: 'delete',
        tooltip: intl.formatMessage({ id: 'catalog.table.action.delete' }),
        onClick: (e, rowData) => {
          onIsValidEdit(rowData.id);
          this.setState({ itemToDelete: rowData });
        }
      },
      {
        icon: 'add',
        isFreeAction: true,
        tooltip: intl.formatMessage({ id: 'catalog.table.action.create' }),
        onClick: this.props.onAddClick
      }
    ];
  };

  delete = () => {
    const { itemToDelete } = this.state;

    this.props.onDelete(itemToDelete.id);
    this.setState({ itemToDelete: null });
  };

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.isValidEdit != undefined &&
      nextProps.isValidEdit != this.props.isValidEdit
    ) {
      this.setState({ displayWarning: nextProps.isValidEdit });
    }

    return true;
  }

  render() {
    const {
      classes,
      className,
      items,
      isLoading,
      intl,
      isValidEdit,
      onClearIsValidEdit
    } = this.props;
    const { columns, actions, itemToDelete, displayWarning } = this.state;
    const rootClassName = classNames(classes.root, className);

    return (
      <React.Fragment>
        <Portlet className={rootClassName}>
          <PortletContent noPadding>
            {isValidEdit != undefined &&
              displayWarning != undefined &&
              (displayWarning ? (
                <ConfirmDialog
                  text={intl.formatMessage(
                    { id: 'catalog.companylocations.warning.description' },
                    { locationName: `${itemToDelete.code}` }
                  )}
                  open
                  onClose={() => {
                    this.setState({
                      displayWarning: undefined,
                      itemToDelete: null
                    });
                    onClearIsValidEdit(undefined);
                  }}
                  onConfirm={this.delete}
                />
              ) : (
                <ConfirmDialog
                  text={intl.formatMessage(
                    { id: 'action.delete.warning' },
                    { itemName: `${itemToDelete.code}` }
                  )}
                  open
                  onClose={() => {
                    onClearIsValidEdit(undefined);
                    this.setState({
                      displayWarning: undefined,
                      itemToDelete: null
                    });
                  }}
                  onConfirm={this.delete}
                />
              ))}
            {/* {itemToDelete && (
              <ConfirmDialog
                text={intl.formatMessage(
                  { id: 'action.delete.warning' },
                  { itemName: `${itemToDelete.code}` }
                )}
                open
                onClose={() => this.setState({ itemToDelete: null })}
                onConfirm={this.delete}
              />
            )} */}
            {isLoading ? (
              <Loader />
            ) : (
              <Table columns={columns} items={items} actions={actions} />
            )}
          </PortletContent>
        </Portlet>
      </React.Fragment>
    );
  }
}

CompanyLocationTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onAddClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDelete: PropTypes.func,
  items: PropTypes.array.isRequired
};

CompanyLocationTable.defaultProps = {
  items: [],
  onAddClick: () => {},
  onEditClick: () => {},
  onDeleteClick: () => {}
};

export default withStyles(styles)(
  withSnackbar(injectIntl(CompanyLocationTable))
);
