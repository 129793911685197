import React from 'react';
import PropTypes from "prop-types";
import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Select, Table } from 'components';

class LocationOwners extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        locations: PropTypes.array,
        users: PropTypes.array
    };

    static defaultProps = {
        locations: [],
        user: []
    };

    handleChange = (selected, i) => {
        const {
            onChange,
            locations
        } = this.props;

        let item = locations.find(x => x.companyLocationId === i.companyLocationId);
        item.ownerId = selected.value;
        onChange(locations);
    }

    render() {
        const {
            locations,
            users
        } = this.props;

        return (
            <FormGroup>
                <Table
                    items={locations}
                    columns={[
                        {
                            field: 'companyLocation',
                            Title: 'Sucursal'
                        },
                        {
                            field: 'companyLocation',
                            Title: 'Responsable',
                            render: rowData => (
                                <Select
                                    isSearchable
                                    value={rowData.ownerId}
                                    onChange={(selected) => this.handleChange(selected, rowData)}
                                    options={users}
                                />
                            )
                        }
                    ]}
                    options={{
                        pageSize: 5
                    }}
                />
            </FormGroup>
        );
    }
}

export default LocationOwners;