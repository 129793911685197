import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
  {
    setSession: ['token'],
    setEditIdRequest: ['id'],
    setEditIdSuccess: ['id'],
    userLoginRequest: ['credential'],
    permissionsRequest: [],
    onPermissionsRequestSuccess: ['permissions'],
    settingsRequest: [],
    onSettingsRequestSuccess: ['settings'],
    userLoginSuccess: ['token'],
    userLoginFailure: ['error'],
    userGetRequest: ['id'],
    userGetSuccess: ['user'],
    userGetFailure: ['error'],
    userListRequest: [],
    userListSuccess: ['users'],
    userListError: ['error'],
    userCreateRequest: ['user'],
    userCreateSuccess: ['user', 'messageOperation'],
    userCreateFailure: ['error'],
    userUpdateRequest: ['user'],
    userUpdateSuccess: ['user', 'messageOperation'],
    userUpdateFailure: ['error'],
    userDeleteRequest: ['id'],
    userDeleteSuccess: ['id', 'messageOperation'],
    userDeleteFailure: ['error'],
    changePasswordRequest: ['password'],
    changePasswordSuccess: [],
    changePasswordFailure: ['error'],
    resetChangePasswordResult: [],
    setLoading: ['isLoading'],
    userSetMessage: ['messageOperation']
  },
  {}
);

export const UserTypes = Types;
export const UserCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
  users: [],
  user: null,
  isLoading: false,
  token: null,
  settings: null,
  loadingSession: true,
  changePasswordMessage: '',
  editId: null,
  permissions: [],
  permissionsLoaded: false
});

export const loginSuccess = (state, { token }) =>
  Immutable.set(state, 'token', token);
export const userListSuccess = (state, { users }) => state.merge({ users });
export const userGetSuccess = (state, { user }) => state.merge({ user });
export const userCreateSuccess = (state, { user, messageOperation }) =>
  state.merge({ users: state.users.concat(user), user, messageOperation });
export const userUpdateSuccess = (state, { user, messageOperation }) =>
  state.merge({
    users: state.users.map(i => (i.id == user.id ? user : i)),
    user,
    messageOperation
  });
export const userDeleteSuccess = (state, { id, messageOperation }) =>
  state.merge({ users: state.users.filter(i => i.id != id), messageOperation });
export const changePasswordSuccess = state =>
  state.merge({
    changePasswordMessage: 'Contraseña actualizada',
    loading: false
  });
export const changePasswordFailure = (state, { error }) =>
  state.merge({ changePasswordMessage: error, loading: false });
export const onSetLoading = (state, { isLoading }) =>
  state.merge({ isLoading });
export const onSetSession = (state, { token }) =>
  state.merge({ token, loadingSession: false });
export const onSetEditId = (state, { id }) => state.merge({ editId: id });
export const resetChangePasswordResult = state =>
  state.merge({ changePasswordMessage: '', loading: true });
export const onSetError = (state, { error }) => state.merge({ error: error });
export const onUserSetMessage = (state, { messageOperation }) =>
  state.merge({ messageOperation });
export const onPermissionsRequestSuccess = (state, { permissions }) =>
  state.merge({ permissions, permissionsLoaded: true });
export const onSettingsRequestSuccess = (state, { settings }) =>
  Immutable.set(state, 'settings', settings);

//map our actions types to our reducer functions
export const HANDLERS = {
  [UserTypes.USER_LOGIN_SUCCESS]: loginSuccess,
  [UserTypes.USER_GET_SUCCESS]: userGetSuccess,
  [UserTypes.USER_LIST_SUCCESS]: userListSuccess,
  [UserTypes.USER_CREATE_SUCCESS]: userCreateSuccess,
  [UserTypes.USER_UPDATE_SUCCESS]: userUpdateSuccess,
  [UserTypes.USER_DELETE_SUCCESS]: userDeleteSuccess,
  [UserTypes.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [UserTypes.CHANGE_PASSWORD_FAILURE]: changePasswordFailure,
  [UserTypes.SET_LOADING]: onSetLoading,
  [UserTypes.SET_SESSION]: onSetSession,
  [UserTypes.SET_EDIT_ID_SUCCESS]: onSetEditId,
  [UserTypes.RESET_CHANGE_PASSWORD_RESULT]: resetChangePasswordResult,
  [UserTypes.USER_LOGIN_FAILURE]: onSetError,
  [UserTypes.USER_SET_MESSAGE]: onUserSetMessage,
  [UserTypes.ON_PERMISSIONS_REQUEST_SUCCESS]: onPermissionsRequestSuccess,
  [UserTypes.ON_SETTINGS_REQUEST_SUCCESS]: onSettingsRequestSuccess
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
//export const selectUser = state => state.userReducer.token;
// export const selectIsAuthenticated = state => state.userReducer.user ? true : false;
export const selectPermissions = state =>
  Immutable.asMutable(state.userReducer.permissions, { deep: true });
export const selectSettings = state => state.userReducer.settings;
export const selectPermissionsLoaded = state =>
  state.userReducer.permissionsLoaded;
export const selectUsers = state =>
  Immutable.asMutable(state.userReducer.users, { deep: true });
export const selectOwners = state =>
  Immutable.asMutable(state.userReducer.users, { deep: true }).filter(
    x => x.entityTypeId === 1
  );

export const selectUserOptions = state => {
  let collection = Immutable.asMutable(state.userReducer.users, { deep: true });
  return collection
    .filter(item => item.isActive)
    .map(item => ({ label: item.userName, value: item.id }));
};

export const selectUser = state => state.userReducer.user;
export const selectToken = state => state.userReducer.token;
export const selectIsLoading = state => state.userReducer.isLoading;
export const selectIsLoadingSession = state => state.userReducer.loadingSession;
export const selectRedirect = state => (state.userReducer.token ? true : false);
export const selectIsAuthenticated = state =>
  state.userReducer.token ? true : false;
export const selectChangePasswordMessage = state =>
  state.userReducer.changePasswordMessage;
export const selectError = state => state.userReducer.error;
export const selectEditId = state => state.userReducer.editId;
export const selectMessageOperation = state =>
  state.userReducer.messageOperation;
