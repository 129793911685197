import React, { Component } from 'react';
import classNames from 'classnames';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import {
    AttachmentConfigTable,
    EditForm,
    WithAttachmentConfig
} from './components';

import { withSnackbar } from 'notistack';

import { injectIntl } from 'react-intl';

// Component styles
import styles from './style';

class AttachmentConfig extends Component {
    state = {
        isLoading: false,
        attachmentsConfigs: [],
        showDetailOf: null,
        addItem: false
    };

    componentDidMount() {
        const { getAll } = this.props;
        getAll();
    }

    shouldComponentUpdate(nextProps) {
        const { enqueueSnackbar, clearMessage, intl } = this.props;

        if (
            nextProps.messageOperation &&
            nextProps.messageOperation !== this.props.messageOperation
        ) {
            enqueueSnackbar(
                intl.formatMessage({ id: nextProps.messageOperation.message }),
                { variant: nextProps.messageOperation.type }
            );
            clearMessage();
        }

        return true;
    }

    render() {
        const {
            classes,
            attachmentConfigs,
            deleteAttachmentConfig,
            isLoading
        } = this.props;
        const { showDetailOf, addItem } = this.state;

        return (
            <DashboardLayout title={'Archivos'}>
                <div
                    className={classNames(classes.root, {
                        [classes.hidden]: showDetailOf || addItem
                    })}
                >
                    <div className={classes.content}>
                        <AttachmentConfigTable
                            items={attachmentConfigs}
                            onDelete={deleteAttachmentConfig}
                            onEditClick={id => {
                                this.setState({ showDetailOf: id });
                            }}
                            onAddClick={() => {
                                this.setState({ addItem: true });
                            }}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
                {(showDetailOf != null || addItem) && (
                    <div className={classes.root}>
                        <div className={classes.content}>
                            <EditForm
                                id={showDetailOf}
                                onBack={() =>
                                    this.setState({ showDetailOf: null, addItem: false })
                                }
                            />
                        </div>
                    </div>
                )}
            </DashboardLayout>
        );
    }
}

AttachmentConfig.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
    withSnackbar(WithAttachmentConfig(injectIntl(AttachmentConfig)))
);
