import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  TaskTypeCreators,
  selectTaskType,
  selectIsLoading
} from 'redux/TaskTypeRedux';

export const mapStateToProps = state => ({
  isLoading: selectIsLoading(state),
  taskType: selectTaskType(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTaskType: TaskTypeCreators.taskTypeGetRequest,
      createTaskType: TaskTypeCreators.taskTypeCreateRequest,
      updateTaskType: TaskTypeCreators.taskTypeUpdateRequest
    },
    dispatch
  );

export const hoc = WrappedComponent => {
  const hocComponent = class HocComponent extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  hocComponent.propTypes = {
    isLoading: PropTypes.bool,
    taskType: PropTypes.object,
    getTaskType: PropTypes.func,
    createTaskType: PropTypes.func,
    updateTaskType: PropTypes.func
  };

  return hocComponent;
};

export default WrapperComponent =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hoc(WrapperComponent));
