import EntityBase from '../EntityBase';
// import Immutable from 'seamless-immutable';

class Department extends EntityBase {
  constructor(obj) {
    if (!obj) obj = {};
    super(obj);
    this.code = obj.code ? obj.code : '';
    this.detail = obj.detail ? obj.detail : '';
    this.ownerId = obj.ownerId ? obj.ownerId : '';
    this.ownerName = obj.ownerName ? obj.ownerName : '';
    this.companyId = obj.companyId ? obj.companyId : undefined;

    if (!obj.departmentMembers) {
      this.departmentMembers = obj.departmentMembers;
    }
    else {
      if (obj.departmentMembers.asMutable) {
        this.departmentMembers = (obj.departmentMembers.asMutable());
      }
      else {
        this.departmentMembers = obj.departmentMembers;
      }
    }
  }
}

export default Department;
