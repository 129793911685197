import API from './Api';

class BusinessFunctionsService extends API {
  constructor(controller) {
    super(controller);
  }
}

let service = new BusinessFunctionsService('businessFunctions');

export default service;
