import React from 'react';

// Material components
import { Button, FormControlLabel, InputLabel, Grid } from '@material-ui/core';

import classNames from 'classnames';

import { withStyles } from '@material-ui/core';

import { WithGeneralInfo } from '../hoc';

import { Formik, Field, Form } from 'formik';

import { Switch, TextField } from 'formik-material-ui';

import { Loader, Select, SaveContainer } from 'components';

import { validationSchema } from './resources';

import { withSnackbar } from 'notistack';

import { injectIntl } from 'react-intl';

import styles from './styles';

class GeneralInfo extends React.Component {
  state = {
    id: null,
    user: {
      email: '',
      companyLocationId: '',
      externalId: '',
      userName: '',
      displayName: '',
      mobilePhone: '',
      roleId: null,
      isActive: true,
      roleName: '',
      entityTypeId: null,
      externalEntityId: null,
      entityTypeName: ''
    },
    isReadOnly: false
  };

  componentDidMount() {
    const { getUser, id } = this.props;
    if (id) getUser(id);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.user !== this.props.user) {
      this.handleRuleRole(nextProps.user.roleId);
      this.setState({ user: nextProps.user }, () => true);
    }

    return true;
  }

  handleRuleRole(roleId) {
    const { roles, entityTypes } = this.props;

    let role = roles.find(x => x.id == roleId);

    if (roleId == 1) {
      role = { code: "superuser" };
    }

    this.setState({ isReadOnly: true });
    let entityType;
    switch (role.code.toUpperCase()) {
      case 'CLIENT':
        entityType = entityTypes[1];
        break;
      case 'CLIENT MANAGER':
        entityType = entityTypes[1];
        break;
      case 'PROVIDER':
        entityType = entityTypes[2];
        break;
      case 'PROVIDER MANAGER':
        entityType = entityTypes[2];
        break;
      default:
        entityType = undefined;
        break;
    }

    this.setState({ isReadOnly: entityType != null && entityType != null });

    return entityType ? entityType.id : entityType;
  }

  render() {
    const {
      id,
      roles,
      companyLocations,
      classes,
      createUser,
      updateUser,
      isLoading,
      intl,
      entityTypes,
      externalEntities
    } = this.props;

    const { user, isReadOnly } = this.state;

    const companyLocationsOptions = companyLocations
      .filter(i => i.isActive)
      .map(i => ({ value: i.id, label: i.code, isActive: i.isActive }));
    const rolesOptions = roles
      .filter(i => i.isActive)
      .map(i => ({ value: i.id, label: i.code, isActive: i.isActive }));
    const entityTypesOptions = entityTypes
      .filter(i => i.isActive)
      .map(i => ({ value: i.id, label: i.code, isActive: i.isActive }));
    return (
      <React.Fragment>
        {isLoading ? (
          <Loader />
        ) : (
            <Formik
              enableReinitialize
              initialValues={user}
              validationSchema={validationSchema(intl)}
              validateOnBlur
              validateOnChange
              onSubmit={values => {
                if (id) {
                  updateUser(values);
                } else {
                  createUser(values);
                }
              }}
              render={({ submitForm, isSubmitting, values }) => (
                <SaveContainer saveContent={submitForm}>
                  <Form>
                    <Grid
                      container
                      justify="center"
                      className={classes.root}
                      spacing={2}
                    >
                      <Grid item xs={12} md={6} lg={6}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={6} lg={6}>
                            <Field
                              name="userName"
                              type="text"
                              label={intl.formatMessage({
                                id: 'catalog.users.field.username'
                              })}
                              component={TextField}
                              margin="normal"
                              fullWidth
                              autoFocus
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{
                                maxLength: 255
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <Field
                              name="displayName"
                              label={intl.formatMessage({
                                id: 'catalog.users.field.displayName'
                              })}
                              type="text"
                              component={TextField}
                              margin="normal"
                              fullWidth
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{
                                maxLength: 255
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <Field
                              name="email"
                              type="email"
                              label={intl.formatMessage({
                                id: 'catalog.users.field.email'
                              })}
                              component={TextField}
                              margin="normal"
                              fullWidth
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{
                                maxLength: 255
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <Field
                              name="mobilePhone"
                              type=""
                              label={intl.formatMessage({
                                id: 'catalog.users.field.mobile'
                              })}
                              component={TextField}
                              margin="normal"
                              fullWidth
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{
                                maxLength: 50
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <Field
                              name="roleId"
                              render={({ field, form }) => {
                                return (
                                  <div
                                    className={classNames(classes.selectRoot, {
                                      error:
                                        form.touched[field.name] &&
                                        form.errors[field.name]
                                    })}
                                  >
                                    <InputLabel className={classes.inputLabel}>
                                      {intl.formatMessage({
                                        id: 'catalog.users.field.role'
                                      })}
                                    </InputLabel>
                                    <Select
                                      isSearchable
                                      value={field.value}
                                      onChange={selected => {
                                        form.setFieldValue(
                                          field.name,
                                          selected.value
                                        );
                                        form.setFieldValue(
                                          'roleName',
                                          rolesOptions.find(
                                            x => x.value === selected.value
                                          ).label
                                        );
                                        let entityTypeId = this.handleRuleRole(
                                          selected.value
                                        );
                                        form.setFieldValue(
                                          'entityTypeId',
                                          entityTypeId
                                            ? entityTypeId
                                            : values.entityTypeId
                                        );
                                        form.setFieldValue(
                                          'externalEntityId',
                                          entityTypeId !=
                                            entityTypesOptions[0].value
                                            ? null
                                            : 0
                                        );
                                      }}
                                      options={rolesOptions}
                                      onMenuClose={() =>
                                        form.setFieldTouched(
                                          field.name,
                                          true,
                                          true
                                        )
                                      }
                                      onMenuOpen={() =>
                                        form.setFieldTouched(field.name, false)
                                      }
                                    />
                                    {form.touched[field.name] &&
                                      form.errors[field.name] && (
                                        <p className="MuiFormHelperText-root Mui-error">
                                          {form.errors[field.name]}
                                        </p>
                                      )}
                                  </div>
                                );
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <Field
                              name="companyLocationId"
                              render={({ field, form }) => {
                                return (
                                  <div
                                    className={classNames(classes.selectRoot, {
                                      error:
                                        form.touched[field.name] &&
                                        form.errors[field.name]
                                    })}
                                  >
                                    <InputLabel className={classes.inputLabel}>
                                      {intl.formatMessage({
                                        id: 'catalog.users.field.location'
                                      })}
                                    </InputLabel>
                                    <Select
                                      isSearchable
                                      value={field.value}
                                      onChange={selected => {
                                        form.setFieldValue(
                                          field.name,
                                          selected.value
                                        );
                                      }}
                                      options={companyLocationsOptions}
                                      onMenuClose={() =>
                                        form.setFieldTouched(
                                          field.name,
                                          true,
                                          true
                                        )
                                      }
                                      onMenuOpen={() =>
                                        form.setFieldTouched(field.name, false)
                                      }
                                    />
                                    {form.touched[field.name] &&
                                      form.errors[field.name] && (
                                        <p className="MuiFormHelperText-root Mui-error">
                                          {form.errors[field.name]}
                                        </p>
                                      )}
                                  </div>
                                );
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <Field
                              name="externalId"
                              type=""
                              label={intl.formatMessage({
                                id: 'catalog.users.field.idexternal'
                              })}
                              component={TextField}
                              margin="normal"
                              fullWidth
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{
                                maxLength: 255
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <FormControlLabel
                              control={
                                <Field
                                  label={intl.formatMessage({
                                    id: 'catalog.users.field.isactive'
                                  })}
                                  name="isActive"
                                  component={Switch}
                                />
                              }
                              label={intl.formatMessage({
                                id: 'catalog.users.field.isactive'
                              })}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <Field
                              name="entityTypeId"
                              render={({ field, form }) => {
                                return (
                                  <div
                                    className={classNames(classes.selectRoot, {
                                      error:
                                        form.touched[field.name] &&
                                        form.errors[field.name]
                                    })}
                                  >
                                    <InputLabel className={classes.inputLabel}>
                                      {intl.formatMessage({
                                        id: 'catalog.users.field.entitytype'
                                      })}
                                    </InputLabel>
                                    <Select
                                      isSearchable
                                      isDisabled={isReadOnly}
                                      value={field.value}
                                      onChange={selected => {
                                        let entityType = entityTypes.find(
                                          x => x.id == selected.value
                                        );

                                        form.setFieldValue(
                                          field.name,
                                          selected.value
                                        );

                                        form.setFieldValue(
                                          "entityTypeName",
                                          entityType.code
                                        );

                                        form.setFieldValue(
                                          'externalEntityId',
                                          selected.value !=
                                            entityTypesOptions[0].value
                                            ? null
                                            : 0
                                        );
                                      }}
                                      options={entityTypesOptions}
                                      onMenuClose={() =>
                                        form.setFieldTouched(
                                          field.name,
                                          true,
                                          true
                                        )
                                      }
                                      onMenuOpen={() =>
                                        form.setFieldTouched(field.name, false)
                                      }
                                    />
                                    {form.touched[field.name] &&
                                      form.errors[field.name] && (
                                        <p className="MuiFormHelperText-root Mui-error">
                                          {form.errors[field.name]}
                                        </p>
                                      )}
                                  </div>
                                );
                              }}
                            />
                          </Grid>
                          {values.entityTypeId && values.entityTypeId != 1 && (
                            <Grid item xs={12} md={6} lg={6}>
                              <Field
                                name="externalEntityId"
                                render={({ field, form }) => {
                                  return (
                                    <div
                                      className={classNames(classes.selectRoot, {
                                        error:
                                          form.touched[field.name] &&
                                          form.errors[field.name]
                                      })}
                                    >
                                      <InputLabel className={classes.inputLabel}>
                                        {intl.formatMessage({
                                          id: 'catalog.users.field.externalentity'
                                        })}
                                      </InputLabel>
                                      <Select
                                        key={`externalentity${values.entityTypeId}`}
                                        isSearchable
                                        value={field.value}
                                        onChange={selected => {
                                          form.setFieldValue(
                                            field.name,
                                            selected.value
                                          );
                                        }}
                                        options={externalEntities
                                          .filter(
                                            i =>
                                              i.isActive &&
                                              i.entityTypeId ==
                                              values.entityTypeId
                                          )
                                          .map(i => ({
                                            value: i.id,
                                            label: i.code,
                                            isActive: i.isActive
                                          }))}
                                        onMenuClose={() =>
                                          form.setFieldTouched(
                                            field.name,
                                            true,
                                            true
                                          )
                                        }
                                        onMenuOpen={() =>
                                          form.setFieldTouched(field.name, false)
                                        }
                                      />
                                      {form.touched[field.name] &&
                                        form.errors[field.name] && (
                                          <p className="MuiFormHelperText-root Mui-error">
                                            {form.errors[field.name]}
                                          </p>
                                        )}
                                    </div>
                                  );
                                }}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting}
                              onClick={submitForm}
                            >
                              {intl.formatMessage({ id: 'button.label.save' })}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                </SaveContainer>
              )}
            />
          )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(
  withSnackbar(WithGeneralInfo(injectIntl(GeneralInfo)))
);
