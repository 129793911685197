import React, { Component } from 'react';
import classNames from 'classnames';

// Externals
import PropTypes from 'prop-types';

//Material components
import { withStyles } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { TaskTypeTable, EditForm, WithTaskTypes } from './components';

import { withSnackbar } from 'notistack';

import { injectIntl } from 'react-intl';

// Component styles
import styles from './styles';

class TaskTypes extends Component {
    state = {
        isLoading: false,
        companyLocations: [],
        showDetailOf: null,
        addItem: false
    };

    componentDidMount() {
        const { getTaskTypes } = this.props;
        getTaskTypes();
    }

    shouldComponentUpdate(nextProps) {
        const { enqueueSnackbar, clearMessage, intl } = this.props;
        if (
            nextProps.messageOperation &&
            nextProps.messageOperation !== this.props.messageOperation
        ) {
            enqueueSnackbar(
                intl.formatMessage({ id: nextProps.messageOperation.message }),
                { variant: nextProps.messageOperation.type }
            );
            clearMessage();
        }

        return true;
    }

    render() {
        const { classes, taskTypes, isLoading, deleteTaskType } = this.props;
        const { showDetailOf, addItem } = this.state;

        return (
            <DashboardLayout title="Tareas">
                <div
                    className={classNames(classes.root, { [classes.hidden]: showDetailOf || addItem })}
                >
                    <div className={classes.content}>
                        <TaskTypeTable
                            items={taskTypes}
                            onDelete={deleteTaskType}
                            onAddClick={() => this.setState({ addItem: true })}
                            onEditClick={id => {
                                this.setState({ showDetailOf: id });
                            }}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
                {(showDetailOf != null || addItem) && (
                    <div className={classes.root}>
                        <div className={classes.content}>
                            <EditForm
                                id={showDetailOf}
                                onBack={() =>
                                    this.setState({ showDetailOf: null, addItem: false })
                                }
                            />
                        </div>
                    </div>
                )}
            </DashboardLayout>
        );
    }
}

TaskTypes.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
    withSnackbar(WithTaskTypes(injectIntl(TaskTypes)))
);
