import React from 'react';

import { withStyles } from '@material-ui/core';

import { Button, FormControlLabel, Grid } from '@material-ui/core';

import { Loader, SaveContainer } from 'components';

import { WithGeneralInfo } from './../../components';

import { Formik, Field, Form } from 'formik';

import { TextField, Switch } from 'formik-material-ui';

import { validationSchema, AttachmentConfigModel } from './resources';

import AttachmentConfig from 'contract/requests/AttachmentConfig';

import { SingleCheckBoxTable } from './../../components';

// I18n Helper
import { injectIntl } from 'react-intl';

import styles from './styles';

class GeneralInfo extends React.Component {
    state = {
        attachmentConfig: new AttachmentConfigModel()
    };

    componentDidMount() {
        const { getAttachmentConfig, id } = this.props;
        if (id) {
            getAttachmentConfig(id);
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.attachmentConfig !== this.props.attachmentConfig) {
            this.setState(
                { attachmentConfig: nextProps.attachmentConfig },
                () => true
            );
        }

        if (nextProps.subCategories !== this.props.subCategories) {
            let filteredItems = this.props.subCategories.filter(x => x.isActive);
            let indexes = filteredItems.map(x =>
                this.props.subCategories.findIndex(item => item.id == x.id)
            );
            this.setState({
                rowsSelected: indexes
            });
        }

        return true;
    }

    handleRowsSelected = rowsSelected => {
        this.setState({ rowsSelected: rowsSelected });
    };

    render() {
        const {
            intl,
            classes,
            isLoading,
            saveMultiple,
            subCategories
        } = this.props;

        const { attachmentConfig, rowsSelected } = this.state;

        return (
            <React.Fragment>
                {isLoading ? (
                    <Loader />
                ) : (
                        <Formik
                            enableReinitialize
                            initialValues={attachmentConfig}
                            validationSchema={validationSchema(intl)}
                            onSubmit={values => {
                                const attachmentConfigRequest = new AttachmentConfig(values);
                                attachmentConfigRequest.subCategories = this.state.rowsSelected;
                                saveMultiple(attachmentConfigRequest);
                            }}
                            render={({ submitForm, isSubmitting }) => (
                                <SaveContainer saveContent={submitForm}>
                                <Form>
                                    <Grid container className={classes.root} spacing={1}>
                                        <Grid
                                            item
                                            xs={12}
                                            md={1}
                                            lg={1}
                                        />
                                        <Grid item xs={12} md={5} lg={5}>
                                            <Grid container className={classes.root} spacing={1}>
                                                <Grid item xs={12} md={12} lg={6}>
                                                    <Field
                                                        type="text"
                                                        label={intl.formatMessage({
                                                            id:
                                                                'catalog.attachmentconfig.table.columns.extension'
                                                        })}
                                                        name="fileExt"
                                                        margin="normal"
                                                        component={TextField}
                                                        fullWidth
                                                        autoFocus
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={1}>
                                                <Grid item xs={6} md={6} lg={6}>
                                                    <Field
                                                        type="text"
                                                        label={intl.formatMessage({
                                                            id: 'catalog.attachmentconfig.table.columns.minsize'
                                                        })}
                                                        name="minSize"
                                                        component={TextField}
                                                        margin="normal"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={1}>
                                                <Grid item xs={6} md={6} lg={6}>
                                                    <Field
                                                        type="text"
                                                        label={intl.formatMessage({
                                                            id: 'catalog.attachmentconfig.table.columns.maxsize'
                                                        })}
                                                        name="maxSize"
                                                        component={TextField}
                                                        margin="normal"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={1}>
                                                <Grid item xs={6} md={6} lg={6}>
                                                    <Field
                                                        name="namingConvention"
                                                        label={intl.formatMessage({
                                                            id:
                                                                'catalog.attachmentconfig.table.columns.nameconvention'
                                                        })}
                                                        type="text"
                                                        component={TextField}
                                                        margin="normal"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={1}>
                                                <Grid item xs={6} md={6} lg={6}>
                                                    <FormControlLabel
                                                        control={
                                                            <Field
                                                                label={intl.formatMessage({
                                                                    id:
                                                                        'catalog.attachmentconfig.table.columns.isrequired'
                                                                })}
                                                                name="isRequired"
                                                                component={Switch}
                                                            />
                                                        }
                                                        label={intl.formatMessage({
                                                            id:
                                                                'catalog.attachmentconfig.table.columns.isrequired'
                                                        })}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={1}>
                                                <Grid item xs={12} md={6} lg={6}>
                                                    <FormControlLabel
                                                        control={
                                                            <Field
                                                                label={intl.formatMessage({
                                                                    id:
                                                                        'catalog.attachmentconfig.table.columns.isactive'
                                                                })}
                                                                name="isActive"
                                                                component={Switch}
                                                            />
                                                        }
                                                        label={intl.formatMessage({
                                                            id:
                                                                'catalog.attachmentconfig.table.columns.isactive'
                                                        })}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                        onClick={submitForm}
                                                    >
                                                        {intl.formatMessage({ id: 'button.label.save' })}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Grid container className={classes.root} spacing={1}>
                                                <Grid item xs={12} md={12} lg={12}>
                                                    <SingleCheckBoxTable
                                                        items={subCategories}
                                                        rowsSelected={rowsSelected}
                                                        handleRowsSelected={this.handleRowsSelected}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                                </SaveContainer>
                            )}
                        />
                    )}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(WithGeneralInfo(injectIntl(GeneralInfo)));
