import * as React from 'react';
import Select from 'react-select';

const SelectComponent = ({
  options,
  value,
  onChange,
  placeholder,
  noOptionsMessage,
  ...props
}) => {
  const getObjectFromId = id => {
    return options.find(x => x.value == id);
  };

  options = options || [];

  props = {
    ...props,
    noOptionsMessage: noOptionsMessage
      ? noOptionsMessage
      : () => 'No hay opciones',
    placeholder: placeholder || 'Seleccionar',
    onChange: selected => {
      onChange(selected || { value: null });
    },
    value: getObjectFromId(value),
    isMulti: false,
    options: options
      .sort(x => x.label)
      .filter(x => x.isActive === undefined || x.isActive)
  };

  return (
    <Select
      {...props}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      menuPosition={'absolute'}
    />
  );
};

export default SelectComponent;
