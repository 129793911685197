import { create, SERVER_ERROR } from 'apisauce';
import { ResponseStatus } from '../constants';
import store from 'stores';

class API {
  constructor(controller) {
    this.API = create({
      baseURL: window.env.API_URL
      //baseURL: 'http://localhost:65442/'
    });

    this.validResponse = response => response.status != ResponseStatus.fail;

    this.API.addRequestTransform(request => {
      const state = store ? store.getState() : null;
      if (state && state.userReducer.token && state.userReducer.token.token) {
        request.headers[
          'Authorization'
        ] = `Bearer ${state.userReducer.token.token}`;
      }
    });

    this.API.addResponseTransform(response => {
      if (response.status == 401) {
        delete localStorage.appUser;
        window.location = '/sign-in';
      } else if (!this.validResponse(response)) {
        response.ok = false;
        response.problem = SERVER_ERROR;
      }
    });

    this.controller = controller;

    this.getById = id => {
      return this.API.get(`${this.controller}/${id}`);
    };

    this.getAll = () => {
      return this.API.get(`${this.controller}`);
    };

    this.getAllSimple = () => {
      return this.API.get(`${this.controller}/all`);
    };

    this.create = item => {
      return this.API.post(`${this.controller}`, item);
    };

    this.update = item => {
      return this.API.put(`${this.controller}/${item.id}`, item);
    };

    this.delete = id => {
      return this.API.delete(`${this.controller}/${id}`);
    };

    // this.downloadFile = (url, filename) => {
    // return this.API({
    //     url: `${url}`,
    //     method: "GET",
    //     responseType: "blob" // important
    //   })
    //     .then(response => {
    //       const blob = new Blob([response.data]);

    //       if (typeof window.navigator.msSaveBlob !== "undefined") {
    //         // IE workaround for "HTML7007: One or more blob URLs were
    //         // revoked by closing the blob for which they were created.
    //         // These URLs will no longer resolve as the data backing
    //         // the URL has been freed."
    //         window.navigator.msSaveBlob(blob, filename);
    //         return;
    //       }

    //       const url = window.URL.createObjectURL(blob);
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.setAttribute("download", filename);
    //       document.body.appendChild(link);
    //       link.click();
    //     })
    //     .catch(error => ({ error }));
    // }
  }
}

export default API;
