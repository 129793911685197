export const getMonthName = month => {
  const months = [
    { id: 1, label: 'Ene' },
    { id: 2, label: 'Feb' },
    { id: 3, label: 'Mar' },
    { id: 4, label: 'Abr' },
    { id: 5, label: 'May' },
    { id: 6, label: 'Jun' },
    { id: 7, label: 'Jul' },
    { id: 8, label: 'Ago' },
    { id: 9, label: 'Sep' },
    { id: 10, label: 'Oct' },
    { id: 11, label: 'Nov' },
    { id: 12, label: 'Dic' }
  ];

  return months.find(x => x.id === month).label;
};
