import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  CompanyLocationCreators,
  selectCompanyLocation,
  selectIsLoading
} from 'redux/CompanyLocationRedux';
import { CityCreators, selectCities } from 'redux/CityRedux';
import { selectStates } from 'redux/StateRedux';
import { selectOwners } from 'redux/UserRedux';

export const mapStateToProps = state => ({
  isLoading: selectIsLoading(state),
  companyLocation: selectCompanyLocation(state),
  cities: selectCities(state),
  states: selectStates(state),
  users: selectOwners(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCitiesByStateId: CityCreators.cityListStateIdRequest,
      getCompanyLocation: CompanyLocationCreators.companyLocationGetRequest,
      createCompanyLocation:
        CompanyLocationCreators.companyLocationCreateRequest,
      updateCompanyLocation:
        CompanyLocationCreators.companyLocationUpdateRequest
    },
    dispatch
  );

export const hocComponentName = WrappedComponent => {
  const hocComponent = class HocComponent extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  hocComponent.propTypes = {
    companyLocation: PropTypes.object,
    getCompanyLocation: PropTypes.func,
    createCompanyLocation: PropTypes.func,
    updateCompanyLocation: PropTypes.func
  };

  return hocComponent;
};

export default WrapperComponent =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hocComponentName(WrapperComponent));
