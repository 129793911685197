import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
//import Yup from 'yup';
import Moment from 'moment';
import classNames from "classnames";
import { MTableToolbar } from 'material-table';

// UI Components
import {
    withStyles,
    Grid,
    Typography,
    CircularProgress,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Button,
    InputLabel,
    Icon
} from '@material-ui/core';
// import { TextField } from 'formik-material-ui';
import { Dashboard as DashboardLayout } from 'layouts';
// import { Portlet, PortletContent, Table, Select, SelectAsync } from 'components';
import { Portlet, PortletContent, Table, Select } from 'components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DatePicker } from "@material-ui/pickers";
import { Formik, Field, Form } from 'formik';

//Services
import { WithTicket, WithSession, WithNotifications } from "hoc";
import ticketsService from "services/ticketsService";
// import userService from "services/userService";
// import subCategoriesService from "services/subCategoriesService";
// import categoriesService from "services/categoriesService";
// import severitiesService from "services/severitiesService";
// import companyLocationsService from "services/companyLocationService";

//Assets
import styles from './styles';

class SearchTickets extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        id: PropTypes.number,
        onBack: PropTypes.func
    };

    static defaultProps = {
        id: null,
        onBack: () => { },
    };

    state = {
        tickets: [],
        severities: [],
        categories: [],
        subCategories: [],
        companyLocations: [],
        statuses: [],
        users: [],
        filtersPanelExpanded: true,
        tablePanelExpanded: true,
        date: new Date(),
        category: null,
        subCategory: null,
        companyLocation: null,
        severity: null,
        status: null,
        user: null,
        loading: false,
    };

    selectStatus = [
        { value: 1, label: "Inicial" },
        { value: 2, label: "En progreso" },
        { value: 3, label: "Finalizado" }
    ]

    emptyFilters = {
        startDate: Moment().startOf('month'),
        endDate: Moment().endOf('day'),
        workItemId: "",
        categoryId: "",
        subCategoryId: "",
        locationId: "",
        subject: "",
        assignedTo: "",
        createdBy: "",
        statusId: "",
        severityId: ""
    }

    statuses = [
        { value: 1, label: "Inicial" },
        { value: 2, label: "En progreso" },
        { value: 3, label: "Finalizado" }
    ]

    componentDidMount = () => {
        this.searchTickets(this.emptyFilters);
        this.setState({ filtersPanelExpanded: false });
    }

    // updateSubCategories = (categoryId) => {
    //     if (!categoryId) {
    //         this.setState({ subCategories: [] });
    //         return;
    //     }

    //     subCategoriesService.getSimpleByCategoryId(categoryId).then((response) => {
    //         this.setState({ subCategories: response.data.data });
    //     });
    // }

    getTableColumns = () => {
        const {
            users,
        } = this.state;

        let columns = [
            {
                title: "Id",
                field: 'id'
            },
            {
                title: "Asunto",
                field: 'subject',
                render: row => {
                    return (
                        <Link to={`/ticket/${row.id}`}>
                            {row.subject}
                        </Link>
                    );
                }
            },
            {
                title: "Sucursal",
                field: 'companyLocationName'
            },
            {
                title: "SubCategoria",
                field: 'subCategoryName'
            },
            {
                title: "Categoria",
                field: 'categoryName'
            },
            {
                title: "Severidad",
                field: 'severity'
            },
            {
                title: "Estado",
                field: 'status'
            },
            {
                title: "Tiempo de cierre",
                field: 'createdDate',
                render: row => {
                    if (!row.status.startsWith('Cerrado'))
                        return null;

                    var startDate = Moment(row.createdDate).local();
                    var endDate = Moment(row.modifiedDate).local();

                    return (
                        <span>
                            {endDate.from(startDate, true)}
                        </span>
                    );
                }
            },
        ];

        if (users.length > 1) {
            columns.push({
                title: "Asignado a",
                field: 'userName'
            });
        }

        return columns;
    }

    searchTickets = (values) => {
        this.setState({ loading: true });
        ticketsService.getAssignedReport(values).then((response) => {
            this.setState({ loading: false });

            if (!response.data || response.data.status == "Fail" || !response.data.data) {
                return;
            }

            let tickets = response.data.data;

            const categories = [... new Set(tickets.map(x => ({ value: x.categoryId, label: x.categoryName })))];
            const subCategories = [... new Set(tickets.map(x => ({ value: x.subCategoryId, label: x.subCategoryName })))];
            const companyLocations = [... new Set(tickets.filter(x => x.companyLocationId).map(x => ({ value: x.companyLocationId, label: x.companyLocationName })))];
            const statuses = [... new Set(tickets.map(x => ({ value: x.statusId, label: x.status })))];
            const severities = [... new Set(tickets.map(x => ({ value: x.severityId, label: x.severity })))];
            const users = [... new Set(tickets.map(x => ({ value: x.userId, label: x.userName })))];

            this.setState({
                tickets,
                categories: categories.filter((v, i, a) => a.findIndex(v2 => (v2.value === v.value)) === i),
                subCategories: subCategories.filter((v, i, a) => a.findIndex(v2 => (v2.value === v.value)) === i),
                companyLocations: companyLocations.filter((v, i, a) => a.findIndex(v2 => (v2.value === v.value)) === i),
                statuses: statuses.filter((v, i, a) => a.findIndex(v2 => (v2.value === v.value)) === i),
                severities: severities.filter((v, i, a) => a.findIndex(v2 => (v2.value === v.value)) === i),
                users: users.filter((v, i, a) => a.findIndex(v2 => (v2.value === v.value)) === i),
            });
        });
    }

    copyRows = () => {
        this.setState({ copiyng: true }, () => {
            let table = document.getElementsByTagName('table')[0];

            if (window.getSelection) {
                const selection = window.getSelection();
                const range = document.createRange();
                range.selectNodeContents(table);
                selection.removeAllRanges();
                selection.addRange(range);
            }

            document.execCommand('copy', true);
            window.getSelection().removeAllRanges();
            this.props.addNotification({ message: "Tickets copiados al porta papeles", options: { variant: "success" } });

            window.setTimeout(() => {
                this.setState({ copiyng: false });
            }, 500);
        });
    }

    renderFilters = () => {
        const {
            classes
        } = this.props;

        const {
            date,
            // severities,
            // companyLocations,
            // categories,
            // subCategories
        } = this.state;

        return (
            <Grid container alignItems="center">
                <Grid item xs={12} justify="center" alignContent="center">
                    <Formik
                        key={date.toISOString()}
                        style={{ width: '100%' }}
                        enableReinitialize
                        initialValues={this.emptyFilters}
                        onSubmit={async (values, form) => {
                            this.setState({ filtersPanelExpanded: false });
                            this.searchTickets(values);
                            form.setSubmitting(false);
                        }}
                        render={({ submitForm, isSubmitting, }) => (
                            <Form>
                                <Grid container className={classes.root} justify="space-beetwen">
                                    <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                                        <Field
                                            label="Fecha inicial"
                                            name="startDate"
                                            margin="normal"
                                            render={({ field, form }) => (
                                                <Grid
                                                    direction="column"
                                                    justify="center"
                                                    className={
                                                        classNames(
                                                            classes.inputRoot,
                                                            { "error": form.touched[field.name] && form.errors[field.name] }
                                                        )
                                                    }
                                                >
                                                    <InputLabel>Fecha inicial</InputLabel>
                                                    <DatePicker
                                                        format="DD MMMM [del] YYYY"
                                                        value={field.value}
                                                        onChange={(date) => form.setFieldValue(field.name, date)}
                                                    />
                                                    {
                                                        form.touched[field.name] && form.errors[field.name] &&
                                                        <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                    }
                                                </Grid>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                                        <Field
                                            label="Fecha inicial"
                                            name="endDate"
                                            margin="normal"
                                            render={({ field, form }) => (
                                                <Grid
                                                    direction="column"
                                                    justify="center"
                                                    className={
                                                        classNames(
                                                            classes.inputRoot,
                                                            { "error": form.touched[field.name] && form.errors[field.name] }
                                                        )
                                                    }
                                                >
                                                    <InputLabel>Fecha final</InputLabel>
                                                    <DatePicker
                                                        format="DD MMMM [del] YYYY"
                                                        value={field.value}
                                                        onChange={(date) => form.setFieldValue(field.name, date)}
                                                    />
                                                    {
                                                        form.touched[field.name] && form.errors[field.name] &&
                                                        <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                    }
                                                </Grid>
                                            )}
                                        />
                                    </Grid>
                                    <Grid container className={classes.item} justify="flex-end">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            endIcon={<Icon>send</Icon>}
                                            disabled={isSubmitting}
                                            onClick={submitForm}
                                        >
                                            Buscar
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            className={classes.button}
                                            endIcon={<Icon>send</Icon>}
                                            disabled={isSubmitting}
                                            onClick={() => this.setState({ date: new Date() })}
                                            style={{ marginRight: '10px' }}
                                        >
                                            Limpiar filtros
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    />
                </Grid>
            </Grid>
        );
    }

    renderDropDowns = () => {
        const {
            tickets,
            categories,
            subCategories,
            companyLocations,
            statuses,
            severities,
            users,
            category,
            subCategory,
            companyLocation,
            status,
            severity,
            user,
        } = this.state;

        if (!tickets || !tickets.length)
            return null;

        return (
            <Grid container justify="center" alignContent="center" spacing={2} xs={12}>
                <Grid item xs={6} lg={2}>
                    <InputLabel>Sucursal</InputLabel>
                    <Select
                        isClearable
                        value={companyLocation}
                        onChange={(selected) => {
                            this.setState({ companyLocation: selected.value });
                        }}
                        options={companyLocations}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition={'absolute'}
                        menuPlacement={'bottom'}
                    />
                </Grid>
                <Grid item xs={6} lg={2}>
                    <InputLabel>Categoria</InputLabel>
                    <Select
                        isClearable
                        value={category}
                        onChange={(selected) => {
                            this.setState({ category: selected.value });
                        }}
                        options={categories}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition={'absolute'}
                        menuPlacement={'bottom'}
                    />
                </Grid>
                <Grid item xs={6} lg={2}>
                    <InputLabel>SuCategoria</InputLabel>
                    <Select
                        isClearable
                        value={subCategory}
                        onChange={(selected) => {
                            this.setState({ subCategory: selected.value });
                        }}
                        options={subCategories}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition={'absolute'}
                        menuPlacement={'bottom'}
                    />
                </Grid>
                <Grid item xs={6} lg={2}>
                    <InputLabel>Severidad</InputLabel>
                    <Select
                        isClearable
                        value={severity}
                        onChange={(selected) => {
                            this.setState({ severity: selected.value });
                        }}
                        options={severities}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition={'absolute'}
                        menuPlacement={'bottom'}
                    />
                </Grid>
                <Grid item xs={6} lg={2}>
                    <InputLabel>Status</InputLabel>
                    <Select
                        isClearable
                        value={status}
                        onChange={(selected) => {
                            this.setState({ status: selected.value });
                        }}
                        options={statuses}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition={'absolute'}
                        menuPlacement={'bottom'}
                    />
                </Grid>
                {
                    users.length > 1 &&
                    <Grid item xs={6} lg={2}>
                        <InputLabel>Usuario</InputLabel>
                        <Select
                            isClearable
                            value={user}
                            onChange={(selected) => {
                                this.setState({ user: selected.value });
                            }}
                            options={users}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                }

            </Grid>
        );
    }

    renderTable = () => {
        const {
            tickets,
            loading,
            selectedItems,
            copiyng,
            category,
            subCategory,
            user,
            companyLocation,
            status,
            severity,
        } = this.state;

        let items = [...tickets];
        if (user) {
            items = items.filter(x => x.userId == user);
        }

        if (category) {
            items = items.filter(x => x.categoryId == category);
        }

        if (subCategory) {
            items = items.filter(x => x.subCategoryId == subCategory);
        }

        if (companyLocation) {
            items = items.filter(x => x.companyLocationId == companyLocation);
        }

        if (status) {
            items = items.filter(x => x.statusId == status);
        }

        if (severity) {
            items = items.filter(x => x.severityId == severity);
        }

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    {
                        loading
                            ? <CircularProgress />
                            : (
                                <Table
                                    columns={this.getTableColumns()}
                                    items={copiyng ? selectedItems : items}
                                    options={{
                                        pageSize: 100,
                                        pageSizeOptions: [5, 10, 20, 100, 500],
                                        selection: copiyng ? false : true,
                                        search: true,
                                        //toolbar: selectedItems && selectedItems.length ? true : false,
                                        toolbar: true,
                                        exportButton: true,
                                        exportFileName: "Tickets asignados",
                                        exportAllData: true
                                    }}
                                    actions={copiyng ? [] : [
                                        {
                                            icon: 'content_copy',
                                            tooltip: 'Copiar al porta papeles',
                                            onClick: this.copyRows
                                        }
                                    ]}
                                    onSelectionChange={(selectedItems) => {
                                        this.setState({ selectedItems });
                                    }}
                                    components={{
                                        Toolbar: props => (
                                            <div>
                                                {this.renderDropDowns()}
                                                <MTableToolbar {...props} />
                                            </div>
                                        ),
                                    }}
                                />
                            )
                    }
                </Grid>
            </React.Fragment>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            filtersPanelExpanded,
            tablePanelExpanded
        } = this.state;

        return (
            <DashboardLayout title="Tickets asignados">
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                <div className={classes.root}>
                                    <ExpansionPanel expanded={filtersPanelExpanded} onChange={(e, v) => this.setState({ filtersPanelExpanded: v })}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="filters-content"
                                            id="filters-header"
                                        >
                                            <Typography className={classes.heading}>Opciones de busqueda</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            {this.renderFilters()}
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel expanded={tablePanelExpanded}>
                                        <ExpansionPanelSummary
                                            aria-controls="result-content"
                                            id="result-header"
                                        >
                                            <Typography className={classes.heading}>Resultados</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            {this.renderTable()}
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(WithSession(WithNotifications(WithTicket(withStyles(styles)(SearchTickets)))));