import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Paper } from 'components';
import styles from './styles';

class SentTickets extends Component {
	static propTypes = {
		className: PropTypes.string,
		onClick: PropTypes.func.isRequired,
		classes: PropTypes.object.isRequired,
		counter: PropTypes.number.isRequired
	};

	render() {
		const { classes, className, counter, onClick, ...rest } = this.props;

		const rootClassName = classNames(classes.root, className);

		return (
			<Paper
				{...rest}
				className={rootClassName}
				onClick={onClick}
			>
				<div className={classes.content}>
					<div className={classes.details}>
						<Typography
							className={classes.title}
							variant="body2"
						>
							Total creados
						</Typography>
						<Typography
							className={classes.value}
							variant="h3"
						>
							{counter}
						</Typography>
					</div>
				</div>
			</Paper>
		);
	}
}

export default withStyles(styles)(SentTickets);
