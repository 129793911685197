import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  AttachmentConfigCreators,
  selectAttachmentConfigs,
  selectMessageOperation,
  selectIsLoading
} from 'redux/AttachmentConfigRedux';

export const mapStateToProps = state => ({
  messageOperation: selectMessageOperation(state),
  isLoading: selectIsLoading(state),
  attachmentConfigs: selectAttachmentConfigs(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteAttachmentConfig:
        AttachmentConfigCreators.attachmentConfigDeleteRequest,
      clearMessage: AttachmentConfigCreators.attachmentConfigSetMessage,
      getAll: AttachmentConfigCreators.attachmentConfigListRequest
    },
    dispatch
  );

export const hocConector = WrappedComponent => {
  const hoc = ({ ...props }) => <WrappedComponent {...props} />;

  hoc.propTypes = {
    /** Get all company categories */
    getAll: PropTypes.func
  };

  return hoc;
};

export default Component =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hocConector(Component));
