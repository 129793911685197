import React from 'react';

// Externals
// import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// I18n Helper
import { injectIntl } from 'react-intl';

// Shared components
import { Table } from 'components';

// Component styles
import styles from './styles';

class ChartTable extends React.Component {
	state = {
		rowsPerPage: 15,
		page: 0,
		itemToDelete: null,
		columns: [],
		actions: []
	};

	componentDidMount() {
		this.setState({ columns: this.getColumns() });
	}

	getColumns = () => {
		const {
			intl,
			labelColumn,
			valueColumn,
			labelField,
			valueField
		} = this.props;

		return [
			{
				title: '',
				sorting: false,
				field: 'color',
				width: 5,
				cellStyle: rowData => ({
					backgroundColor:
						rowData < 33
							? 'green'
							: rowData >= 33 && rowData < 66
								? 'yellow'
								: 'red'
				}),
				render: () => <div />
			},
			{
				title: intl.formatMessage({
					id: labelColumn
				}),
				field: labelField
			},
			{
				title: intl.formatMessage({
					id: valueColumn
				}),
				field: valueField
			}
		];
	};

	render = () => {
		const { items } = this.props;
		const { columns } = this.state;
		return (
			<Table
				title={'Tickets Generados'}
				columns={columns}
				items={items}
				options={{
					paging: false,
					search: false,
					toolbar: false,
					showTitle: false
				}}
			/>
		);
	};
}

ChartTable.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object.isRequired,
	items: PropTypes.array.isRequired
};

ChartTable.defaultProps = {
	items: [],
	labelColumn: 'chart.table.field.category',
	valueColumn: 'chart.table.field.tickets'
};

export default withStyles(styles)(injectIntl(ChartTable));
