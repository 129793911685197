import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  CategoryCreators,
  selectIsLoading,
  selectCategory
} from 'redux/CategoryRedux';
import { selectOwners } from 'redux/UserRedux';
import { selectDepartmentsOptions } from 'redux/DepartmentRedux';
import { selectEntityTypes } from 'redux/EntityTypeRedux';

export const mapStateToProps = state => ({
  category: selectCategory(state),
  isLoading: selectIsLoading(state),
  users: selectOwners(state),
  deparments: selectDepartmentsOptions(state),
  entityTypes: selectEntityTypes(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCategory: CategoryCreators.getCategoryRequest,
      createCategory: CategoryCreators.createCategoryRequest,
      updateCategory: CategoryCreators.updateCategoryRequest,
      clearMessage: CategoryCreators.categorySetMessage
    },
    dispatch
  );

export const hocConector = WrappedComponent => {
  const hoc = ({ ...props }) => <WrappedComponent {...props} />;

  hoc.propTypes = {
    /** Get all company categories */
    getCategory: PropTypes.func,
    createCategory: PropTypes.func,
    updateCategory: PropTypes.func,
    clearMessage: PropTypes.func
  };

  return hoc;
};

export default Component =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hocConector(Component));
