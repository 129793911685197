import * as React from 'react';
import { withStyles } from '@material-ui/core';

import { Button, FormControlLabel, Grid, Switch } from '@material-ui/core';

import { Loader, SaveContainer } from 'components';
import WithGeneralInfo from '../hoc/WithGeneralInfo';
import { TextField } from 'formik-material-ui';
import { Formik, Field, Form } from 'formik';
import { validationSchema } from './resources';
import TaskType from 'contract/requests/TaskType';

import styles from './styles';

class GeneralInfo extends React.Component {
	state = {
		taskType: {
			code: '',
			detail: '',
			isActive: true
		}
	};

	componentDidMount() {
		const { getTaskType, id } = this.props;
		if (id) getTaskType(id);
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.taskType !== this.props.taskType) {
			this.setState({ taskType: nextProps.taskType }, () => true);
		}

		return true;
	}

	render() {
		const { classes, createTaskType, updateTaskType, isLoading } = this.props;

		const { taskType } = this.state;

		return (
			<React.Fragment>
				{isLoading ? (
					<Loader />
				) : (
						<Formik
							enableReinitialize
							initialValues={taskType}
							validationSchema={validationSchema}
							onSubmit={values => {
								let taskTypeRequest = new TaskType(values);
								if (!values.id) {
									createTaskType(taskTypeRequest);
								} else {
									updateTaskType(taskTypeRequest);
								}
							}}
							render={({ submitForm, isSubmitting }) => (
								<SaveContainer saveContent={submitForm}>
									<Form>
										<Grid container className={classes.root} spacing={1}>
											<Grid item xs={12} md={6} lg={4}>
												<Field
													type="text"
													label="Tarea"
													name="code"
													margin="normal"
													component={TextField}
													fullWidth
													autoFocus
													InputLabelProps={{
														shrink: true
													}}
												/>
											</Grid>
										</Grid>
										<Grid container className={classes.root} spacing={1}>
											<Grid item xs={12} md={6} lg={4}>
												<Field
													type="text"
													label="Detalles"
													name="detail"
													component={TextField}
													margin="normal"
													fullWidth
													multiline
													rows={3}
													InputLabelProps={{
														shrink: true
													}}
												/>
											</Grid>
										</Grid>
										<Grid container className={classes.root} spacing={1}>
											<Grid item xs={12} md={6} lg={4}>
												<FormControlLabel
													control={
														<Field
															label="Activo"
															name="isActive"
															component={props => (
																<Switch
																	checked={props.field.value}
																	value={props.field.name}
																	onChange={(e, value) =>
																		props.form.setFieldValue(
																			props.field.name,
																			value
																		)
																	}
																/>
															)}
														/>
													}
													label="Activo"
												/>
												<br />
											</Grid>

											<Grid item xs={12}>
												<Button
													variant="contained"
													color="primary"
													disabled={isSubmitting}
													onClick={submitForm}
												>
													Guardar
												</Button>
											</Grid>
										</Grid>
									</Form>
								</SaveContainer>
							)}
						/>
					)}
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(WithGeneralInfo(GeneralInfo));
